import { FC, useEffect } from 'react';
import './ProposalExport.scss';
import MasterPage from '../../../../components/MasterPage/MasterPage';
import PageBase from '../../../../components/PageBase/PageBase';
import FormProposalExportFilter from '../../../../components/Proposals/Export/FormProposalExportFilter/FormProposalExportFilter';
import { useProposalExport } from "../../../../providers/Proposals/Export/ProposalExportProvider";
import { useSidebarFilter } from '../../../../providers/SidebarFilterProvider';

interface ProposalExportProps { }

const ProposalExport: FC<ProposalExportProps> = () => {
  const { isLoadingFile } = useProposalExport();
  const { setShowSidebarFilter } = useSidebarFilter();

  useEffect(() => {
    setShowSidebarFilter(false);
  }, []);

  const renderContent = (): JSX.Element => {
    return (
      <>
        <FormProposalExportFilter />
      </>
    );
  }

  return (
    <MasterPage
      title="Exportação de Produção"
      icon="fas fa-ruler"
      footerFixed
      footerInverse
    >
      <div className="ProposalExport" data-testid="ProposalExport">
        <PageBase
          title="Exportação de Produção"
          subtitle="Nessa tela é possível realizar a exportação de produção."
          content={renderContent()}
          isLoadingFile={isLoadingFile}
        />
      </div>
    </MasterPage>
  );
}

export default ProposalExport;
