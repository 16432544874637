import { FC, useEffect, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { useForm } from 'react-hook-form';
import { CompanyParams } from '../../../../models/Registrations/Company/CompanyParams';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';

interface SidebarCompanyFilterProps { }

export const defaultValues = {
  name: '',
  cnpj: '',
  acronym: '',
  startDate: '',
  endDate: ''
} as CompanyParams;

const SidebarCompanyFilter: FC<SidebarCompanyFilterProps> = () => {

  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  const { handleSubmit, register, setValue } = useForm<any>({ defaultValues });
  
  const { params, setParams, handleList } = useCompany();

  useEffect(() => {
    handleList()
  }, [params]);

  const onSubmit = async (data: CompanyParams) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';

    data.cnpj = data?.cnpj? data?.cnpj.replace(/[^0-9]/g, '') : '';

    setParams({ ...params, ...data, ...{ page: 1 } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarCompanyFilter" data-testid="SidebarCompanyFilter">
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="name">NOME</label>
              <input
                className={`form-control`}
                {...register('name')}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="acronym">SIGLA</label>
              <input
                className={`form-control`}
                {...register('acronym')}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="cnpj">CNPJ</label>
              <input
                className={`form-control`}
                {...register('cnpj')}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
}

export default SidebarCompanyFilter;
