import { FC, useEffect, useState } from 'react';
import './SidebarTermsPersonalFilter.scss';
import SidebarFilter from '../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useTermsPersonal } from '../../../providers/Terms/TermsPersonalProvider';
import { useTermsTracking } from '../../../providers/Terms/TermsTrackingProvider';
import InputDateRange from '../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useAuth } from '../../../providers/AuthProvider';

interface SidebarTermsPersonalFilterProps { }

export const defaultValues = {
  startDate: '',
  endDate: '',
} as any;

const SidebarTermsPersonalFilter: FC<SidebarTermsPersonalFilterProps> = () => {
  const { params, setParams } = useTermsPersonal();
  const { params: paramsTracking, setParams: setParamsTracking  } = useTermsTracking();
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const { userLogged } = useAuth();
  const { handleSubmit, setValue, control } = useForm<any>({ defaultValues });

  useEffect(() => {
    setParams({ ...params, ...{
      startDate: startDate,
      endDate: endDate,
    }});

    setParamsTracking({ ...paramsTracking, ...{
      startDate: startDate,
      endDate: endDate,
      userId: userLogged?.id
    }});
  }, []);

  const onSubmit = async (data: any) => {

    setParams({ ...params, ...{
      startDate: data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : null,
      endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : null,
    }});

    setParamsTracking({ ...paramsTracking, ...{
      startDate: data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : null,
      endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : null,
      userId: userLogged?.id
    }});
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarTermsPersonalFilter" data-testid="SidebarTermsPersonalFilter">
        <Row className="mt-1">
          <Col md={12} xs={12}>
            <FormGroup className="form-group mb-0">
              <InputDateRange
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setValue={setValue}
                  startLabelText={'DATA INICIAL'}
                  endLabelText={'DATA FINAL'}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarTermsPersonalFilter;
