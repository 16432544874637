import { FC, useEffect, useState } from 'react';
import './ModalLowReceiptInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useAuth } from '../../../../providers/AuthProvider';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import { useEnterprise } from '../../../../providers/Registrations/Company/EnterpriseProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';
import { useReceiptWriteOff } from '../../../../providers/Commissioning/Receipt/ReceiptWriteOff/ReceiptWriteOffProvider';
import FormatMoney from '../../../../utils/FormatMoney';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faEye, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import ModalUploadVouchers from '../ModalUplaodVouchers/ModalUplaodVouchers';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { Color } from '../../../../assets/argon/js/plugins/threejs';


interface ModalLowReceiptInsertProps {
  show: boolean;
  onClose: () => void;
  receiptId: number;
  setReceiptId: any;
  receiptDate: string;
  setReceiptDate: any;
  businessDate: string;
  setBusinessDate: any;
}
const ModalLowReceiptInsert: FC<ModalLowReceiptInsertProps> = ({ show, onClose, receiptId, setReceiptId, receiptDate, setReceiptDate, businessDate, setBusinessDate }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean[]>([]);
  const [amounts, setAmounts] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any[]>([]);
  const [showModalUploadVounchers, setShowModalUploadVounchers] = useState<boolean>(false);
  const [writeOffId, setWriteOffId] = useState<number>(0);

  const {
    receiptLow,
    setReceiptLow,
    setError,
    handleGet,
    handleList,
    handleListReceiptType,
    handleExcel,
    setParamsExcel,
    handleListPerType,
  } = useReceipt();
  const { enterprises } = useEnterprise();
  const { handleAddEnterpriseReceipt } = useReceipt();
  const { handleFetch } = useReceiptWriteOff();

  const { onInvalid } = useAuth();
  const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm<any>({
    mode: "onChange",
  });

  const handleClose = () => {
    setError('');
    setReceiptLow(undefined);
    setReceiptId(0)
    setReceiptDate('');
    setBusinessDate('');
    setIsEdit([true])
    setParamsExcel({
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      financials: null,
      receiptTypes: null,
      campaigns: null
    });
    handleListReceiptType();
    onClose();
  }

  const onSubmitAddEnterprise = (enterpriseId: number, receiptId: number) => {
    handleAddEnterpriseReceipt({ enterpriseId: enterpriseId, receiptId: receiptId }).then(res => handleGet(receiptId, 'low', receiptDate, businessDate));
  }

  const handleFetchLowReceipt = async (index: number, data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    let _data = {};

    if (receiptLow?.receipts?.[0]?.enterprises && receiptLow?.receipts?.[0]?.enterprises[index]?.writeOff?.id) {

      _data = {
        id: receiptLow?.receipts?.[0]?.enterprises[index]?.writeOff?.id,
        receiptEnterpriseId: receiptLow?.receipts?.[0]?.enterprises && receiptLow?.receipts?.[0]?.enterprises[index].id,
        amount: amounts[index],
        paymentDate: moment(startDate[index]).format('YYYY-MM-DD'),
        receiptTypeId: receiptLow?.receipts?.[0]?.receiptTypeId,
        campaignId: receiptLow?.receipts?.[0]?.campaignId ?? undefined
      }
    } else {
      _data = {
        receiptEnterpriseId: receiptLow?.receipts?.[0]?.enterprises && receiptLow?.receipts?.[0]?.enterprises[index].id,
        amount: amounts[index],
        paymentDate: moment(startDate[index]).format('YYYY-MM-DD'),
        receiptTypeId: receiptLow?.receipts?.[0]?.receiptTypeId,
        campaignId: receiptLow?.receipts?.[0]?.campaignId ?? undefined
      }
    }

    const ret = await handleFetch(_data);
    if (ret) {
      toast.success('baixa de recebimento salvo com sucesso!');
      handleList();
      handleListPerType();
      handleGet(receiptId, 'low', receiptDate, businessDate);
      reset();
      setIsEdit([true]);
      setReceiptId(receiptId);
      setReceiptDate(receiptDate);
      setBusinessDate(businessDate);
    }
    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    if (show && receiptLow) {
      setParamsExcel({
        startDate: receiptDate,
        endDate: businessDate,
        financials: [receiptLow?.receipts?.[0]?.financialId],
        receiptTypes: [receiptLow?.receipts?.[0]?.receiptTypeId],
        campaigns: [receiptLow?.receipts?.[0]?.campaignId]
      });
    }
  }, [show, receiptLow])

  useEffect(() => {
    if (receiptId > 0) {
      handleGet(receiptId, 'low', receiptDate, businessDate);
    }
  }, [receiptId])

  useEffect(() => {
    setValue('amounts', amounts)
  }, [amounts]);

  useEffect(() => {
    setValue('paymentDate', startDate)
  }, [startDate]);

  useEffect(() => {
    if (receiptLow && receiptLow?.receipts?.[0]?.enterprises) {

      const returnPaymentDate: string[] = [];
      receiptLow?.receipts?.[0]?.enterprises?.forEach((item: any, index: any) => {
        if (item?.writeOff && item?.writeOff?.paymentDate) {
          const formattedDate = moment(item?.writeOff?.paymentDate).format('YYYY-MM-DD HH:mm:ss');
          returnPaymentDate[index] = formattedDate;
        }
      });
      setStartDate(returnPaymentDate);

      const returnAmount: string[] = [];
      receiptLow?.receipts?.[0]?.enterprises?.forEach((item: any, index: any) => {
        returnAmount[index] = item?.writeOff?.amount || '';
      });
      setAmounts(returnAmount);
    }
  }, [receiptLow]);

  const renderNameEnterprise = (enterpriseId: number | undefined) => {
    const enterprise = enterprises?.find(x => x?.id === enterpriseId);
    return enterprise ? enterprise?.name : '';
  }

  const handleStartDateChange = (index: number, date: string) => {
    const paymentDateFormat = moment(date).format('YYYY-MM-DD HH:mm:ss');
    const newStatePaymentDate = [...startDate];
    newStatePaymentDate[index] = paymentDateFormat;
    setStartDate(newStatePaymentDate);
  };

  const handleIsEdit = (index: number) => {
    const newStateEdit = [...isEdit];
    newStateEdit[index] = false;
    setIsEdit(newStateEdit);
  };

  const handleAmounts = (e: any, index: number) => {
    const inputValue: string = e.target.value.replace(/\D/g, '');
    const numericValue: number = parseFloat(inputValue) / 100;
    const updatedAmounts = [...amounts];
    updatedAmounts[index] = numericValue;
    setAmounts(updatedAmounts);
  };  

  const handleShowModalUploadVouchre = (id:number) => {
    setWriteOffId(id);
    setShowModalUploadVounchers(true);
  }

  return (
    <>
      <ModalDefault
        title={'Adicionar baixa de recebimento'}
        show={show}
        onClose={handleClose}
        sizeModal={'xxl'}
        showFooter={true}
        buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
        showButtonSpinner={isLoadingSubmit}
        disabledSubmit={isLoadingSubmit}
        backdrop="static"
        backgroundColor="#f8f9fa"
      >
        
         <ModalUploadVouchers
          show={showModalUploadVounchers}
          setShowModalUploadVounchers={setShowModalUploadVounchers}
          writeOffId={writeOffId}
          receiptId={receiptId}
          receiptDate={receiptDate}
          businessDate={businessDate}
        />

        <div className="ModalLowReceiptInsert" data-testid="ModalLowReceiptInsert">
          <Card>
            <Card.Header className="p-3">
              <Row>
                <Col xl={4}>
                  <h5>Financeira</h5>
                  <div id="lowImageFinancial">
                    <img
                      src={`/assets/img/financials/${receiptLow?.receipts?.[0]?.externalId}.svg`}
                      alt={receiptLow?.receipts?.[0]?.name}
                      style={{ width: '110px', height: '110px' }}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        const lowImageFinancial = document.getElementById('lowImageFinancial');
                        if (lowImageFinancial instanceof HTMLElement && receiptLow?.receipts?.[0]?.name) {
                          lowImageFinancial.innerHTML = `<h3>${receiptLow?.receipts?.[0]?.name}</h3>`;
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col xl={3} className="mb-2">
                  <Card>
                    <Card.Body className="p-3">
                      <Col>
                        <p className="text-sm mb-0 text-uppercase font-weight-bold">DATA</p>
                        <h6 className="font-weight-bolder">
                          {moment(businessDate).format('DD/MM/YYYY')}
                        </h6>
                      </Col>
                      <Row className='mb-2'>
                        <Col xs={9} md={9}>
                          <p className="text-sm mb-0 text-uppercase font-weight-bold">PREVISÃO RECEBIMENTO</p>
                          <h6 className="font-weight-bolder">
                            {FormatMoney(receiptLow?.receipts?.[0]?.previsionTotalAmount)}
                          </h6>
                        </Col>
                        <Col xs={3} md={3} className="d-flex justify-content-end">
                          <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9} md={9}>
                          <p className="text-sm mb-0 text-uppercase font-weight-bold">RECEBIMENTO</p>
                          <h6 className="font-weight-bolder">
                            {FormatMoney(receiptLow?.receipts?.[0]?.writeOffTotalAmount)}
                          </h6>
                        </Col>
                        <Col xs={3} md={3} className="d-flex justify-content-end">
                          <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                            <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={5}>
                  <div className="d-flex justify-content-end ms-auto my-auto">
                    <button className="btn bg-success text-white rounded" onClick={handleExcel}>
                      <i className="fas fa-file-excel me-1" /> Excel
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>

            <Card.Body className="p-3">
              <Col className="type">
                <hr />
                <p>{receiptLow?.type?.toUpperCase()}</p>
                <hr className='mb-1' />
              </Col>
              {receiptLow?.receipts?.[0]?.enterprises?.map((item: any, index: any) => (
                <Row key={index}>
                  <Col xl={12}>
                    <Card className='mt-2'>
                      <CardBody className='py-2'>
                        <Row>
                          <Col xl={12}>
                            <Form key={index} onSubmit={handleSubmit((data) => handleFetchLowReceipt(index, data))}>
                              <Row className='d-flex justify-content-start align-content-center mb-2'>
                                <Col xl={12} md={12}>
                                  <Row className='align-items-center'>
                                    <Col xl={2}>
                                      <p className="mb-0"> <label className='m-0'> {`${renderNameEnterprise(item?.enterpriseId)}`} </label> </p>
                                    </Col>
                                    <Col xl={2}>
                                      <FormGroup className='form-group'>
                                        <p className='m-0'> <label className='mb-0 mx-0'> Valor previsão de recebimento </label> </p>
                                        <p className='mb-0'> <b> {FormatMoney(item?.previsionAmount)} </b> </p>
                                      </FormGroup>
                                    </Col>
                                    <Col xl={2}>
                                      <FormGroup className='form-group mb-0'>
                                        <label htmlFor=""> Valor do recebimento </label>
                                        <input
                                          key={item?.id}
                                          className={`form-control ${onInvalid(errors?.amounts)}`}
                                          placeholder="Valor Recebido"
                                          type="text"
                                          value={FormatMoney(amounts[index] || '')}
                                          disabled={isEdit[index] === false ? isEdit[index] : item?.writeOff}
                                          {...register(`amount.${index}`)}
                                          onChange={(e: any) => handleAmounts(e, index)}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col sm={12} md={2} xl={2} className={`${isEdit[index] === false ? '' : (item?.writeOff ? 'disabled-input' : '')}`}>
                                      <InputDateRange
                                        startDate={startDate[index]}
                                        setStartDate={(date) => handleStartDateChange(index, date)}
                                        showStartDateClearButton={false}
                                        viewEndDate={false}
                                        startLabelText={'Data do recebimento'}
                                        nameStartDate=''
                                        nameEndDate=''
                                        {...register(`paymentDate.${index}`)}
                                      />
                                    </Col>
                                    <Col xl={2} className='text-center'>
                                      {item?.writeOff && (
                                        <>
                                          <button
                                            className='btn btn-secondary text-white mb-0'
                                            type="button"
                                            disabled={item?.writeOff?.id > 0 ? false : true}
                                            style={{ width: '100%', marginTop: 50 }}
                                            onClick={() => {handleShowModalUploadVouchre(item?.writeOff?.id || 0)}}
                                          >
                                            <FontAwesomeIcon icon={faCloudUpload} className='mx-2' /> Enviar comprovantes
                                          </button>
                                          {item?.writeOff && item?.writeOff?.receiptFiles?.length > 0 ? (
                                            <>
                                              <PopoverHelp
                                                placement="left"
                                                header={<> <p className='text-center text-danger m-0'> Comprovantes Recebimentos </p> </>}
                                                body={
                                                  <>
                                                    <ul style={{listStyleType: 'disclosure-closed', color: "#000"}}>
                                                      {
                                                        item?.writeOff?.receiptFiles.map((files: any) => (
                                                          <li>
                                                            <a href={files?.file} className='btn btn-link font-italic font-font-weight-bold m-0 p-0' target='_blank' style={{color: "#000"}}> Comprovantes {files?.id} </a>
                                                          </li>
                                                        ))
                                                      }
                                                    </ul>
                                                  </>
                                                }
                                              >
                                                <b> {`${item?.writeOff?.receiptFiles?.length}`} Comprovantes </b>
                                              </PopoverHelp>
                                            </>
                                          ) : (
                                            <>
                                              <p className='text-danger font-italic font-font-weight-bold m-0 p-0'> <b> 0 Comprovantes </b> </p>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                    <Col xl={2}>
                                      <button
                                        className='btn btn-primary mb-0'
                                        type="submit"
                                        disabled={isEdit[index] === false ? isEdit[index] : item?.writeOff}
                                        style={{ width: '100%', marginTop: 30 }}
                                      >
                                        Confirmar
                                      </button>
                                      {item?.writeOff && (
                                        <button
                                          className='btn btn-secondary mb-0 mt-2' type="button" onClick={() => handleIsEdit(index)} style={{ width: '100%' }}>
                                          Editar
                                        </button>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ))}

              {receiptLow?.receipts?.[0]?.receiptPrevisionEnterprises?.map((item: any, index: any) => (
                <Row className='mt-2'>
                  <Col xl={12}>
                    <Card>
                      <CardBody>
                        <Row className="d-flex justify-content-flex-start align-items-center mt-4">
                          <Col xl={2} className='d-flex align-items-center'>
                            <p className='m-0'> <label className='text-danger m-0'>{`${item.name}`} </label> </p>
                          </Col>
                          <Col sm={12} md={12} xl={2}>
                            <Row>
                              <Col xl={12}>
                                <FormGroup className='form-group mb-0'>
                                  <p className='mb-0'> <label className='m-0'> Valor previsão de recebimento </label> </p>
                                  <p className='mb-0'> <b> {FormatMoney(item?.amount)} </b> </p>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={12} md={12} xl={6} className='d-xs-none'></Col>
                          <Col xl={2} className='d-flex align-items-center'>
                            <button
                              className='btn btn-primary mb-0'
                              type="button"
                              onClick={() => onSubmitAddEnterprise(item?.id, receiptLow?.receipts?.[0]?.id)}
                              style={{ width: '100%' }}
                            >
                              Adicionar
                            </button></Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ))}
            </Card.Body>
          </Card>
        </div >
      </ModalDefault >
    </>
  );
}

export default ModalLowReceiptInsert;
