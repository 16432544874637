import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { DashboardService } from "../../../services/Marketplace/Dashboard/DashboardService";
import {ToastSettings} from "../../../utils/ToastSettings";
import {toast} from "react-toastify";
import {useReportRequest} from "../../Reports/ReportRequest/ReportRequestProvider";
import { DashboardEnum } from "../../../enums/Superset/DashboardEnum";

interface ContextProps {
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    isLoading: boolean,
    hasElem: boolean,
    setHasElem: Dispatch<SetStateAction<boolean>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleUnableType: () => Promise<any>,
    handlePartnerView: () => Promise<any>,
    handleCommercialView: () => Promise<any>,
    handlePartnerDetailed: () => Promise<any>,
    handleAnalytics: () => Promise<any>,
}

export const DashboardContext = createContext<ContextProps>({} as ContextProps);

interface DashboardProviderProps {
    children: ReactNode;
}

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
    const [params, setParams] = useState<any>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasElem, setHasElem] = useState<boolean>(false);
    const { isLoadingFile, setIsLoadingFile } = useReportRequest();
    const service = new DashboardService();

    const handleUnableType = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportUnableType(params.filter.startDate, params.filter.endDate);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handlePartnerView = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportPartnerView(params.filter.startDate, params.filter.endDate);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleCommercialView = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportCommercialView(params.filter.startDate, params.filter.endDate);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handlePartnerDetailed = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportPartnerDetailed(params.filter.startDate, params.filter.endDate, params.filter.agent ? params.filter.agent[0] : '');

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleAnalytics = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportAnalytics(params.filter.startDate, params.filter.endDate);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };


    return (
        <DashboardContext.Provider value={{
            isLoading,
            error,
            setError,
            params,
            setParams,
            hasElem,
            setHasElem,
            handleUnableType,
            handlePartnerView,
            handleCommercialView,
            handlePartnerDetailed,
            handleAnalytics,
        }}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => useContext(DashboardContext);

export async function fetchGuestTokenFromBackend(){
    try {
        const service = new DashboardService();
        const [_Response, _Error] = await service.getGuestToken(DashboardEnum.MARKETPLACE);
        if (!!_Error) {
            return "";
        }
        
        return _Response?.data?.token;

    } catch (error: any) {
        console.warn(error);
        return "";
    }
};
