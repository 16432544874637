import { CampaignCreateModel } from "../../../models/Marketplace/Campaign/CampaignCreateModel";
import { CampaignModel } from "../../../models/Marketplace/Campaign/CampaignModel";
import { CampaignParams } from "../../../models/Marketplace/Campaign/CampaignParams";
import { CampaignSimulationFilter } from "../../../models/Marketplace/Campaign/CampaignSilmulaitonFilter";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class DashboardService extends BaseService {


    async getGuestToken(dashboarduid: string): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/dashboard/superset/guesttoken/${dashboarduid}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async exportUnableType(start_date: any, end_date: any): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/reports/unable_to_type?start_date=${start_date}&end_date=${end_date}`, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportPartnerView(start_date: any, end_date: any): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/reports/partner_view?start_date=${start_date}&end_date=${end_date}`, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportCommercialView(start_date: any, end_date: any): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/reports/commercial_view?start_date=${start_date}&end_date=${end_date}`, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportPartnerDetailed(start_date: any, end_date: any, agent: any): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/reports/partner_detailed?start_date=${start_date}&end_date=${end_date}&agent=${agent}`, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportAnalytics(start_date: any, end_date: any): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/admin/reports/analytics?start_date=${start_date}&end_date=${end_date}`, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}