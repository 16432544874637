import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./ContractFormTableBaseCard.scss";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import moment from "moment";
import FormatMoney from "../../../../../utils/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  BiMonthlyProductionGroupModel,
} from "../../../../../models/BI/Dashboard/BiMonthlyProductionModel";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";

interface ContractFormTableBaseCardProps {
  data: BiMonthlyProductionGroupModel[];
  hideButtons: any[];
  setHideButtons: Dispatch<SetStateAction<any[]>>;
  months: string[],
  nameColumnLabel?: string;
}

const ContractFormTableBaseCard: FC<ContractFormTableBaseCardProps> = ({
  data,
  hideButtons,
  setHideButtons,
  months,
  nameColumnLabel = "Forma de Contrato",
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const {
    biCellColor,
    dataFontSize,
    tableHeight
  } = useBiDashboard();

  const hasData = (): boolean => {
    if (months?.length > 0) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">
              {nameColumnLabel}
            </div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      months?.forEach((item: string, index: number) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {moment(item, "YYYY-MM").format("MMMM")}
            </div>
          ),
          selector: (data: any) => data[item] ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data[item ?? ""])}
            </div>
          ),
          sortable: true,
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          compact: true,
          omit: !hideButtons?.find((x: any) => x.alias === item)?.show,
        });

        if (index > 0) {
          _columns.push({
            name: <div className="bg-info text-table text-uppercase">C/Q</div>,
            selector: (data: any) => data[`${item}cq`] ?? 0,
            right: true,
            cell: (data: any) => (
              <div
                className={`${data[`${item}cq`] < 0 ? "bg-negative" : "bg-positive"
                  } text-nowrap`}
              >
                {FormatMoney(data[`${item}cq`], false)}%
                <FontAwesomeIcon
                  icon={data[`${item}cq`] < 0 ? faArrowDown : faArrowUp}
                  className="ms-2"
                />
              </div>
            ),
            sortable: true,
            omit:
              !hideButtons?.find((x: any) => x.alias === "cq")?.show ||
              !hideButtons?.find((x: any) => x.alias === item)?.show,
          });
        }
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data[`${item}participation`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data[`${item}participation`], false)}%</div>
          ),
          sortable: true,
          compact: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "participation")?.show ||
            !hideButtons?.find((x: any) => x.alias === item)?.show,
        });
      });


      _columns.push({
        name: (
          <div className="bg-info text-table text-uppercase">PROJEÇÃO</div>
        ),
        selector: (data: any) => data?.projection ?? 0,
        right: true,
        cell: (data: any) => (
          <div className="text-nowrap">{FormatMoney(data?.projection)}</div>
        ),
        sortable: true,
        style: {
          paddingLeft: '18px',
          paddingRight: '18px',
        },
        omit: !hideButtons?.find((x: any) => x.alias === "projection")?.show,
      });

      _columns.push({
        name: (
          <div className="bg-info text-table text-uppercase">
            PROJEÇÃO C/Q
          </div>
        ),
        selector: (data: any) => data?.projectionGrowth ?? 0,
        right: true,
        cell: (data: any) => (
          <div
            className={`${data?.projectionGrowth < 0 ? "bg-negative" : "bg-positive"
              }`}
          >
            {FormatMoney(data?.projectionGrowth, false)}%
            <FontAwesomeIcon
              icon={data?.projectionGrowth < 0 ? faArrowDown : faArrowUp}
              className="ms-2"
            />
          </div>
        ),
        sortable: true,
        omit: !hideButtons?.find((x: any) => x.alias === "projectionGrowth")
          ?.show,
      });

      setColumns(_columns);
    }
  }, [data, hideButtons]);

  const mountRows = () => {
    if (hasData()) {
      const _rows: any = [];

      data?.forEach((item) => {
        const _row: any = {
          name: item.name,
        };

        item?.periods?.forEach((period: any) => {
          _row[period.month ?? ""] = period.totalProduction;
          _row[`${period.month}cq`] = period.totalProductionGrowth;
          _row[`${period.month}participation`] =
            period.totalProductionPercentage;
        });

        if (item?.periods && item?.periods?.length > 0) {
          _row.projection =
            item?.periods[item?.periods?.length - 1]?.totalProductionProjection;
          _row.projectionGrowth =
            item?.periods[
              item?.periods?.length - 1
            ].totalProductionProjectionGrowth;
        }

        _rows.push(_row);
      });

      setRows(_rows);
    }
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];
      months?.forEach((item) => {
        _buttons.push({
          alias: item,
          label: moment(item, "YYYY-MM").format("MMMM"),
          show: true,
        });
      });

      _buttons.push({
        alias: `cq`,
        label: "Crescimento/Queda",
        show: true,
      });

      _buttons.push({
        alias: `participation`,
        label: "Participação",
        show: true,
      });

      _buttons.push({
        alias: `projection`,
        label: "Projeção",
        show: true,
      });

      _buttons.push({
        alias: `projectionGrowth`,
        label: "Projeção C/Q",
        show: true,
      });

      setHideButtons(_buttons);
    }
  };

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [data]);

  return (
    <div
      className="ContractFormTableBaseCard mt-3"
      data-testid="ContractFormTableBaseCard"
    >
      <AppDataTable
        columns={columns}
        rows={rows}
        pagination={false}
        cellColor={biCellColor}
        cellFontSize={dataFontSize}
        display="flex"
        maxHeight={tableHeight}
      />
    </div>
  );
};

export default ContractFormTableBaseCard;
