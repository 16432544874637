import { FC, useEffect, useState } from 'react';
import './DashboardAdmin.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import { useExample } from '../../../providers/ExampleProvider';
import { toast } from 'react-toastify';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useDashboard, fetchGuestTokenFromBackend} from '../../../providers/Marketplace/Dashboard/DashboardProvider';
import { DashboardEnum } from '../../../enums/Superset/DashboardEnum';
import TooltipItem from '../../../components/TooltipItem/TooltipItem';
import SidebarMarketplaceDashboardFilter from '../../../components/Marketplace/Dashboard/SidebarMarketplaceDashboardFilter/SidebarMarketplaceDashboardFilter';

interface DashboardAdminProps { }

interface EmbedDashboardParams {
  fetchGuestToken: () => Promise<string>;
}

const DashboardAdmin: FC<DashboardAdminProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const {  isLoading, error, hasElem, setHasElem, handleUnableType, handlePartnerView, handleCommercialView, 
    handlePartnerDetailed, handleAnalytics
  } = useDashboard();
  const { handlePaginate } = usePaginate(); 

  useEffect(() => {
    const container = document.getElementById("superset-container") as HTMLElement;
    const supersetDomain: string = process.env.REACT_APP_SUPERSET || '';

    if (container) {
      embedDashboard({
          id: DashboardEnum.MARKETPLACE, 
          supersetDomain: supersetDomain,
          mountPoint: container, 
          fetchGuestToken: fetchGuestTokenFromBackend,
          dashboardUiConfig: { hideTitle: true, hideChartControls: true }
      });
    }
  }, [hasElem]);

  const renderContent = (): JSX.Element => {
    return (
      <>
      <Row className='mt-3'>
        <Col sm={12} className='text-center text-md-end pb-2'>
          <button
            className='btn btn-primary mb-0 ms-2'
            onClick={() => handleUnableType()}
          >
            Não foi possivel Digitar
          </button>
          <button
              className='btn btn-primary mb-0 ms-2'
              onClick={() => handlePartnerView()}
          >
            Visão Parceiro
          </button>
          <button
              className='btn btn-primary mb-0 ms-2'
              onClick={() => handleCommercialView()}
          >
            Visão Comercial
          </button>
          <button
              className='btn btn-primary mb-0 ms-2'
              onClick={() => handlePartnerDetailed()}
          >
            Detalhado Parceiro
          </button>
          <button
              className='btn btn-primary mb-0 ms-2'
              onClick={() => handleAnalytics()}
          >
              Analítico
          </button>
        </Col>
    </Row>
      <div className="table-responsive">
        <div className="w-100 h-100" id="superset-container" > 
        </div>
      </div>
      </>
    );
  }

  return (
    <>
      <MasterPage
        title="DashboardAdmin"
        icon="fas fa-file"
        footerFixed
        footerInverse
      >
        <div className="DashboardAdmin" data-testid="DashboardAdmin">
          <PageBase
            title="Dashboard"
            subtitle="Dashboard administrativo do Marketplace"
            error={error}
            content={renderContent()}
            handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          />

        </div>

      </MasterPage>
      
      <SidebarMarketplaceDashboardFilter/>
    </>
  );
}

export default DashboardAdmin;

