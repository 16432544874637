import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CampaignProvider } from "../../providers/Marketplace/Campaign/CampaignProvider";
import { SelectFinancialProvider } from "../../providers/Registrations/Financial/SelectFinancialProvider";
import { OpportunityProvider } from '../../providers/Marketplace/Opportunities/OpportunityProvider';
import { EndpointProvider } from '../../providers/Marketplace/Endpoint/EndpointProvider';
import { EasyPortProvider } from '../../providers/Marketplace/EasyPort/EasyPortProvider';
import { AccessControlProvider } from '../../providers/Marketplace/AccessControl/AccessControlProvider';
import { CompanyProvider } from '../../providers/Registrations/Company/CompanyProvider';
import { TypeProvider } from '../../providers/Registrations/Partner/PartnerTypeProvider';
import { AccessProfileProvider } from '../../providers/Registrations/Access/AccessProfileProvider';
import { DashboardProvider } from '../../providers/Marketplace/Dashboard/DashboardProvider';

interface Props {
    element?: any;
    path?: string;
}

export const MarketplaceRoute: FC<Props> = () => {
    return (
        <CampaignProvider>
            <OpportunityProvider>
                <EndpointProvider>
                    <EasyPortProvider>
                        <SelectFinancialProvider>
                            <AccessControlProvider>
                                <CompanyProvider>
                                    <TypeProvider>
                                        <AccessProfileProvider>
                                            <DashboardProvider>
                                                <Outlet />
                                            </DashboardProvider>
                                        </AccessProfileProvider>
                                    </TypeProvider>
                                </CompanyProvider>
                            </AccessControlProvider>
                        </SelectFinancialProvider>
                    </EasyPortProvider>
                </EndpointProvider>
            </OpportunityProvider>
        </CampaignProvider>
    );
}
