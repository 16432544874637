import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useContext, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

interface ContextProps {
    showSidebarFilter: boolean,
    setShowSidebarFilter: Dispatch<SetStateAction<boolean>>
}

export const SidebarFilterContext = createContext<ContextProps>({} as ContextProps);

interface SidebarFilterProviderProps {
    children: ReactNode;
}

export const SidebarFilterProvider = ({ children }: SidebarFilterProviderProps) => {
    const [showSidebarFilter, setShowSidebarFilter] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        if (isMobile) {
            setShowSidebarFilter(false);
        }
    }, []);

    useEffect(() => {
        let body = document.getElementsByTagName("body")[0];

        if (!isMobile) {
            if (!!showSidebarFilter) {
                if (body) {
                    body.classList.add('sidebar-filter-show');
                    body.classList.remove('sidebar-filter-hidden');
                }
            }
            else {
                if (body) {
                    body.classList.add('sidebar-filter-hidden');
                    body.classList.remove('sidebar-filter-show');
                }
            }
        }
    }, [showSidebarFilter, location]);

    return (
        <SidebarFilterContext.Provider value={{
            showSidebarFilter,
            setShowSidebarFilter
        }}>
            {children}
        </SidebarFilterContext.Provider>
    );
};

export const useSidebarFilter = () => useContext(SidebarFilterContext);