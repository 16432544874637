import { MonitoringCommercialPointsParamsModel } from "../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsParamsModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from "file-saver";

const URL = 'api/connect/progress';

export class MonitoringCommercialPointsService extends BaseService {

    async list(params: MonitoringCommercialPointsParamsModel): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/scores`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getExcel(params: MonitoringCommercialPointsParamsModel): Promise<any[]> {
        try {
            const response = await api.get(`${URL}/excel`, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getExcelAnalytical(id: number): Promise<any[]> {
        try {
            const response = await api.post(`${URL}/export`, { id }, { responseType: 'blob' });
            const filename = response.headers['content-disposition'].split('filename=')[1];
            FileSaver.saveAs(response.data, filename, { autoBom: false });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async fetchManualGoal(params: any, file: File): Promise<any> {
        try {
            const formdata = new FormData();
            formdata.append("closingDate", params?.period?.toString());
            formdata.append("accessProfileId", params?.accessProfileId?.toString());
            formdata.append("connectGoalId", params?.goal?.toString());
            formdata.append("file", file);

            return this.handleResponse(await api.post(`${URL}/import`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }


}