import { FC, useEffect, useRef, useState } from 'react';
import './SidebarVisitsReportsFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useVisitsReports } from '../../../../providers/Visits/VisitsReports/VisitsReportsProvider';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import { wasFinished } from '../../../../enums/Visits/VisitsReports/WasFinished';
import { useVisits } from '../../../../providers/Visits/VisitsProvider';
import { usePartner } from '../../../../providers/Registrations/Partner/PartnerProvider';
import { useVisitsTypesContacts } from '../../../../providers/Visits/VisitsTypesContacts/VisitsTypesContactsProvider';

interface SidebarVisitsReportsFilterProps { }

// @ts-ignore
export const defaultValues = {
  commercialId: '',
  userId: '',
  wasFinished: '',
  startDate: '',
  endDate: '',
  visitTypeContact: '',
} as any;

const SidebarVisitsReportsFilter: FC<SidebarVisitsReportsFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
  const [valuePartner, setValuePartner] = useState<string>('');
  const [selectedWasFinished, setSelectedWasFinished] = useState<any | null>(null);
  const [selectedUserVisitorId, setSelectedUserVisitorId] = useState<any | null>(null);
  const [selectedProspecting, setSelectedProspecting] = useState<any | null>(null);
  const [selectedVisitTypeContact, setSelectedVisitTypeContact] = useState<any[]>([]);

  const animatedComponents = makeAnimated();

  const refSubmitButtom = useRef<HTMLButtonElement>(null);

  const { handleSubmit, control, setValue } = useForm<any>({ defaultValues });

  const { params, setParams, handleList } = useVisits();
  const { paramsAgents, setParamsAgents, handleListByMonths, handleListByAgents } = useVisitsReports();
  const { commercialsOptions } = useCommercial();
  const { handleListProspecting, prospectingOptions } = usePartner();

  const { typesContactsOptions } = useVisitsTypesContacts();


  const onSubmit = async (data: any) => {
    data.startDate = data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : '';
    data.endDate = data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : '';
    data.userId = data?.userId ? data?.userId?.value : null;
    data.visitTypeContact = data?.visitTypeContact ? data.visitTypeContact.map((x: any) => x.value) : null;

    setParams({ ...params, ...data, ...{ page: 1 } });
    setParamsAgents({ ...paramsAgents, ...data });

  }

  useEffect(() => {
    setSelectedWasFinished(wasFinished[0]);
    setParams({ ...params, startDate: moment(new Date()).format('YYYY-MM-01'), endDate: moment(new Date()).format('YYYY-MM-DD') })
  }, []);

  useEffect(() => {
    setValue('visitTypeContact', selectedVisitTypeContact ?? null);
  }, [selectedVisitTypeContact]);


  useEffect(() => {
    if (!!params) {
      handleList().then();
      handleListByMonths(params?.commercialId);
      handleListByAgents({
        ...params,
        userVisitorId: params?.commercialId,
        userVisitedId: params?.userId,
        startDate: params?.startDate ?? moment(new Date()).format('YYYY-MM-01'),
        endDate: params?.endDate ?? moment(new Date()).format('YYYY-MM-DD')
      });
    }
  }, [params]);

  useEffect(() => {
    console.log(selectedUserVisitorId?.value)
    setValue('commercialId', selectedUserVisitorId?.value ?? null);
    setSelectedProspecting(null);
    !!selectedUserVisitorId && handleListProspecting(selectedUserVisitorId?.value).then();
  }, [selectedUserVisitorId]);

  useEffect(() => {
    setValue('wasFinished', selectedWasFinished?.value ?? null)
  }, [selectedWasFinished]);

  useEffect(() => {
    setValue('partnerRegistrationProspectingId', selectedProspecting?.value ?? null);
  }, [selectedProspecting]);

  useEffect(() => {
    if (commercialsOptions && commercialsOptions?.length === 1) {
      setSelectedUserVisitorId(commercialsOptions[0]);
    }
  }, [commercialsOptions]);

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)} buttonRef={refSubmitButtom}>
      <div className="SidebarVisitsReportsFilter" data-testid="SidebarVisitsReportsFilter">
        <Row className="mt-1">
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              nameStartDate='startDate'
              nameEndDate='endDate'
              startLabelText={'DATA INICIAL'}
              endLabelText={'DATA FINAL'}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="commercialId">COMERCIAL</label>
              <ReactSelect
                name="commercialId"
                isSearchable
                isClearable={commercialsOptions?.length > 1}
                options={commercialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedUserVisitorId ?? ''}
                defaultValue={selectedUserVisitorId}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedUserVisitorId(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="userId">PARCEIRO</label>
              <InputSearchPartner
                name="userId"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="wasFinished">FINALIZADAS</label>
              <ReactSelect
                name="wasFinished"
                isSearchable
                options={wasFinished}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedWasFinished ?? ''}
                defaultValue={selectedWasFinished}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedWasFinished(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="visitTypeContact ">TIPO DE CONTATO</label>
              <ReactSelect
                name="visitTypeContact "
                isSearchable
                isMulti
                isClearable={typesContactsOptions?.length > 1}
                options={typesContactsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedVisitTypeContact ?? []}
                defaultValue={selectedVisitTypeContact}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedVisitTypeContact(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="prospecting">PROSPECÇÃO</label>
              <ReactSelect
                name="prospecting"
                isSearchable
                isClearable
                options={prospectingOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                value={selectedProspecting ?? ''}
                defaultValue={selectedProspecting}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedProspecting(val);
                }}
                styles={customStyles}
                isDisabled={!selectedUserVisitorId}
              />
            </FormGroup>
          </Col>
        </Row>


      </div>
    </SidebarFilter>
  );
}

export default SidebarVisitsReportsFilter;
