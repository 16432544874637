import { FC, useEffect, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset, faInfoCircle, faList, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";
import { useVisitsDivergencesAssessments } from "../../../../providers/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessmentsProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from './Validate';
import { useAuth } from "../../../../providers/AuthProvider";
import { toast } from "react-toastify";
import { VisitsDivergenceAssessmentsModel } from "../../../../models/Visits/VisitsDivergenceAssessments/VisitsDivergenceAssessmentsModel";
import { faAddressCard, faImage, faListAlt, faMap, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import GoogleMaps from "../../../GoogleMaps/GoogleMaps";
import Gallery from "../../../Gallery/Gallery";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import './ModalDivergenceDetail.scss';
import { VisitModel } from "../../../../models/Visits/IndicatorManagement/VisitModel";

interface ModalDivergenceDetailProps {
    show: boolean;
    onClose: () => void;
    visit?: VisitModel;
}

type FormValues = {
    id?: number,
    visitId?: number,
    observation: string,
    statusId: number
}

const ModalDivergenceDetail: FC<ModalDivergenceDetailProps> = ({ show, onClose, visit }) => {

    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(true);
    const { setError, handleList, handleSave, params } = useVisitsDivergencesAssessments();
    const { onInvalid } = useAuth();
    const [images, setImages] = useState<any[]>([]);

    const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const handleClose = async () => {
        reset();
        onClose();
    }

    const onSubmit = async (data: any, statusId: number) => {
        setIsLoadingSubmit(true);
        setError('');

        data = { ...data, visitId: visit?.id, id: visit?.visitDivergenceAssessment?.id || null, statusId: statusId };

        const ret = await handleSave(data as VisitsDivergenceAssessmentsModel);

        if (ret) {
            toast.success('Divergência salva com sucesso!');
            handleList(params);
            onClose();
        }

        setIsLoadingSubmit(false);
    }

    useEffect(() => {
        if (show && !!visit) {
            const _images: any[] = visit?.visitsImages?.map((image: any) => { return { original: image?.file, thumbnail: image?.file }; }) || [];
            setImages(_images);
            setIsLoadingSubmit(false);
            setValue('observation', visit?.visitDivergenceAssessment?.observationStatusDivergence || '');
        }
    }, [show, visit]);

    return (
        <>
            <ModalDefault
                title={`Detalhar divergência - ` + visit?.id}
                show={show}
                onClose={handleClose}
                sizeModal={'lg'}
                showFooter={true}
                buttonText={'Reprovar'}
                showButtonSpinner={isLoadingSubmit}
                showButtonAdditionalSpinner={isLoadingSubmit}
                disabledSubmit={isLoadingSubmit || visit?.visitDivergenceAssessment}
                handleSubmit={handleSubmit((data: any) => { onSubmit(data, 2) })}
                handleAdditionalSubmit={handleSubmit((data: any) => { onSubmit(data, 1) })}
                disableAdditionalSubmit={isLoadingSubmit || visit?.visitDivergenceAssessment}
                buttonBackgroundColor='btn-danger'
                buttonAdditionalBackgroundColor='btn-success'
                buttonTextColor='text-white'
                buttonAdditionalTextColor='text-white'
                buttonIcon='fa-solid fa-thumbs-down me-1'
                buttonAdditionalIcon='fa-solid fa-thumbs-up me-1'
                buttonTextAdditional='Aprovar'
                backdrop="static"
                backgroundColor="#f8f9fa"

            >
                <div className="ModalDivergenceDetail" data-testid="ModalDivergenceDetail">
                    <Row>
                        <Col md={6} xs={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="userVisitor">
                                    <FontAwesomeIcon icon={faUserCircle} /> COMERCIAL
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={`${visit?.userVisitor?.partnerCode} - ${visit?.userVisitor?.name}`}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} xs={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="userVisited">
                                    <FontAwesomeIcon icon={faUserCircle} /> PARCEIRO
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={visit?.userVisited ?
                                        `${visit?.userVisited?.partnerCode ?? ''} - ${visit?.userVisited?.name ?? ''}` :
                                        `${visit?.partnerRegistrationProspecting?.partnerCode ?? ''} - ${visit?.partnerRegistrationProspecting?.name ?? ''}`}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="address">
                                    <FontAwesomeIcon icon={faAddressCard} /> ENDEREÇO DO PARCEIRO
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={visit?.userVisited?.address
                                        && visit?.userVisited?.address?.publicPlace
                                        && visit?.userVisited?.address?.cep ? `${visit?.userVisited?.address?.publicPlace}, ${visit?.userVisited?.address?.numbers || '0'}, ${visit?.userVisited?.address?.neighborhood || 'Não cadastrado'}, ${visit?.userVisited?.address?.cep}, ${visit?.userVisited?.address?.city || 'Não cadastrado'}-${visit?.userVisited?.address?.state || 'Não cadastrado'}` : ''}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="userVisitedAddress">
                                    <FontAwesomeIcon icon={faAddressCard} /> ENDEREÇO VISITADO
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={visit?.address?.publicPlace
                                        && visit?.address?.cep ? `${visit?.address?.publicPlace}, ${visit?.address?.numbers || '0'}, ${visit?.address?.neighborhood || 'Não cadastrado'}, ${visit?.address?.cep}, ${visit?.address?.city || 'Não cadastrado'}-${visit?.address?.state || 'Não cadastrado'}` : ''}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="userVisitedAddress">
                                    <FontAwesomeIcon icon={faHeadset} /> TIPO DE CONTATO
                                </label>
                                <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                                    <div className="item">
                                        <div className="bg-item position-relative">
                                            <span>
                                                {visit?.visitTypeContact?.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="activities">
                                    <FontAwesomeIcon icon={faListAlt} /> AÇÃO
                                </label>
                                <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                                    {visit?.visitsActions?.map((item: any, key: number) => (
                                        <div className="item" key={key}>
                                            <div className="bg-item position-relative">
                                                <span>
                                                    {item?.name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="activities">
                                    <FontAwesomeIcon icon={faListAlt} /> FOCO
                                </label>
                                <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                                    {visit?.visitsTypesActivities?.map((item: any, key: number) => (
                                        <div className="item" key={key}>
                                            <div className="bg-item position-relative">
                                                <span>
                                                    {item?.name}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="observation">
                                    <FontAwesomeIcon icon={faInfoCircle} /> OBSERVAÇÃO
                                </label>
                                <textarea className="form-control" value={visit?.observation} rows={4} disabled></textarea>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="location">
                                    <FontAwesomeIcon icon={faMap} /> LOCALIZAÇÃO
                                </label>
                                <GoogleMaps
                                    zoom={12}
                                    points={[
                                        { lat: Number(visit?.latitude) || 0, lng: Number(visit?.longitude) || 0, label: 'Visita' },
                                        { lat: Number(visit?.userVisited?.address?.latitude) || 0, lng: Number(visit?.userVisited?.address?.longitude) || 0, label: 'Parceiro' },
                                    ]}
                                    showCircle={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="location">
                                    <FontAwesomeIcon icon={faImage} /> IMAGENS
                                </label>
                                <Gallery images={images} thumbnailPosition="left" />
                                <div className='text-end mt-2 message'>
                                    <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> Clique na imagem para amplia-la.
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md={12} xs={12}>
                            <FormGroup className="form-group">
                                <label htmlFor="observation">
                                    <FontAwesomeIcon icon={faList} /> Observação Divergência
                                </label>
                                <textarea
                                    id="observation"
                                    className={`form-control ${onInvalid(errors?.observation)}`}
                                    rows={4}
                                    defaultValue={visit?.visitDivergenceAssessment?.observationStatusDivergence || ''}
                                    disabled={visit?.visitDivergenceAssessment}
                                    {...register('observation')}></textarea>
                                <ErrorMessage name={'Obersevação de divergência'} type={errors?.observation?.type} min={1} max={255} />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </ModalDefault>
        </>
    );
}

export default ModalDivergenceDetail;