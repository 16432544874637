import { FC, useEffect, useState } from "react";
import "./SidebarMarketplaceDashboardFilter.scss";
import SidebarFilter from "../../../SidebarFilter/SidebarFilter";
import { useForm } from "react-hook-form";
import { Col, FormGroup, Row } from "react-bootstrap";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import moment from "moment";
import InputSearchPartner from "../../../Registrations/Partner/InputSearchPartner/InputSearchPartner";
import { useDashboard } from "../../../../providers/Marketplace/Dashboard/DashboardProvider";

interface SidebarMarketplaceDashboardFilterProps { }

const SidebarMarketplaceDashboardFilter: FC<SidebarMarketplaceDashboardFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [valuePartner, setValuePartner] = useState<any>();
  const [partnerObj, setPartnerObj] = useState<any>();

  const { setParams, params} = useDashboard();
  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    if (!valuePartner) {
      setPartnerObj(undefined);
    }
  }, [valuePartner]);

  useEffect(() => {
    setParams({
      ...params,
      filter: {
        ...params?.filter,
        startDate: startDate,
        endDate: endDate
      },
    });
  }, []);

  const onSubmit = async (data: any) => {
    const _data: any = {};
    _data.agent = partnerObj?.partnerId ? [partnerObj.partnerId] : [];
    
    setParams({
      ...params,
      filter: {
        ...params?.filter,
        startDate: moment(data?.startDate).format("YYYY-MM-DD"),
        endDate: moment(data?.endDate).format("YYYY-MM-DD"),
        ..._data,
      },
    });
  };

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div
        className="SidebarMarketplaceDashboardFilter"
        data-testid="SidebarMarketplaceDashboardFilter"
      >
        <Row>
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={"DATA INICIAL"}
              endLabelText={"DATA FINAL"}
              maxEndDate={moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="user">PARCEIRO</label>
              <InputSearchPartner
                name="partner"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
                setPartnerObj={setPartnerObj}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
};

export default SidebarMarketplaceDashboardFilter;
