import React, { FC, useEffect, useState } from 'react';
import './TermsTracking.scss';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEye, faFileAlt, faIdBadge, faRotate, faSignature } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import SidebarTermsTrackingFilter from '../../../components/Terms/TermsTracking/SidebarTermsTrackingFilter/SidebarTermsTrackingFilter';
import { useTermsTracking } from '../../../providers/Terms/TermsTrackingProvider';
import moment from 'moment';
import FormatCpfCnpj from '../../../utils/FormatCpfCnpj';
import FormatPhone from '../../../utils/FormatPhone';
import { faCircleXmark, faFolderOpen, faFileLines } from '@fortawesome/free-regular-svg-icons';
import ModalSeeHistoryInsert from '../../../components/Terms/TermsTracking/ModalSeeHistoryInsert/ModalSeeHistoryInsert';
import { useCompany } from '../../../providers/Registrations/Company/CompanyProvider';
import { TermsStatusEnum } from '../../../enums/Terms/EnumTerms';
import returnBadge from '../../../utils/BadgeTermStatus';
import ModalSignature from '../../../components/Terms/TermsTracking/ModalSignature/ModalSignature';
import { VersionTermsEnum } from '../../../enums/Terms/VersionTerms';

interface TermsTrackingProps { }

const TermsTracking: FC<TermsTrackingProps> = () => {
  const [showModalSeeHistory, setShowModalSeeHistory] = useState<boolean>(false);
  const [showModalSignature, setShowModalSignature] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { isLoadingFile, handleList, params, setParams, isLoading, error, handleResendTerm, 
          handleCancelTerm, setTermsTracking, termsTrackings, pages, listStatus, listDepartments,
          handleExcel, handleSeeHistory, handleTermSignature } = useTermsTracking();
  const { handlePaginate } = usePaginate();
  const { handleGetAll } = useCompany();

  useEffect(() => {
    handleList();
  }, [params]);

  const handleViewDetails = (item: any) => {

    setExpandedRows((x) =>
      x.includes(item?.id)
        ? x.filter((row) => row !== item?.id)
        : [...x, item?.id]
    );
  }

  const onClickSeeHistory = (item: any) => {
    
    handleSeeHistory({
      termId: item.id,
      page: 1,
      perPage: 10
    });

    setShowModalSeeHistory(true)
  }

  const onClickModalSignature = (termId: number) => {
    handleTermSignature(termId);
    setShowModalSignature(true)
  }

  const getTermType = (hasPhysicalDocument: number, envelopeId: any, documentSupplierId: any): string => {
    if (hasPhysicalDocument === 1) {
      return 'FÍSICO';
    }
    if (hasPhysicalDocument === 0 && !envelopeId && !documentSupplierId) {
      return 'ACEITE';
    }
    return 'DIGITAL';
  };

  const resendTerm = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja reenviar o termo?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, reenviar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleResendTerm({
          id: item?.termDocument?.id || null,
          userId: item?.user?.id || null,
          supplier: item?.termDocument?.termDocumentSignatureChannel[0]?.termSupplier?.id || null,
          version: VersionTermsEnum.v1
        });
        if (ret) {
          toast.success('Termo reenviado com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível reenviar o termo.<br>${error}`, 'error');
        }
      }
    });
  }

  const cancelTerm = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja cancelar o termo?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleCancelTerm({
          termId: item?.id || null,
          supplier: item?.termDocument?.termDocumentSignatureChannel[0]?.termSupplier?.id || null,
          version: VersionTermsEnum.v1
        });
        if (ret) {
          toast.success('Termo cancelado com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível cancelar o termo.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderParty = (data:any, property:string) => {
    let party = data?.filter((item:any) => item.type == 'party')?.shift() || null
    
    if (property == 'email'){
      return party?.email || '';
    }

    return FormatPhone(party?.phone) || '';
  }

  useEffect(() => {
    listStatus().then();
    listDepartments().then();
    handleGetAll().then();
  },[]);

  const renderList = (): JSX.Element => {
    return (
      <>
        {termsTrackings?.length > 0 ? (
          <>
            {termsTrackings?.map((x: any, key: number) => (
              <React.Fragment key={x?.id}>
                <tr className={`text-sm ${expandedRows.includes(x?.id) ? 'no-border' : ''}`}>
                  <td className="text-center">
                    {x?.id} <br />
                    {
                      parseInt(x?.termDocument?.hasExclusive) == 1 && (
                        <><span className='badge badge-warning mt-2'> EXCLUSIVO </span></>
                      )
                    }  
                  </td>
                  <td>
                    <small className='font-italic font-weight-bold'> Parceiro: </small> {x?.user?.partnerCode} - {x?.user?.name?.toUpperCase()} <br />
                    <small className='font-italic font-weight-bold'> CPF: </small> {FormatCpfCnpj(x?.user?.registrationData?.cpf)}<br />
                    <small className='font-italic font-weight-bold'> E-mail: </small> {renderParty(x?.termSubscriber, 'email')} <br />
                    <small className='font-italic font-weight-bold'> Telefone: </small> {renderParty(x?.termSubscriber, 'phone')}
                  </td>
                  <td>
                    <small className='font-italic font-weight-bold'> Título do termo:</small> {x?.termDocument?.name?.toUpperCase()} <br />
                    <small className='font-italic font-weight-bold'> Fornecedor:</small> {x?.termDocument?.termDocumentSignatureChannel[0]?.termSupplier?.name} <br />
                    <small className='font-italic font-weight-bold'> Tipo de documento:</small> {getTermType(x?.hasPhysicalDocument, x?.envelopeId, x?.documentSupplierId)} <br />
                    <small className='font-italic font-weight-bold'> Criação: </small> {moment(x?.createdAt).format("DD/MM/YYYY HH:mm")} <br />
                    <span className='text-danger'> <small className='font-italic font-weight-bold'> Expiração: </small> {moment(x?.createdAt).add(7, 'd').format("DD/MM/YYYY")} </span> <br />
                  </td>
                  <td>
                    {x?.termDocument?.termDocumentSignatureChannel?.length > 0 && (
                      <>
                        <ul className='mb-0' style={{listStyleType: 'disclosure-closed'}}>
                          {x?.termDocument?.termDocumentSignatureChannel?.map((channels:any) => (
                            <li>
                              {`${channels?.name}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td>{x?.termDocument?.termDocumentDepartment?.name?.toUpperCase()}</td>
                  <td className='text-center'>
                    <span className={`badge ${returnBadge(x?.termStatus?.id)}`}>
                      {x?.termStatus?.name}
                    </span>
                  </td>
                  <td className="text-center">
                    <DropdownMenuTable key={key}>
                      <Dropdown.Item
                        className="border-radius-md py-2"
                        onClick={() => onClickSeeHistory(x)}
                      >
                        <FontAwesomeIcon icon={faFolderOpen} className="me-2" /> Ver Histórico
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="border-radius-md py-2"
                        onClick={() => handleViewDetails(x)}
                      >
                        <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar Detalhes
                      </Dropdown.Item>
                      {
                        [TermsStatusEnum.PENDENTE, TermsStatusEnum.PROCESSANDO].includes(x?.termStatus?.id) && (
                          <>
                            <Dropdown.Item
                              className="border-radius-md py-2"
                              onClick={() => onClickModalSignature(x?.id)}
                            >
                              <FontAwesomeIcon icon={faFileLines} className="me-2" /> Visualizar Assinaturas
                            </Dropdown.Item>
                          </>
                        )
                      }
                      {
                        [TermsStatusEnum.CANCELADO, TermsStatusEnum.RECUSADO, TermsStatusEnum.EXCLUIDO, TermsStatusEnum.EXPIRADO].includes(x?.termStatus?.id) 
                        && x.countSendUserDocument <= 3 
                        && (
                          <>
                            <Dropdown.Item
                              className="border-radius-md py-2"
                              onClick={() => resendTerm(x)}
                            >
                              <FontAwesomeIcon icon={faRotate} className="me-2" /> Reenviar Termo
                            </Dropdown.Item>
                          </>
                        )
                      } 
                      {[TermsStatusEnum.PENDENTE, TermsStatusEnum.PROCESSANDO].includes(x?.termStatus?.id) && (
                        <>
                        <Dropdown.Item
                          className="border-radius-md py-2"
                          onClick={() => cancelTerm(x)}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} className="me-2" /> Cancelar Termo
                        </Dropdown.Item>
                        </>
                      )}
                    </DropdownMenuTable>
                  </td>
                </tr>

                {expandedRows.includes(x?.id) && (
                  <tr className="collapse-content">
                    <td colSpan={9}>
                      <div className="p-3 bg-light border-radius-md expanded-content">
                        <p><b><FontAwesomeIcon icon={faFileAlt} /> Nome Documento:</b> {x?.termDocument?.name}</p>
                        <p><b><FontAwesomeIcon icon={faIdBadge} /> Termo Exclusivo:</b> {x?.termDocument?.hasExclusive ? "Sim" : "Não"}</p>

                        {x?.termDocument?.enterprises?.length > 0 && (
                          <div>
                            <p><b><FontAwesomeIcon icon={faBuilding} /> Empresas envolvidas:</b></p>
                            <ul>
                              {x.termDocument.enterprises.map((enterprise: any) => (
                                <li key={enterprise.id}>
                                  {`${enterprise.name} - ${FormatCpfCnpj(enterprise.cnpj)}`}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

                        {x?.termDocument?.termDocumentSignatureChannel?.length > 0 && (
                          <div>
                            <p><b><FontAwesomeIcon icon={faSignature} /> Canais de Assinatura:</b></p>
                            <ul>
                              {x.termDocument.termDocumentSignatureChannel.map((channel: any) => (
                                <li key={channel.id}>
                                  {`${channel.name} (${channel.termSupplier?.name})`}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={9} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase opacity-7" scope="col">{`#`}</th>
                  <th className="text-uppercase opacity-7" scope="col">Agente Solicitante</th>
                  <th className="text-uppercase opacity-7" scope="col">Dados do Termo</th>
                  <th className="text-uppercase opacity-7" scope="col">Canais de assinatura</th>
                  <th className="text-uppercase opacity-7" scope="col">Departamento</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                  <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {termsTrackings?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Acompanhamento de Termos"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="TermsTracking" data-testid="TermsTracking">
        <PageBase
          title="Acompanhamento de Termos"
          subtitle="Gerenciamento de acompanhamento de Termos"
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
          hasExcelPdf
          handleExcel={handleExcel}
          isLoadingFile={isLoadingFile}
        />

        <ModalSeeHistoryInsert
          show={showModalSeeHistory}
          onClose={() => setShowModalSeeHistory(false)}
        />

        <ModalSignature
          show={showModalSignature}
          onClose={() => setShowModalSignature(false)}
        />

      </div>

      <SidebarTermsTrackingFilter />
    </MasterPage>
  );
}

export default TermsTracking;
