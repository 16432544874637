import { FC, ReactNode, useEffect, useRef, useState } from "react";
import "./ModalSettings.scss";
import { Badge, Card, Col, FormCheck, FormGroup, Row } from "react-bootstrap";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { TermsDocumentsModel } from "../../../models/Terms/TermsDocumentsModel";
import ModalTermsCompany from "../ModalTermsCompany/ModalTermsCompany";
import ModalTermsCompanyView from "../ModalTermsCompanyView/ModalTermsCompanyView";
import ModalTermsAccessProfile from "../ModalTermsAccessProfile/ModalTermsAccessProfile";
import ModalTermsAccessProfileView from "../ModalTermsAccessProfileView/ModalTermsAccessProfileView";
import ModalTermsUserTypeView from "../ModalTermsUserTypeView/ModalTermsUserTypeView";
import ModalTermsUserType from "../ModalTermsUserType/ModalTermsUserType";
import ModalTermsUserView from "../ModalTermsUserView/ModalTermsUserView";
import ModalTermsUser from "../ModalTermsUser/ModalTermsUser";

interface ModalSettingsProps {
  term: TermsDocumentsModel;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalSettings: FC<ModalSettingsProps> = (term) => {
  const [showModalCompanyView, setShowModalCompanyView] = useState<boolean>(false);
  const [showModalCompany, setShowModalCompany] = useState<boolean>(false);
  const [showModalAccessProfileView, setShowModalAccessProfileView] = useState<boolean>(false);
  const [showModalAccessProfile, setShowModalAccessProfile] = useState<boolean>(false);
  const [showModalUserTypeView, setShowModalUserTypeView] = useState<boolean>(false);
  const [showModalUserType, setShowModalUserType] = useState<boolean>(false);
  const [showModalUserView, setShowModalUserView] = useState<boolean>(false);
  const [showModalUser, setShowModalUser] = useState<boolean>(false);
  const [kindOfPerson, setKindOfPerson] = useState<'F' |'J'>('F');
  const { rules, handleTermsRules, setTerm } = useTermsDocuments();

  useEffect(() => {
    handleTermsRules(term?.term?.id);
  }, []);

  const countItems = (items: any[], key: string) => {
    return items.reduce((acc, item) => {
      const count = item[key] ? item[key].length : 0;
      return acc + count;
    }, 0);
  };

  const kindOfPersonF = rules.filter(item => item.kindOfPerson === 'F');
  const kindOfPersonJ = rules.filter(item => item.kindOfPerson === 'J');

  const totalCompaniesF = countItems(kindOfPersonF, 'company');
  const totalAccessProfileF = countItems(kindOfPersonF, 'accessProfile');
  const totalUserTypeF = countItems(kindOfPersonF, 'userType');
  const totalUserF = countItems(kindOfPersonF, 'user');

  const totalCompaniesJ = countItems(kindOfPersonJ, 'company');
  const totalAccessProfileJ = countItems(kindOfPersonJ, 'accessProfile');
  const totalUserTypeJ = countItems(kindOfPersonJ, 'userType');
  const totalUserJ = countItems(kindOfPersonJ, 'user');

  const handleCompany = (person: 'F' |'J', type: 'view' | 'save') => {
    setKindOfPerson(person)
    setTerm(term.term);
    type === 'view' ? setShowModalCompanyView(true) : setShowModalCompany(true);
  };

  const handleAccessProfile = (person: 'F' |'J', type: 'view' | 'save') => {
    setKindOfPerson(person)
    setTerm(term.term);
    type === 'view' ? setShowModalAccessProfileView(true) : setShowModalAccessProfile(true);
  };

  const handleUserType = (person: 'F' |'J', type: 'view' | 'save') => {
    setKindOfPerson(person)
    setTerm(term.term);
    type === 'view' ? setShowModalUserTypeView(true) : setShowModalUserType(true);
  };

  const handleUser = (person: 'F' |'J', type: 'view' | 'save') => {
    setKindOfPerson(person)
    setTerm(term.term);
    type === 'view' ? setShowModalUserView(true) : setShowModalUser(true);
  };

  const renderList = () => {
    return (
      <>
        <tr key={term.term.id} className="text-sm">          
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleCompany('F', 'view')}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalCompaniesF}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleCompany('F', 'save')}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>          
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleAccessProfile("F", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalAccessProfileF}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleAccessProfile("F", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleUserType("F", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalUserTypeF}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleUserType("F", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleUser("F", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalUserF}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleUser("F", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <span className={'text-primary'} >
              PESSOA FÍSICA
            </span>
          </td>
        </tr>

        <tr key={term.term.id} className="text-sm">          
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleCompany('J', 'view')}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalCompaniesJ}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleCompany('J', 'save')}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>          
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleAccessProfile("J", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalAccessProfileJ}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleAccessProfile("J", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleUserType("J", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalUserTypeJ}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleUserType("J", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <button type="button" className="btn btn-outline-primary text-primary mb-0 ms-2" onClick={() => handleUser("J", "view")}>
              <i className="fa-regular fa-eye me-1"></i>
              {totalUserJ}
            </button>
            <button type="button" className="btn bg-primary text-white mb-0 ms-2" onClick={() => handleUser("J", "save")}>
              <i className="fa-regular fa-plus me-1"></i>
            </button>
          </td>
          <td>
            <span className={'text-primary'} >
              PESSOA JURÍDICA
            </span>
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      <div className="ModalSettings" data-testid="ModalSettings">
        <Card>
          <Card.Header className="pb-0">
            <Card.Title>Configurações</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0"> 
            <div className="table">
              <table className="table table-flush m-0">
                <thead className="thead-light">
                  <tr>
                    <th
                      className="text-uppercase text-xs font-weight-bolder opacity-7"
                      scope="col"
                    >
                      PROMOTORAS
                    </th>
                    <th
                      className="text-uppercase text-xs font-weight-bolder opacity-7"
                      scope="col"
                    >
                      NÍVEIS ACESSO
                    </th>
                    <th
                      className="text-uppercase text-xs font-weight-bolder opacity-7"
                      scope="col"
                    >
                      TIPOS DE AGENTE
                    </th>
                    <th
                      className="text-uppercase text-xs font-weight-bolder opacity-7"
                      scope="col"
                    >
                      AGENTE
                    </th>
                    <th
                      className="text-uppercase text-xs font-weight-bolder opacity-7"
                      scope="col"
                    >
                      TIPOS DE PESSOAS
                    </th>
                  </tr>
                </thead>
                <tbody>{renderList()}</tbody>
              </table>
            </div>
          </Card.Body>
        </Card> 
      </div>

      <ModalTermsCompanyView
        show={showModalCompanyView}
        onClose={() => setShowModalCompanyView(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsCompany
        show={showModalCompany}
        onClose={() => setShowModalCompany(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsAccessProfileView
        show={showModalAccessProfileView}
        onClose={() => setShowModalAccessProfileView(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsAccessProfile
        show={showModalAccessProfile}
        onClose={() => setShowModalAccessProfile(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsUserTypeView
        show={showModalUserTypeView}
        onClose={() => setShowModalUserTypeView(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsUserType
        show={showModalUserType}
        onClose={() => setShowModalUserType(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsUserView
        show={showModalUserView}
        onClose={() => setShowModalUserView(false)}
        kindOfPerson={kindOfPerson}
      />

      <ModalTermsUser
        show={showModalUser}
        onClose={() => setShowModalUser(false)}
        kindOfPerson={kindOfPerson}
      />
    </>
  );
};

export default ModalSettings;
