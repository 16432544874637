import React, { FC } from 'react';
import './BiChangeFontSize.scss';
import { useBiDashboard } from '../../../../providers/BI/Dashboard/BiDashboardProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import TooltipItem from '../../../TooltipItem/TooltipItem';

interface BiChangeFontSizeProps { }

const BiChangeFontSize: FC<BiChangeFontSizeProps> = () => {

  const { dataFontSize, setDataFontSize, biCellColor, setBiCellColor } = useBiDashboard();

  return (
    <div className="BiChangeFontSize" data-testid="BiChangeFontSize">
      <div className='d-flex align-items-center flex-column gap-2 w-100'>
        <TooltipItem text='Aumentar Fonte' position='top'>
          <FontAwesomeIcon
            icon={faCirclePlus}
            className='cursor-pointer'
            onClick={() => setDataFontSize((current: number) => ++current)}
          />
        </TooltipItem>
        <span>
          {dataFontSize}
        </span>
        <TooltipItem text='Diminuir Fonte' position='bottom'>
          <FontAwesomeIcon
            icon={faCircleMinus}
            className='cursor-pointer'
            onClick={() => setDataFontSize((current: number) => --current)}
          />
        </TooltipItem>
        <hr />
        <div>
          <TooltipItem position='bottom' text='Alterar Cor'>
            <input
              type='color'
              className='form-control form-control-color'
              value={biCellColor}
              onChange={(val) => setBiCellColor(val?.target?.value ?? '')}
            />
          </TooltipItem>
        </div>
      </div>
    </div>
  )
};

export default BiChangeFontSize;
