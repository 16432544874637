import { FC, useEffect, useState } from "react";
import ModalDefault from "../../../ModalDefault/ModalDefault";
import { Card, CardBody, Col, FormGroup, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import { VoucherOptions } from "../../../../models/Commissioning/Receipt/ReceiptVouchersOptions";
import { useAuth } from "../../../../providers/AuthProvider";
import { useForm } from "react-hook-form";
import './ModalUplaodVouchers.scss';
import { ModalUploadVoucher } from "../../../../models/Commissioning/Receipt/ModelUploadVoucher";
import { useReceipt } from "../../../../providers/Commissioning/Receipt/ReceiptProvider";

interface ModalUploadVouchersProps {
    show: boolean;
    setShowModalUploadVounchers:any;
    writeOffId: number;
    receiptId: number;
    receiptDate: string;
    businessDate: string;
}

const DefaultFormValuesCreate: ModalUploadVoucher = {
    typeVoucher: 0,
    fileVoucher: '',
}


const ModalUploadVouchers: FC<ModalUploadVouchersProps> = ({show, setShowModalUploadVounchers, writeOffId, receiptId, receiptDate, businessDate}) => {
    
    const animatedComponents                                = makeAnimated();
    const { onInvalid }                                     = useAuth();
    const [isLoadingSubmit, setIsLoadingSubmit]             = useState<boolean>(false);
    const [selectedTypeVoucher, setSelectedTypeVoucher]     = useState<any>(0);

    const {handleSaveFiles, handleGet} = useReceipt();

    const {
        reset,
        register,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm<ModalUploadVoucher>({
        defaultValues: DefaultFormValuesCreate
    });

    const onSubmit = async (data:any) => {

        setIsLoadingSubmit(true);

        let file = data?.fileVoucher?.filter((item:any) => item)?.shift() || null;

        let obj = {
            receiptWriteOffId: writeOffId,
            receiptFilesTypeId: data?.typeVoucher || null,
            file: file[0] || null
        }

        let post = await handleSaveFiles(obj);

        if (post) {
            await handleGet(receiptId, 'low', receiptDate, businessDate);
            setShowModalUploadVounchers(false);
        }
        
        setIsLoadingSubmit(false);
    }

    const handleClose = async () => {
        reset();
        setSelectedTypeVoucher(null);
        setShowModalUploadVounchers(false);
    }

    useEffect(() => {
        setValue('typeVoucher', selectedTypeVoucher?.value);
    }, [selectedTypeVoucher]);

    return (
        <>
            <ModalDefault
                title={'Anexar comprovantes'}
                show={show}
                onClose={handleClose}
                sizeModal={'lg'}
                showFooter={true}
                buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
                showButtonSpinner={isLoadingSubmit}
                disabledSubmit={isLoadingSubmit}
                handleSubmit={handleSubmit(onSubmit)}
                backdrop="static"
                backgroundColor="#f8f9fa"
            >
                <div className="ModalUploadVouchers" data-testid="ModalUploadVouchers">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12} xl={12}>
                                    <FormGroup className='mb-0'>
                                        <label htmlFor="typeVoucher"> Tipo de comprovante </label>
                                        <ReactSelect
                                            name="typeVoucher"
                                            isClearable
                                            isSearchable
                                            options={VoucherOptions}
                                            placeholder="Selecione..."
                                            className={`form-control p-0`}
                                            value={selectedTypeVoucher ?? ''}
                                            defaultValue={selectedTypeVoucher}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            onChange={(val: any) => {
                                                setSelectedTypeVoucher(val);
                                            }}
                                            styles={customStyles}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup className='mb-0'>
                                    <label htmlFor="fileVoucher"> Comprovante </label>
                                    <input
                                        key={writeOffId}
                                        multiple={false}
                                        className={`form-control ${onInvalid(errors?.fileVoucher)}`}
                                        type="file"
                                        {...register(`fileVoucher.${writeOffId}`)}
                                    />
                                </FormGroup>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </ModalDefault>
        </>
    );
};

export default ModalUploadVouchers;