import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { TermsDocumentsProvider } from '../../providers/Terms/TermsDocumentsProvider';
import { TermsTrackingProvider } from '../../providers/Terms/TermsTrackingProvider';
import { CompanyProvider } from '../../providers/Registrations/Company/CompanyProvider';
import { UserDependentProvider } from '../../providers/User/UserDependentProvider';
import { AccessProfileProvider } from '../../providers/Registrations/Access/AccessProfileProvider';
import { TypeProvider } from '../../providers/Registrations/Partner/PartnerTypeProvider';
import { TermsPersonalProvider } from '../../providers/Terms/TermsPersonalProvider';

interface Props {
    element?: any;
    path?: string;
}

export const TermsRoute: FC<Props> = () => {
    return (
        <TermsDocumentsProvider>
            <TermsTrackingProvider>
                <TermsPersonalProvider>
                    <CompanyProvider>
                        <UserDependentProvider>
                            <AccessProfileProvider>
                                <TypeProvider>
                                    <Outlet />
                                </TypeProvider>
                            </AccessProfileProvider>
                        </UserDependentProvider>
                    </CompanyProvider>
                </TermsPersonalProvider>
            </TermsTrackingProvider>
        </TermsDocumentsProvider>
    );
}
