import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { TermsTrackingService } from "../../services/Terms/TermsTrackingService";
import { ToastSettings } from "../../utils/ToastSettings";
import { IntegrationsService } from "../../services/Integrations/IntegrationsService";


interface ContextProps {
    isLoadingFile: boolean,
    termsTracking: any,
    setTermsTracking: Dispatch<SetStateAction<any>>,
    termsTrackings: any[],
    setTermsTrackingHistory: Dispatch<SetStateAction<any>>,
    termsTrackingHistory: any[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    pagesSeeHistory: PagesPaginateModel,
    setPagesSeeHistory: Dispatch<SetStateAction<PagesPaginateModel>>,
    setTermsSignatures: Dispatch<SetStateAction<any>>,
    termsSignatures: any,
    isLoading: boolean,
    isLoadingSeeHistory: boolean,
    error: string,
    status: any[],
    departments: any[],
    setStatus: Dispatch<SetStateAction<any>>,
    setDepartments: Dispatch<SetStateAction<any>>,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleResendTerm: (data: any) => Promise<boolean>,
    handleCancelTerm: (data: any) => Promise<boolean>,
    listStatus: () => Promise<boolean>,
    listDepartments: () => Promise<boolean>,
    handleExcel: () => void,
    handleSeeHistory: (data: any) => Promise<boolean>,
    handleTermSignature: (termId: number) => Promise<boolean>,
}

export const TermsTrackingContext = createContext<ContextProps>({} as ContextProps);

interface TermsTrackingProviderProps {
    children: ReactNode;
}

export const TermsTrackingProvider = ({ children }: TermsTrackingProviderProps) => {
    const [termsTracking, setTermsTracking] = useState<any>({} as any);
    const [termsTrackings, setTermsTrackings] = useState<any[]>([]);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [pagesSeeHistory, setPagesSeeHistory] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingSeeHistory, setIsLoadingSeeHistory] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [status, setStatus] = useState<any[]>([]);
    const [departments, setDepartments] = useState<any[]>([]);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [termsTrackingHistory, setTermsTrackingHistory] = useState<any[]>([]);
    const [termsSignatures, setTermsSignatures] = useState<any[]>([]);
    
    const service = new TermsTrackingService();
    const integrationService = new IntegrationsService()

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);

            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setTermsTrackings(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os acompanhamento de termos do sistema.');
        }
    }, [params]);

    const handleSeeHistory = async (data:any) => {
        try {
            setIsLoadingSeeHistory(true);
            const [_Response, _Error] = await service.listSeeHistory(data);
            setIsLoadingSeeHistory(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setTermsTrackingHistory(_Response.data);
            setPagesSeeHistory(_Response?.pages);
             return true;
        } catch (e) {
            setTermsTrackingHistory([]);
            setIsLoadingSeeHistory(false);
            setError('Não foi possível carregar os acompanhamento de termos do sistema.');
            return false;
        }
    };

    const handleResendTerm = async (data: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await integrationService.resendTerm(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao reenviar o termo');
            return false;
        }
    }

    const handleCancelTerm = async (data:any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await integrationService.cancelTerm(data);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao cancelar o termo');
            return false;
        }
    }

    const listStatus = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listStatus();
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            setStatus(_Response?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            }) ?? [])
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao cancelar o termo');
            return false;
        }
    }

    const listDepartments = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listDepartments();
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            setDepartments(_Response?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.name
                }
            }) ?? [])
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao cancelar o termo');
            return false;
        }
    }

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error ?? 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    }

    const handleTermSignature = async (termId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listSignature(termId);
            setIsLoading(false);

            if (!!_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setTermsSignatures(_Response.data);
            return true;
        } catch (e) {
            setTermsSignatures([]);
            setIsLoading(false);
            setError('Não foi possível carregar os acompanhamento de termos do sistema.');
            return false;
        }
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <TermsTrackingContext.Provider value={{
            isLoadingFile,
            termsTracking,
            setTermsTracking,
            termsTrackings,
            setTermsTrackingHistory,
            termsTrackingHistory,
            termsSignatures,
            setTermsSignatures,
            params,
            setParams,
            pages,
            setPages,
            pagesSeeHistory,
            setPagesSeeHistory,
            isLoading,
            isLoadingSeeHistory,
            error,
            setError,
            status,
            setStatus,
            listStatus,
            departments,
            setDepartments,
            listDepartments,
            handleList,
            handleResendTerm,
            handleCancelTerm,
            handleExcel,
            handleSeeHistory,
            handleTermSignature
        }}>
            {children}
        </TermsTrackingContext.Provider>
    );
}

export const useTermsTracking = () => useContext(TermsTrackingContext);