import React, { FC, useEffect, useState } from "react";
import "./ProductionByContractForm.scss";
import { Card, Col, Row } from "react-bootstrap";
import NavPills from "../../../../NavPills/NavPills";
import ContractFormAnnualProduction from "./ContractFormAnnualProduction/ContractFormAnnualProduction";
import PageBase from "../../../../PageBase/PageBase";
import ChartsContractFormParticipation from "./ChartsContractFormParticipation/ChartsContractFormParticipation";
import ContractFormMonthlyProduction from "./ContractFormMonthlyProduction/ContractFormMonthlyProduction";

interface ProductionByContractFormProps { }

export enum TabBiContractFormEnum {
  MONTHLY_PRODUCTION = "monthlyProduction",
  ANNUAL_PRODUCTION = "annualProduction",
  CHART_PARTICIPATION = "chartParticipation",
};

const ProductionByContractForm: FC<ProductionByContractFormProps> = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState<string>(
    TabBiContractFormEnum.MONTHLY_PRODUCTION
  );

  const renderContent = (): JSX.Element => {
    return (
      <>
        <Row>
          <Col md={12}>
            <NavPills
              mdMenu={12}
              options={[
                {
                  acronym: TabBiContractFormEnum.MONTHLY_PRODUCTION,
                  name: "Produção Mensal",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabBiContractFormEnum.ANNUAL_PRODUCTION,
                  name: "Produção Anual",
                  icon: <i className="fa-solid fa-table"></i>,
                  show: true,
                },
                {
                  acronym: TabBiContractFormEnum.CHART_PARTICIPATION,
                  name: "Gráfico de Participação",
                  icon: <i className="fa-solid fa-chart-pie"></i>,
                  show: true,
                },
              ]}
              current={currentActiveTab}
              setCurrent={setCurrentActiveTab}
            />
          </Col>
        </Row>
        {currentActiveTab === TabBiContractFormEnum.MONTHLY_PRODUCTION && (
          <ContractFormMonthlyProduction activeTab={currentActiveTab} />
        )}
        {currentActiveTab === TabBiContractFormEnum.ANNUAL_PRODUCTION && (
          <ContractFormAnnualProduction activeTab={currentActiveTab} />
        )}
        {currentActiveTab === TabBiContractFormEnum.CHART_PARTICIPATION && (
          <ChartsContractFormParticipation />
        )}
      </>
    );
  };

  return (
    <div
      className="ProductionByContractForm"
      data-testid="ProductionByContractForm"
    >
      <PageBase content={renderContent()} showCardHeader={false} />
    </div>
  );
};

export default ProductionByContractForm;
