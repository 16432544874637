import { toast, ToastPosition, TypeOptions } from 'react-toastify';

const makeUl = (title: string | JSX.Element, items?: any) => {
    return (
        <>
            <p>{title}</p>
            
            <ul>
                {items?.map((item: string, k: number) => (<li key={k}> {item} </li>))}
            </ul>
        </>
    );
}

export const ToastSettings = (message: string | JSX.Element, position: ToastPosition = 'bottom-center', type: TypeOptions = 'warning', onClose?: () => void, items?: any) => {
    (items) && (message = makeUl(message, items));
    toast(message, {
        position: position,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type,
        theme: 'dark',
        onClose: () => onClose,
    });
}