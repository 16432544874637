import * as yup from "yup";

export const schema = yup.object().shape({
    connectTypeId: yup
        .object()
        .required(),
    score: yup
        .number()
        .min(1)
        .required(),
    startDate: yup
        .string()
        .required(),
    endDate: yup
        .string()
        .required(),
    hasRule: yup
        .boolean()
        .required(),
    proportional: yup
        .boolean(),
    wasGlobal: yup
        .boolean()
        .required(),
    description: yup
        .string()
        .when('connectTypeId', {
            is: (item: any) => item?.filter,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required()
        })
        .required(),
    percent: yup
        .number()
        .when('wasGlobal', {
            is: (item: any) => item,
            then: (schema) => schema.min(1).max(100).required(),
            otherwise: (schema) => schema.notRequired()
        }),
    value: yup
        .mixed()
        .when(['hasRule', 'wasGlobal'], {
            is: (hasRule: boolean, wasGlobal: boolean) => !hasRule && !wasGlobal,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired()
        }),
    observation: yup
        .string()
        .notRequired(),
    accessProfileId: yup
        .object()
        .required()
});