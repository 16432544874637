import { FC, useEffect, useState } from 'react';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import './ModalMonthlyGoalInsert.scss';
import { Card, Col, Dropdown, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useMonthlyGoal } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validate';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../providers/AuthProvider';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MonthlyGoalGroup, MonthlyGoalGroupTagCondition, MonthlyGoalModel, MonthlyGoalOperationCondition, MonthlyGoalScore, MonthlyGoalScoreCondition } from '../../../../models/PanelConnects/MonthlyGoalModel';
import ModalMonthlyGoalInsertGroup from '../ModalMonthlyGoalInsertGroup/ModalMonthlyGoalInsertGroup';
import { MonthlyGoalGroupEnum } from '../../../../enums/PanelConnects/MonthlyGoalGroupEnum';
import DropdownMenuTable from '../../../DropdownMenuTable/DropdownMenuTable';
import Swal from 'sweetalert2';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import ModalMonthlyGoalInsertRule from '../ModalMonthlyGoalInsertRule/ModalMonthlyGoalInsertRule';
import TooltipItem from '../../../TooltipItem/TooltipItem';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface ModalMonthlyGoalInsertProps {
  show: boolean;
  onClose: () => void;
}

const ModalMonthlyGoalInsert: FC<ModalMonthlyGoalInsertProps> = ({ show, onClose }) => {
  const {
    typesOptions,
    monthlyGoal,
    isLoading,
    handleSave,
    setMonthlyGoal,
  } = useMonthlyGoal();
  const { calculableScoreTagsOptions, observableScoreTagsOptions } = useMonthlyGoalCondition();
  const { onInvalid } = useAuth();

  const [selectedPeriod, setSelectedPeriod] = useState<any>();

  const [groupsAdd, setGroupsAdd] = useState<MonthlyGoalGroup[]>([]);
  const [showGroupsAdd, setShowGroupsAdd] = useState<boolean>(false);
  const [indexGroupEdit, setIndexGroupEdit] = useState<number>();

  const [scoreRules, setScoreRules] = useState<MonthlyGoalScore[]>([]);
  const [showScoreRules, setShowScoreRules] = useState<boolean>(false);
  const [indexScoreRuleEdit, setIndexScoreRuleEdit] = useState<number>();

  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [selectedType, setSelectedType] = useState<any>(null);
  const [needRules, setNeedRules] = useState<boolean>(false);
  const [wasGlobal, setWasGlobal] = useState<boolean>(false);
  const [proportional, setProportional] = useState<boolean>(false);
  const [observation, setObservation] = useState<string>();
  const [selectedAccessProfile, setSelectedAccessProfile] = useState<any>();

  const [periodOptions, setPeriodOptions] = useState<any[]>([]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    control
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    if (periodOptions.length === 0) {
      getPeriodOptions();
    }
  }, []);

  useEffect(() => {
    if (!showGroupsAdd) {
      setIndexGroupEdit(undefined);
    }
  }, [showGroupsAdd]);

  useEffect(() => {
    if (!showScoreRules) {
      setIndexScoreRuleEdit(undefined);
    }
  }, [showScoreRules]);

  useEffect(() => {
    if (show) {
      if (monthlyGoal) {
        setSelectedType(typesOptions?.find((x: any) => x.value === monthlyGoal?.connectType?.id));
        setSelectedAccessProfile(monthlyGoalAccessProfileOptions?.find((x: any) => x.value === monthlyGoal?.accessProfileId));
        setValue('value', monthlyGoal.value);
        setObservation(monthlyGoal.observation?.replace(/\n/g, '<br>'));
        setValue('description', monthlyGoal.description ?? '');

        const _groups = monthlyGoal?.groups?.map((_group: MonthlyGoalGroup) => ({
          ..._group,
          tags: _group?.tags?.map((_tag: MonthlyGoalGroupTagCondition) => ({
            ..._tag,
            connectGoalConditionId: _tag?.connectGoalCondition?.id,
            connectGoalTagId: _tag?.connectGoalTag?.id
          }))
        }));
        setGroupsAdd(_groups ?? []);
        setSelectedPeriod(
          periodOptions?.find((x: any) =>
            x.value === moment(monthlyGoal?.startDate).format('YYYY-MM'))
        );

        if (monthlyGoal.score) {
          setValue('score', monthlyGoal.score);
        }

        if (monthlyGoal?.scores && monthlyGoal?.scores?.length > 0) {
          setNeedRules(true);
          const _scores = monthlyGoal?.scores?.map((_score: MonthlyGoalScore) => ({
            ..._score,
            conditions: _score?.conditions?.map((_condition: MonthlyGoalScoreCondition) => ({
              ..._condition,
              connectGoalConditionId: _condition?.connectGoalCondition?.id,
              scoreTagId: _condition?.scoreTag?.id,
              valueTagObj: _condition?.valueTag,
              valueTag: _condition?.valueTag?.id,
              operations: _condition?.operations?.map((_operation: MonthlyGoalOperationCondition) => ({
                ..._operation,
                operationId: _operation?.operation?.id,
                scoreTagId: _operation?.scoreTag?.id
              }))
            }))
          }));
          setScoreRules(_scores);
        }

        setWasGlobal(!!monthlyGoal?.wasGlobal);
        setProportional(!!monthlyGoal?.proportional);
        setValue('percent', monthlyGoal?.percent);
      }
    } else {
      handleClose();
    }
  }, [show, monthlyGoal]);

  useEffect(() => {
    setValue('startDate', startDate);
  }, [startDate]);

  useEffect(() => {
    setValue('endDate', endDate);
  }, [endDate]);

  useEffect(() => {
    setValue('observation', observation ?? '');
  }, [observation]);

  useEffect(() => {
    setValue('hasRule', needRules);
    if (needRules) {
      setWasGlobal(false);
    } else {
      setScoreRules([]);
    }
  }, [needRules]);

  useEffect(() => {
    setValue('proportional', proportional);
  }, [proportional]);

  useEffect(() => {
    setValue('wasGlobal', wasGlobal);
    if (wasGlobal) {
      setValue('value', undefined);
      setNeedRules(false);
    } else {
      setValue('percent', undefined);
    }
  }, [wasGlobal]);

  useEffect(() => {
    setValue('connectTypeId', selectedType);
    if (!selectedType?.filter) {
      setGroupsAdd([]);
      if (!monthlyGoal?.description)
        setValue('description', selectedType?.description ?? '');
    } else {
      setValue('description', '');
    }
  }, [selectedType]);

  useEffect(() => {
    setValue('accessProfileId', selectedAccessProfile);
  }, [selectedAccessProfile]);

  useEffect(() => {
    if (selectedPeriod) {
      setStartDate(moment(selectedPeriod?.value).startOf('month').format('YYYY-MM-DD'));
      setEndDate(moment(selectedPeriod?.value).endOf('month').format('YYYY-MM-DD'));
    } else {
      setStartDate('');
      setEndDate('');
    }
  }, [selectedPeriod]);

  const handleClose = async () => {
    setMonthlyGoal(undefined);
    setSelectedType(null);
    setSelectedAccessProfile(null);
    setSelectedPeriod(periodOptions[0]);
    setWasGlobal(false);
    setNeedRules(false);
    setProportional(false);
    setGroupsAdd([]);
    setScoreRules([]);
    setValue('description', '');
    setValue('score', 0);
    setValue('value', undefined);
    setValue('percent', undefined);
    setObservation('');
    clearErrors();
    onClose();
  }

  const getPeriodOptions = () => {
    Array.from(Array(6)).forEach((x, i) => {
      periodOptions?.push({
        label: moment(new Date()).add(i - 1, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).add(i - 1, 'M').format('YYYY-MM')
      });
    });
    setSelectedPeriod(periodOptions[0]);
  }

  const handleDropdownShow = (e: any) => {
    const tagifyInstance = e.detail.tagify;
    const dropdownContentEl = tagifyInstance.DOM.dropdown.content;

    const existingFooter = dropdownContentEl.querySelector('.tagify__dropdown__footer');
    if (existingFooter) {
      existingFooter.remove();
    }

    const originalMessage = dropdownContentEl.querySelector('.tagify__dropdown__noMoreItems');
    if (originalMessage) {
      originalMessage.style.display = 'none';
    }

    const remainingTags = tagifyInstance.whitelist.length - dropdownContentEl.children.length;

    if (remainingTags > 0) {
      const customMessage = document.createElement('div');
      customMessage.className = 'tagify__dropdown__footer';
      customMessage.textContent = `Refine a busca. Ainda existem ${remainingTags} tags.`;
      dropdownContentEl.appendChild(customMessage);
    }
  };

  const removeGroup = (group: MonthlyGoalGroup, index: number) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja remover o grupo ${group.name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        setGroupsAdd(() => groupsAdd?.filter((x, i) => i !== index));
        toast.success('Grupo removido com sucesso!');
      }
    });
  }

  const renderGroups = () => {
    return (
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr className='text-sm'>
              <th>Nome</th>
              <th>Tags</th>
              <th className='text-center'>#</th>
            </tr>
          </thead>
          <tbody>
            {groupsAdd?.map((group: MonthlyGoalGroup, key: number) => (
              <tr className='text-sm'>
                <td>{group.name}</td>
                <td>
                  <span className='d-flex gap-2'>
                    {group?.tags?.map((x) => (
                      <span className='badge bg-info'>
                        {x.connectGoalTagId === MonthlyGoalGroupEnum.FINANCIAL ? 'FINANCEIRA' : 'GRUPO DE CONVÊNIO'}
                      </span>
                    ))}
                  </span>
                </td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => {
                      setIndexGroupEdit(key);
                      setShowGroupsAdd(true);
                    }}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => removeGroup(group, key)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const removeRule = (index: number) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja remover a regra?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        setScoreRules(() => scoreRules?.filter((x, i) => i !== index));
        toast.success('Regra removida com sucesso!');
      }
    });
  }

  const renderRules = () => {
    return (
      <div className='table-responsive'>
        <table className='table table-striped'>
          <thead>
            <tr className='text-sm'>
              <th>Regra</th>
              <th>
                <div className='d-flex gap-4'>
                  <div className="d-flex align-items-center gap-1">
                    <div className="tag-th"></div>
                    <span>Tags</span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <div className="operation-th"></div>
                    <span>Tags de Operações</span>
                  </div>
                </div>
              </th>
              <th className='text-center'>#</th>
            </tr>
          </thead>
          <tbody>
            {scoreRules?.map((rule: MonthlyGoalScore, key: number) => (
              <tr className='text-sm'>
                <td>Regra {key + 1}</td>
                <td>
                  <span className='text-wrap'>
                    {rule?.conditions?.map((x) => (
                      <>
                        {(x.scoreTag || x.scoreTagId) ?
                          <span className='badge bg-info me-1 mb-1'>
                            {calculableScoreTagsOptions?.find((tag: any) => tag.value === (x.scoreTagId ?? x.scoreTag?.id))?.label}
                          </span>
                          :
                          <>
                            {x.operations?.map((operation: MonthlyGoalOperationCondition) => (
                              <span className='badge bg-secondary me-1 mb-1'>
                                {calculableScoreTagsOptions?.find((tag: any) => tag.value === operation?.scoreTagId)?.label}
                              </span>
                            ))}
                          </>}
                      </>
                    ))}
                  </span>
                </td>
                <td>
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => {
                      setIndexScoreRuleEdit(key);
                      setShowScoreRules(true);
                    }}>
                      <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                    </Dropdown.Item>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => removeRule(key)}>
                      <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const onSubmit = async (data: any) => {

    if (needRules && scoreRules?.length === 0) {
      toast.warning('É preciso configurar ao menos uma Regra para a meta que necessita de regra!');
      return;
    }

    if (selectedType?.filter && groupsAdd?.length === 0) {
      toast.warning('É preciso configurar ao menos um Grupo para esse Tipo!');
      return;
    }

    const payload: MonthlyGoalModel = {};
    payload.id = monthlyGoal?.id;
    payload.startDate = data?.startDate;
    payload.endDate = data?.endDate;
    payload.value = data?.value;
    payload.description = data?.description;
    payload.score = data?.score;
    payload.percent = data?.percent;
    payload.wasGlobal = data?.wasGlobal;
    payload.proportional = data?.proportional;
    payload.connectTypeId = data?.connectTypeId?.value;
    payload.accessProfileId = data?.accessProfileId?.value;
    payload.observation = data?.observation?.replace(/\n/g, '<br>');
    payload.groups = groupsAdd;
    payload.scores = scoreRules;

    const response = await handleSave(payload).then();
    if (response) {
      toast.success('Meta salva com sucesso!');
      setMonthlyGoal(undefined);
      onClose();
    }
  }

  return (
    <div>
      <ModalDefault
        title={monthlyGoal?.id ? 'Editar Meta' : 'Adicionar Meta'}
        show={show}
        onClose={handleClose}
        sizeModal={'xxl'}
        showFooter={true}
        buttonText={isLoading ? 'Salvando...' : 'Salvar'}
        showButtonSpinner={isLoading}
        disabledSubmit={isLoading}
        handleSubmit={handleSubmit(onSubmit)}
        backdrop="static"
        backgroundColor="#f8f9fa"
        disableEsc={true}
      >
        <div className="ModalMonthlyGoalInsert" data-testid="ModalMonthlyGoalInsert">
          <Row>
            <Col md={6} sm={12}>
              <Card>
                <Card.Header className="pb-1">
                  <p className="mb-0"><b>Configuração</b></p>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-group">
                        <label htmlFor='period'>PERÍODO *</label>
                        <ReactSelect
                          name='period'
                          value={selectedPeriod}
                          isSearchable
                          isClearable={false}
                          options={periodOptions}
                          placeholder="Selecione..."
                          className={`form-control p-0`}
                          noOptionsMessage={() => 'Não há registros'}
                          onChange={(val: any) => {
                            setSelectedPeriod(val);
                          }}
                          styles={customStyles}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-group">
                        <label>TIPO COMERCIAL *</label>
                        <ReactSelect
                          name='accessProfileId'
                          value={selectedAccessProfile}
                          isClearable={false}
                          options={monthlyGoalAccessProfileOptions}
                          placeholder="Selecione..."
                          className={`form-control p-0 ${onInvalid(errors?.accessProfileId)}`}
                          noOptionsMessage={() => 'Não há registros'}
                          onChange={(val: any) => {
                            setSelectedAccessProfile(val);
                          }}
                          styles={customStyles}
                        />
                        <ErrorMessage name={'Tipo Comercial'} type={errors?.accessProfileId?.type?.toString()} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-group">
                        <label htmlFor="connectTypeId">TIPO *</label>
                        <Controller
                          control={control}
                          name={'connectTypeId'}
                          render={({ field: { onChange, name, ref } }) => (
                            <ReactSelect
                              ref={ref}
                              name={name}
                              isClearable
                              isSearchable
                              placeholder="Selecione..."
                              options={typesOptions}
                              className={`form-control p-0 ${onInvalid(errors?.connectTypeId)}`}
                              noOptionsMessage={() => 'Não há registros'}
                              value={selectedType || ''}
                              defaultValue={selectedType}
                              onChange={(val: any) => {
                                setSelectedType(val);
                              }}
                              styles={customStyles}
                            />
                          )}
                        />
                        <ErrorMessage name={'Tipo'} type={errors?.connectTypeId?.type?.toString()} />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className='form-group'>
                        <label>DESCRIÇÃO {!selectedType?.filter && '*'}</label>
                        <input
                          type='text'
                          className={`form-control ${onInvalid(errors?.description)}`}
                          disabled={selectedType?.filter}
                          {...register('description')}
                        />
                        <ErrorMessage name={'Descrição'} type={errors?.description?.type?.toString()} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label htmlFor="wasGlobal">
                        <div className="d-flex align-items-center text-wrap gap-1">
                          <span>SE BASEAR NA META GLOBAL? </span>

                          <PopoverHelp
                            placement="bottom"
                            header={<>CONSIDERAR META GLOBAL</>}
                            body={
                              <>
                                Se o campo estiver marcado como SIM, então o valor considerado para essa meta
                                será uma porcentagem (que será informada no campo logo abaixo) do valor global. <br />
                                Nesse caso, não será informado um valor fixo para essa meta e não será possível configurar regras,
                                pois o cálculo será baseado no valor global.
                              </>
                            }
                          >
                            <button type="button" id="btn-help"
                              className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                              <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                            </button>
                          </PopoverHelp>
                        </div>
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="wasGlobal"
                          id="wasGlobal1"
                          checked={wasGlobal}
                          onClick={() => setWasGlobal(true)}
                        />
                        <label className="form-check-label" htmlFor="wasGlobal1">
                          Sim
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="wasGlobal"
                          id="wasGlobal2"
                          checked={!wasGlobal}
                          onChange={() => setWasGlobal(false)}
                        />
                        <label className="form-check-label" htmlFor="wasGlobal2">
                          Não
                        </label>
                      </div>
                    </Col>
                    <Col md={4}>
                      <label htmlFor="needRule">
                        <div className="d-flex align-items-center text-wrap gap-1">
                          <span>NECESSITA CONFIGURAR REGRAS? </span>

                          <PopoverHelp
                            placement="bottom"
                            header={<>Configuração de Regras</>}
                            body={
                              <>
                                A regras são utilizadas para situações mais complexas, onde é necessário parametrizar
                                valores e campos para determinar se a meta foi atingida ou não. Se a meta é apenas para verificar
                                se o colaborador tem ou não algo, basta informar "1" no campo Valor.
                              </>
                            }
                          >
                            <button type="button" id="btn-help"
                              className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                              <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                            </button>
                          </PopoverHelp>
                        </div>
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="needRule"
                          id="needRule1"
                          checked={needRules}
                          onClick={() => setNeedRules(true)}
                        />
                        <label className="form-check-label" htmlFor="needRule1">
                          Sim
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="needRule"
                          id="needRule2"
                          checked={!needRules}
                          onChange={() => setNeedRules(false)}
                        />
                        <label className="form-check-label" htmlFor="needRule2">
                          Não
                        </label>
                      </div>
                    </Col>
                    {!needRules &&
                      <Col md={4}>
                        <label htmlFor="proportional">
                          <div className="d-flex align-items-center text-wrap gap-1">
                            <span>CONSIDERA PROPORCIONAL? </span>

                            <PopoverHelp
                              placement="bottom"
                              header={<>PONTUAÇÃO PROPORCIONAL</>}
                              body={
                                <>
                                  Pontuar proporcionalmente caso a meta não seja atingida.
                                </>
                              }
                            >
                              <button type="button" id="btn-help"
                                className="btn btn-sm text-violet-60 text-sm m-0 px-2">
                                <BvIcon name='help' className='text-violet-60' width={16} height={16} />
                              </button>
                            </PopoverHelp>
                          </div>
                        </label>
                        <br />
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="proportional"
                            id="proportional1"
                            checked={proportional}
                            onClick={() => setProportional(true)}
                          />
                          <label className="form-check-label" htmlFor="proportional1">
                            Sim
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="proportional"
                            id="proportional2"
                            checked={!proportional}
                            onChange={() => setProportional(false)}
                          />
                          <label className="form-check-label" htmlFor="proportional2">
                            Não
                          </label>
                        </div>
                      </Col>}
                  </Row>
                  <Row className='mt-2'>
                    <Col md={6}>
                      <FormGroup className="form-group">
                        <label>PONTUAÇÃO *</label>
                        <input
                          type='number'
                          className={`form-control ${onInvalid(errors?.score)}`}
                          defaultValue={''}
                          {...register('score')}
                        />
                        <ErrorMessage
                          name={'Pontuação'}
                          min={1}
                          isNumber
                          type={errors?.score?.type?.toString()} />
                      </FormGroup>
                    </Col>

                    {!wasGlobal && (
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <label>VALOR {!needRules && '*'}</label>
                          <input
                            type='number'
                            {...register('value')}
                            className={`form-control ${onInvalid(errors?.value)}`}
                            defaultValue={''}
                          />
                          <ErrorMessage name={'Valor da Meta'} type={errors?.value?.type?.toString()} />
                        </FormGroup>
                      </Col>
                    )}

                    {wasGlobal && (
                      <Col md={6}>
                        <FormGroup className="form-group">
                          <label>PORCENTAGEM DA META GLOBAL *</label>
                          <span className='d-flex align-items-center'>
                            <input
                              type='number'
                              {...register('percent')}
                              className={`form-control ${onInvalid(errors?.percent)}`}
                              defaultValue={''}
                            />%
                          </span>
                          <ErrorMessage
                            name={'Porcentagem da Meta'}
                            min={1}
                            max={100}
                            isNumber
                            type={errors?.percent?.type?.toString()} />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {selectedType?.filter &&
                      <Col md={12}>
                        <Card className='shadow'>
                          <Card.Header className="p-3 d-flex justify-content-between">
                            <p className="mb-0"><b>Grupos</b></p>
                            {groupsAdd?.length === 0 &&
                              <TooltipItem position='top' text='Adicionar Grupo'>
                                <FontAwesomeIcon
                                  className='text-primary text-2xl cursor-pointer'
                                  icon={faCirclePlus}
                                  onClick={() => setShowGroupsAdd(true)}
                                />
                              </TooltipItem>}
                          </Card.Header>
                          <Card.Body className='p-3'>
                            <Row>
                              <Col md={12}>
                                {groupsAdd?.length > 0 ?
                                  renderGroups()
                                  :
                                  <div className='text-center text-sm text-bolder'>Não existem Grupos criados.</div>
                                }
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    }
                  </Row>


                  <Row className='mt-2'>
                    <Col md={12}>
                      <FormGroup className='form-group'>
                        <label>OBSERVAÇÕES</label>
                        <Tags
                          onDropdownShow={handleDropdownShow}
                          defaultValue={observation}
                          InputMode="textarea"
                          settings={{
                            placeholder:
                              "Digite o texto e tags (iniciando com @)...",
                            mode: "mix",
                            pattern: /@/,
                            tagTextProp: 'name',
                            dropdown: {
                              enabled: 0,
                              mapValueTo: 'name',
                              searchKeys: ['name'],
                              maxItems: observableScoreTagsOptions?.length
                            },
                            enforceWhitelist: true,
                            whitelist: observableScoreTagsOptions?.map((x) => ({ value: x.value, name: x.label })),
                          }}
                          onChange={(val: any) => {
                            setValue("observation", val.detail.value);
                          }}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={12}>
              <Card className={`h-100 ${!needRules && 'rules-disable'}`}>
                <Card.Header className="pb-1 d-flex justify-content-between">
                  <p className="mb-0"><b>Regras</b></p>
                  <TooltipItem position='top' text='Adicionar Regra'>
                    <FontAwesomeIcon
                      className='text-primary text-2xl cursor-pointer'
                      icon={faCirclePlus}
                      onClick={() => setShowScoreRules(true)}
                    />
                  </TooltipItem>
                </Card.Header>
                <Card.Body>
                  {scoreRules?.length > 0 ?
                    renderRules()
                    :
                    <div className='text-center text-sm text-bolder'>Não existem Regras criadas.</div>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </ModalDefault>

      <ModalMonthlyGoalInsertGroup
        groups={groupsAdd}
        setGroups={setGroupsAdd}
        show={showGroupsAdd}
        onClose={setShowGroupsAdd}
        indexGroupEdit={indexGroupEdit}
      />

      <ModalMonthlyGoalInsertRule
        rules={scoreRules}
        setRules={setScoreRules}
        show={showScoreRules}
        onClose={setShowScoreRules}
        indexRuleEdit={indexScoreRuleEdit}
      />
    </div>
  );


}

export default ModalMonthlyGoalInsert;
