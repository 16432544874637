import React, { FC, useState } from 'react';
import ImageGallery from "react-image-gallery";
import './Gallery.scss';

interface GalleryProps {
  images: any[];
  showPlayButton?: boolean;
  thumbnailPosition?: "left" | "right" | "top" | "bottom";
}

const Gallery: FC<GalleryProps> = ({ images, thumbnailPosition = "bottom", showPlayButton = false }) => {
  const [showBullets, setShowBullets] = useState<boolean>(false);

  return (
    <div className="Gallery" data-testid="Gallery">
      <ImageGallery
        items={images}
        thumbnailPosition={thumbnailPosition}
        showPlayButton={showPlayButton}
        showBullets={showBullets}
        onScreenChange={() => setShowBullets((current: boolean) => !current)}
      />
    </div>
  );
}

export default Gallery;
