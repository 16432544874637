import React, { FC, useEffect, useState } from 'react';
import './SidebarMonitoringCommercialPointsFilter.scss';
import { Col, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useCommercial } from '../../../../providers/Registrations/User/UserCommercialProvider';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import moment from 'moment';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import { MonitoringCommercialPointsParamsModel } from '../../../../models/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsParamsModel';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';
import { useAuth } from '../../../../providers/AuthProvider';
import { toast } from 'react-toastify';

interface SidebarMonitoringCommercialPointsFilterProps { }

const SidebarMonitoringCommercialPointsFilter: FC<SidebarMonitoringCommercialPointsFilterProps> = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedCommercial, setSelectedCommercial] = useState<any>();
  const [selectedRegional, setSelectedRegional] = useState<any>();
  const [selectedExecutive, setSelectedExecutive] = useState<any>();
  const [selectedAccessProfileId, setSelectedAccessProfileId] = useState<any>();
  const [periodOptions, setPeriodOptions] = useState<any[]>([]);
  const [accessProfileOptions, setAccessProfileOptions] = useState<any[]>([]);

  const { CanAccess } = useAuth();

  const {
    handleListPoints,
    points,
    setParams
  } = useMonitoringCommercialPoints();

  const {
    commercialsOptions,
    regionalsOptions,
    executivesOptions,
    handleList,
    handleListRegional,
    handleListExecutive } = useCommercial();
  const animatedComponents = makeAnimated();

  useEffect(() => {
    handleList().then();
    handleListRegional({});
    handleListExecutive();
    if (periodOptions.length === 0) {
      getPeriodOptions();
    }
    firstSubmit().then();
  }, []);

  const getPeriodOptions = () => {
    let count = 0;
    let maxItems = 15;
    let canContinue = true;
    while (canContinue && count < maxItems) {
      periodOptions?.push({
        label: moment(new Date()).subtract(count, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).subtract(count, 'M').format('MM-YYYY')
      });
      if (moment(new Date()).subtract(count, 'M').format('MMMM/YY')?.toUpperCase() === 'JULHO/24') {
        canContinue = false;
      }
      count++;
    }
    setSelectedPeriod(periodOptions[0]);
  }

  const noAccessProfileMessage = () => toast.warning('Usuário sem permissão para nenhum tipo de comercial!');

  const firstSubmit = async () => {
    const canItems: any[] = [];

    await Promise.all(
      monthlyGoalAccessProfileOptions?.map(async (item) => {
        const can = await CanAccess(item.rule).then();
        if (can) {
          canItems.push(item);
        }
      })
    );

    if (canItems?.length > 0) {
      setAccessProfileOptions(canItems);
      setSelectedAccessProfileId(canItems[0]);
      setParams({
        closingDate: moment(periodOptions[0]?.value, 'MM-YYYY').format('YYYY-MM-DD'),
        accessProfileId: canItems[0]?.value
      });
      if (!points) {
        handleListPoints({
          closingDate: moment(periodOptions[0]?.value, 'MM-YYYY').format('YYYY-MM-DD'),
          accessProfileId: canItems[0]?.value
        });
      }
    } else {
      noAccessProfileMessage();
    }
  }

  const onSubmit = async () => {
    if (accessProfileOptions?.length > 0) {
      const data: MonitoringCommercialPointsParamsModel = {};
      data.closingDate = moment(selectedPeriod?.value, 'MM-YYYY').format('YYYY-MM-DD');
      data.commercial = selectedCommercial?.value;
      data.regional = selectedRegional?.value;
      data.executive = selectedExecutive?.value;
      data.accessProfileId = selectedAccessProfileId?.value;

      setParams(data);

      handleListPoints(data).then();
    } else {
      noAccessProfileMessage();
    }
  }

  return (
    <SidebarFilter handleSubmit={onSubmit} >
      <div className="SidebarMonitoringCommercialPointsFilter" data-testid="SidebarMonitoringCommercialPointsFilter">
        <Row>
          <Col sm={12}>
            <div className="form-group">
              <label htmlFor="period">PERÍODO</label>
              <ReactSelect
                name='period'
                value={selectedPeriod}
                isSearchable
                isClearable={false}
                options={periodOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedPeriod(val);
                }}
                styles={customStyles}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label htmlFor="commercialId">COMERCIAL</label>
              <ReactSelect
                name='commercialId'
                value={selectedCommercial}
                isSearchable
                isClearable
                options={commercialsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedCommercial(val);
                }}
                styles={customStyles}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label htmlFor="regionalId">REGIONAL</label>
              <ReactSelect
                name='regionalId'
                value={selectedRegional}
                isSearchable
                isClearable
                options={regionalsOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedRegional(val);
                }}
                styles={customStyles}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label htmlFor="executiveId">EXECUTIVO</label>
              <ReactSelect
                name='executiveId'
                value={selectedExecutive}
                isSearchable
                isClearable
                options={executivesOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedExecutive(val);
                }}
                styles={customStyles}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group">
              <label>TIPO COMERCIAL</label>
              <ReactSelect
                name='commercialType'
                value={selectedAccessProfileId}
                isClearable={false}
                options={accessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfileId(val);
                }}
                styles={customStyles}
              />
            </div>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  )
};

export default SidebarMonitoringCommercialPointsFilter;
