import React, { FC, useEffect, useMemo, useState } from "react";
import "./TableAnnualFinancialProduction.scss";
import { TabEnumFinancialProduction } from "../FinancialProduction";
import { useBiFinancialProduction } from "../../../../../../providers/BI/Dashboard/BiFinancial/BiFinancialProductionProvider";
import FormatMoney from "../../../../../../utils/FormatMoney";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";

interface TableAnnualFinancialProductionProps {
  currentActiveTab?: TabEnumFinancialProduction;
}

const TableAnnualFinancialProduction: FC<
  TableAnnualFinancialProductionProps
> = ({ currentActiveTab }) => {
  const [colAnnualProduction, setColAnnualProduction] = useState<boolean>(true);
  const [colAnnualProjection, setColAnnualProjection] = useState<boolean>(true);
  const [colParticipation, setColParticipation] = useState<boolean>(true);
  const [colAnnualAverage, setColAnnualAverage] = useState<boolean>(true);
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const { annualFinancialProductions, setAnnualFinancialProductions } =
    useBiFinancialProduction();
  const {
    handleListAnnualProduction,
    params,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight
  } = useBiDashboard();

  useEffect(() => {
    if (currentActiveTab === TabEnumFinancialProduction.ANNUAL_PRODUCTION) {
      setHandleListOnFilter(() => handleListAnnualProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.FINANCIAL,
        group: {},
      });
      if (!annualFinancialProductions) {
        handleFetch().then();
      }
    }
  }, [currentActiveTab]);

  useEffect(() => {
    if (resultOnFilter) {
      setAnnualFinancialProductions(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.FINANCIAL,
      group: {},
    }).then();

    if (result) {
      setAnnualFinancialProductions(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Financeiras: item.name,
      };

      item?.periods?.forEach((period: any) => {
        _row["Producao anual"] = period.totalProduction;
        _row["Producao anual D.U"] = period.totalProductionProjection;
        _row["Participacao"] = period.totalProductionPercentage;
        _row["Media D.U"] = period.totalProductionByWorkdays;
      });
      _data.push(_row);
    });
    handleExcel(_data, "Producao mensal por financeira");
  };

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const mountRows = () => {
    if (annualFinancialProductions?.items && annualFinancialProductions?.items?.length > 0) {
      setRows([
        ...annualFinancialProductions?.items,
        {
          name: "TOTAL",
          periods: [{ ...annualFinancialProductions?.total }]
        }
      ]);
    }
  }

  const mountColumns = useMemo(() => {
    if (
      annualFinancialProductions?.items &&
      annualFinancialProductions?.items?.length > 0
    ) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Financeiras</div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      annualFinancialProductions?.items[0].periods?.forEach(
        (item: any, index: number) => {
          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Produção Anual
              </div>
            ),
            selector: (data: any) => data?.periods[index]?.totalProduction,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProduction)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProduction,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Projeção Anual D.U
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionProjection,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionProjection)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProjection,
            right: true,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                {" "}
                Participação{" "}
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionPercentage,
            cell: (data: any) => (
              <div>
                {FormatMoney(
                  data?.periods[index]?.totalProductionPercentage,
                  false
                )}
                %
              </div>
            ),
            sortable: true,
            right: true,
            omit: !colParticipation,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">Média D.U</div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionByWorkdays,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionByWorkdays)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualAverage,
            right: true,
          });
        }
      );
      mountRows();
      setColumns(_columns);
    }
  }, [
    annualFinancialProductions,
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Row md={12} className="display-button">
        <div className="d-flex gap-1 justify-content-end align-items-center flex-md-row">
          <button
            type="button"
            className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
            onClick={() => setColAnnualProduction((current) => !current)}
          >
            <FontAwesomeIcon
              icon={colAnnualProduction ? faEye : faEyeSlash}
              className="me-1"
            />
            Produção Anual
          </button>

          <button
            type="button"
            className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
            onClick={() => setColAnnualProjection((current) => !current)}
          >
            <FontAwesomeIcon
              icon={colAnnualProjection ? faEye : faEyeSlash}
              className="me-1"
            />
            Projeção Anual D.U
          </button>

          <button
            type="button"
            className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
            onClick={() => setColParticipation((current) => !current)}
          >
            <FontAwesomeIcon
              icon={colParticipation ? faEye : faEyeSlash}
              className="me-1"
            />
            Participação
          </button>

          <button
            type="button"
            className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
            onClick={() => setColAnnualAverage((current) => !current)}
          >
            <FontAwesomeIcon
              icon={colAnnualAverage ? faEye : faEyeSlash}
              className="me-1"
            />
            Média D.U
          </button>
          <button
            className="btn bg-success text-white rounded m-0 ms-2"
            onClick={() => {
              handleExport(annualFinancialProductions?.items);
            }}
          >
            <i className="fas fa-file-excel" /> Exportar
          </button>
        </div>
      </Row>
    );
  }, [
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  return (
    <div
      className="TableAnnualFinancialProduction"
      data-testid="TableAnnualFinancialProduction"
    >
      <>
        {!isLoading ? (
          annualFinancialProductions?.items ? (
            <>

              {subHeaderComponentMemo}

              <AppDataTable
                rows={rows}
                columns={columns}
                pagination={false}
                handleOrder={handleSortValue}
                conditionalRowStyles={[
                  {
                    when: (data: any) => data.name === 'TOTAL',
                    style: biTotalRowStyle
                  }
                ]}
                cellColor={biCellColor}
                cellFontSize={dataFontSize}
                maxHeight={tableHeight}
                display="flex"
              />
            </>
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )
        ) : (
          <SkeletonTable />
        )}
      </>
    </div>
  );
};

export default TableAnnualFinancialProduction;
