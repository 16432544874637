import { FC, useEffect, useState } from 'react';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import SidebarFilter from '../../../components/SidebarFilter/SidebarFilter'
import SidebarMonthlyGoalFilter from '../../../components/PanelConnects/MonthlyGoal/SidebarMonthlyGoalFilter/SidebarMonthlyGoalFilter';
import { useMonthlyGoal } from '../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalProvider';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import { MonthlyGoalModel } from '../../../models/PanelConnects/MonthlyGoalModel';
import { usePaginate } from '../../../providers/PaginateProvider';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ModalMonthlyGoalInsert from '../../../components/PanelConnects/MonthlyGoal/ModalMonthlyGoalInsert/ModalMonthlyGoalInsert';
import AppPagination from '../../../components/AppPagination/AppPagination';
import moment from 'moment';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalMonthlyGoalImport from '../../../components/PanelConnects/MonthlyGoal/ModalMonthlyGoalImport/ModalMonthlyGoalImport';
import { useAuth } from '../../../providers/AuthProvider';
import ModalMonthlyGoalImportPDF from '../../../components/PanelConnects/MonthlyGoal/ModalMonthlyGoalImportPDF/ModalMonthlyGoalImportPDF';
import FormatNumber from '../../../utils/FormatNumber';
import ModalGoalsClone from '../../../components/PanelConnects/MonthlyGoal/ModalGoalsClone/ModalGoalsClone';

interface MonthlyGoalProps { }

const MonthlyGoal: FC<MonthlyGoalProps> = () => {

  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [showModalImport, setShowModalImport] = useState<boolean>(false);
  const [showModalImportPDF, setShowModalImportPDF] = useState<boolean>(false);
  const [showModalButtonPDF, setShowModalButtonPDF] = useState<boolean>(false);
  const [showModalClone, setShowModalClone] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { userLogged, CanAccess } = useAuth();
  const { monthlyGoals, handleList, handleDelete, handleDownloadPdf, setMonthlyGoal, error, isLoading, pages, params, setParams } = useMonthlyGoal();
  const { handlePaginate } = usePaginate();


  useEffect(() => {
    handleList();

    handleImportPDF();
  }, [params]);

  const handleDownloadPDFButton = () => {
    handleDownloadPdf(params?.startDate, params?.accessProfileId);
  }

  const handleImportPDF = async () => {
    let can = await CanAccess('meta_mensal.importar_pdfregra').then();
    setShowModalButtonPDF(can);
  }

  const updateMonthlyGoal = (item: MonthlyGoalModel) => {
    setMonthlyGoal(item);
    setShowModalInsert(true);
  }

  const renderCloneButton = () => {
    return (
      <button type="button" className="btn bg-primary text-white btn-sm mb-0"
        onClick={() => setShowModalClone(true)}>
        Clonar Período
      </button>
    );
  }

  const deleteMonthlyGoal = (item: MonthlyGoalModel) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir a meta ?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success('Meta excluída com sucesso!');
          handleList();
        } else {
          await Swal.fire('Ops!', `Não foi possível excluir a meta.<br>${error}`, 'error');
        }
      }
    });
  }

  const renderList = () => {
    return (
      <>
        {monthlyGoals?.length > 0 ? (
          <>
            {monthlyGoals.map((x: MonthlyGoalModel, key) => (
              <tr key={key} className="text-sm">
                <td>{x.id}</td>
                <td className='text-wrap'>{x.description}</td>
                <td className='text-wrap'>{x.connectType?.name}{x.groups && x.groups?.length > 0 && ' - ' + x.groups[0]?.name}</td>
                <td>{x.accessProfile?.name}</td>
                <td className="text-center">{FormatNumber(x.score)}</td>
                <td className="text-center">{moment(x.startDate).format('MMMM/YY')?.toUpperCase()}</td>
                <td>
                  {!x?.deletedAt && (
                    <DropdownMenuTable key={key}>
                      <Dropdown.Item className="border-radius-md py-2" onClick={() => updateMonthlyGoal(x)}>
                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                      </Dropdown.Item>
                      {!x?.deletedAt && (
                        <Dropdown.Item className="border-radius-md py-2" onClick={() => deleteMonthlyGoal(x)}>
                          <FontAwesomeIcon icon={faTrash} className="me-2" /> Remover
                        </Dropdown.Item>
                      )}
                    </DropdownMenuTable>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">#ID</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">DESCRIÇÃO</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">TIPO</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7" scope="col">TIPO COMERCIAL</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">PONTUAÇÃO</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">PERÍODO</th>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7 text-center" scope="col">AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {monthlyGoals?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Meta Mensal"
      icon="fas fa-industry"
      footerFixed
      footerInverse
    >
      <div className="MonthlyGoal" data-testid="MonthlyGoal">
        <PageBase
          title="Meta Mensal"
          subtitle="Gerenciamento de meta mensal."
          handleInsert={() => setShowModalImport(true)}
          textButtonAdd='Importar Meta Global'
          showAddtionalButton
          handleAddtional={() => setShowModalInsert(true)}
          textButtonAddtional='Nova Meta Mensal'
          showAddtionalButtonExtra
          handleAddtionalExtra={() => handleDownloadPDFButton()}
          textButtonAddtionalExtra='Download PDF'
          showAddtionalButtonExtra2={showModalButtonPDF}
          handleAddtionalExtra2={() => setShowModalImportPDF(true)}
          textButtonAddtionalExtra2='Importar PDF'
          handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          content={renderContent()}
          hasFilter
          contentButtonLine={renderCloneButton()}
        />

      </div>

      <ModalMonthlyGoalInsert
        show={showModalInsert}
        onClose={() => setShowModalInsert(false)}
      />

      <ModalMonthlyGoalImport
        show={showModalImport}
        onClose={() => setShowModalImport(false)}
      />

      <ModalMonthlyGoalImportPDF
        show={showModalImportPDF}
        onClose={() => setShowModalImportPDF(false)}
      />

      <ModalGoalsClone
        show={showModalClone}
        onClose={setShowModalClone}
      />

      <SidebarFilter>
        <SidebarMonthlyGoalFilter />
      </SidebarFilter>
    </MasterPage>
  );
}

export default MonthlyGoal;
