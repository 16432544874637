import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import './ModalMonthlyGoalInsertGroup.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useForm } from 'react-hook-form';
import { MonthlyGoalGroup, MonthlyGoalGroupTagCondition } from '../../../../models/PanelConnects/MonthlyGoalModel';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import ConditionSelectMonthlyGoal from '../ConditionSelectMonthlyGoal/ConditionSelectMonthlyGoal';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useCovenantGroup } from '../../../../providers/Registrations/Covenant/CovenantGroupProvider';
import { toast } from 'react-toastify';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { MonthlyGoalGroupEnum } from '../../../../enums/PanelConnects/MonthlyGoalGroupEnum';
import { useMonthlyGoalCondition } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalConditionProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { schema, schemaCondition } from './Validate';
import { useMasterCovenantGroup } from '../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider';
import { useCovenant } from '../../../../providers/Registrations/Covenant/CovenantProvider';
import { useContractForm } from '../../../../providers/Proposals/ContractForm/ContractFormProvider';

interface ModalMonthlyGoalInsertGroupProps {
  show: boolean,
  onClose: any,
  groups: MonthlyGoalGroup[],
  setGroups: Dispatch<SetStateAction<MonthlyGoalGroup[]>>,
  indexGroupEdit?: number
}

const tagOptions: any[] = [
  {
    value: MonthlyGoalGroupEnum.FINANCIAL,
    label: 'Financeira'
  },
  {
    value: MonthlyGoalGroupEnum.COVENANT_GROUP,
    label: 'Grupo de Convênio'
  },
  {
    value: MonthlyGoalGroupEnum.COVENANT,
    label: 'Convênio'
  },
  {
    value: MonthlyGoalGroupEnum.COVENANT_MASTER,
    label: 'Grupo Master'
  },
  {
    value: MonthlyGoalGroupEnum.CONTRACT_FORM,
    label: 'Forma de Contrato'
  },
];

const ModalMonthlyGoalInsertGroup: FC<ModalMonthlyGoalInsertGroupProps> = ({ show, onClose, groups, setGroups, indexGroupEdit }) => {
  const [selectedTag, setSelectedTag] = useState<any>();
  const [selectedCondition, setSelectedCondition] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any[]>([]);

  const [conditionsAdd, setConditionsAdd] = useState<MonthlyGoalGroupTagCondition[]>([]);

  const { financialsOptions, handleListFinancialsOptions } = useFinancial();
  const { covenantGroupsOptions, handleList } = useCovenantGroup();
  const { handleList: handleListCovenantMaster, masterGroupOptions } = useMasterCovenantGroup();
  const {
    handleList: handleListCovenant,
    covenantsOptions,
    setParams: setParamsCovenant,
    params: paramsCovenant
  } = useCovenant();
  const {
    handleList: handleListContractForm,
    contractFormsOptions,
    setParams: setParamsContractForm,
    params: paramsContractForm
  } = useContractForm();
  const { conditionsOptions } = useMonthlyGoalCondition();

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    formState: { errors }
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const {
    handleSubmit: handleSubmitCondition,
    setValue: setValeuCondition,
    reset: resetCondition,
    formState: { errors: errorsCondition }
  } = useForm({ mode: 'onChange', resolver: yupResolver(schemaCondition) });

  useEffect(() => {
    handleListFinancialsOptions(false).then();
    handleList().then();
    handleListCovenantMaster().then();
    setParamsCovenant({ withPaginate: false, active: 'S' });
    setParamsContractForm({ withPaginate: false });
  }, []);

  useEffect(() => {
    if (paramsCovenant?.hasOwnProperty('withPaginate')) {
      handleListCovenant().then();
    }
  }, [paramsCovenant]);

  useEffect(() => {
    if (paramsCovenant?.hasOwnProperty('withPaginate')) {
      handleListContractForm().then();
    }
  }, [paramsContractForm]);

  useEffect(() => {
    if (!show) {
      setValue('name', '');
      reset();
      resetCondition();
      setConditionsAdd([]);
      clearConditionForm();
    }
  }, [show]);

  useEffect(() => {
    if (indexGroupEdit !== undefined) {
      const groupEdit = { ...groups[indexGroupEdit] };
      setValue('name', groupEdit?.name ?? '');
      setConditionsAdd(groupEdit?.tags ?? []);
    }
  }, [indexGroupEdit]);

  useEffect(() => {
    setValeuCondition('tag', selectedTag);
  }, [selectedTag]);

  useEffect(() => {
    setValeuCondition('condition', selectedCondition);
  }, [selectedCondition]);

  useEffect(() => {
    setValeuCondition('value', selectedValue);
  }, [selectedValue]);

  const getValueOptions = (tagId: number = selectedTag?.value): any[] => {
    switch (tagId) {
      case MonthlyGoalGroupEnum.FINANCIAL:
        return financialsOptions;
      case MonthlyGoalGroupEnum.COVENANT_GROUP:
        return covenantGroupsOptions;
      case MonthlyGoalGroupEnum.COVENANT:
        return covenantsOptions;
      case MonthlyGoalGroupEnum.COVENANT_MASTER:
        return masterGroupOptions;
      case MonthlyGoalGroupEnum.CONTRACT_FORM:
        return contractFormsOptions;
      default:
        return [];
    }
  }

  const clearConditionForm = () => {
    setSelectedTag(null);
    setSelectedCondition(null);
    setSelectedValue([]);
  }

  const deleteCondition = (index: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Atenção!',
      html: `Deseja realmente remover a condição?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((value) => {
      if (value.isConfirmed) {
        setConditionsAdd((current) => current?.filter((x, i) => i !== index));
        toast.success('Condição removida com sucesso!');
      }
    });
  }

  const renderTagValues = (tagId: number, values: string) => {
    const _options = getValueOptions(tagId);
    const _values = values?.split(',');
    return (
      <div className='d-flex gap-1 flex-wrap'>
        {_values?.map((x) => <span className='badge bg-success'>{_options?.find((y: any) => y.value === Number(x))?.label}</span>)}
      </div>
    );
  }

  const renderListConditions = () => {
    return (
      <div className='table-responsive'>
        <table className='table text-sm'>
          <thead>
            <tr>
              <th>Tag</th>
              <th>Condição</th>
              <th>Valor</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {conditionsAdd && conditionsAdd?.length > 0 ?
              conditionsAdd?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {tagOptions?.find((x) => x.value === (item.connectGoalTagId ?? item.connectGoalTag?.id))?.label}
                  </td>
                  <td>
                    {conditionsOptions?.find((x) => x.value === (item.connectGoalConditionId ?? item.connectGoalCondition?.id))?.label}
                  </td>
                  <td>
                    {renderTagValues(item.connectGoalTagId ?? item.connectGoalTag?.id ?? 0, item.value1 ?? '')}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faCircleMinus}
                      className='cursor-pointer'
                      onClick={() => deleteCondition(index)}
                    />
                  </td>
                </tr>
              )) :
              <tr>
                <td colSpan={8} className='text-center'>Não existem condições para listar!</td>
              </tr>
            }
          </tbody>
        </table>
      </div >
    );
  }

  const insertCondition = (data: any) => {
    setConditionsAdd((current) => [
      ...current,
      {
        connectGoalTagId: data?.tag?.value,
        connectGoalConditionId: data?.condition?.value,
        value1: data?.value?.map((x: any) => x.value)?.join(','),
        type: 'and'
      }
    ]);
    clearConditionForm();
  }

  const onSubmit = (data: any) => {
    if (conditionsAdd?.length > 0) {
      const _data = { ...data };
      _data.tags = conditionsAdd;

      if (indexGroupEdit === undefined) {
        setGroups([...groups, _data]);
        toast.success('Inclusão realizada!')
      } else {
        const _groups = [...groups];
        _groups[indexGroupEdit] = _data;
        setGroups(_groups);
        toast.success('Alteração realizada!')
      }
      onClose();
    } else {
      toast.warning('Adicione ao menos uma condição!');
    }
  }

  return (
    <ModalDefault
      title={'Adicionar Grupo'}
      show={show}
      onClose={onClose}
      sizeModal={'xl'}
      showFooter={true}
      buttonText={indexGroupEdit !== undefined ? 'Alterar Grupo' : 'Adicionar Grupo'}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
      disableEsc={true}
    >
      <div className="ModalMonthlyGoalInsertGroup" data-testid="ModalMonthlyGoalInsertGroup">
        <Card>
          <Card.Body>
            <Row>
              <Col md={6}>
                <FormGroup className='form-group'>
                  <label>NOME *</label>
                  <input
                    type='text'
                    className='form-control'
                    {...register('name')}
                    defaultValue=''
                  />
                  <ErrorMessage name={'Nome'} type={errors?.name?.type?.toString()} />
                </FormGroup>
              </Col>
            </Row>


            <Row>
              <Col md={4}>
                <FormGroup className='form-group'>
                  <label>TAG</label>
                  <ReactSelect
                    name='tag'
                    isSearchable
                    isClearable
                    placeholder="Selecione..."
                    options={tagOptions}
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    value={selectedTag}
                    onChange={(val: any) => {
                      setSelectedTag(val);
                    }}
                    styles={customStyles}
                  />
                  <ErrorMessage name={'Tag'} type={errorsCondition?.tag?.type?.toString()} />
                </FormGroup>
              </Col>
              <Col md={4}>
                <ConditionSelectMonthlyGoal
                  selectedCondition={selectedCondition}
                  setSelectedCondition={setSelectedCondition}
                  isClearable={true}
                />
                <ErrorMessage name={'Condição'} type={errorsCondition?.condition?.type?.toString()} />
              </Col>
              <Col md={4}>
                <FormGroup className='form-group'>
                  <label>VALOR</label>
                  <ReactSelect
                    name='value'
                    isSearchable
                    isMulti
                    isClearable
                    placeholder="Selecione..."
                    options={getValueOptions()}
                    className={`form-control p-0`}
                    noOptionsMessage={() => 'Não há registros'}
                    value={selectedValue}
                    onChange={(val: any) => {
                      setSelectedValue(val);
                    }}
                    styles={customStyles}
                  />
                  <ErrorMessage
                    name={'Valor'}
                    min={1}
                    isArray
                    type={errorsCondition?.value?.type?.toString()} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={12} className='text-end'>
                <button
                  className='btn btn-info text-white'
                  onClick={handleSubmitCondition(insertCondition)}
                >
                  Adicionar Condição
                </button>
              </Col>
            </Row>

            {renderListConditions()}
          </Card.Body>
        </Card>
      </div>
    </ModalDefault>
  )
};

export default ModalMonthlyGoalInsertGroup;
