import { FC, useEffect, useState } from 'react';
import './ModalReceiptInsert.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../../providers/AuthProvider';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row, Card } from 'react-bootstrap';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { schema } from './Validate';
import { useReceipt } from '../../../../providers/Commissioning/Receipt/ReceiptProvider';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import SkeletonTable from '../../../SkeletonTable/SkeletonTable';
import ReceiptTypePeriod from '../ReceiptTypePeriod/ReceiptTypePeriod';
import { ReactSelectOptions } from '../../../../models/ReactSelectOptions';
import { useFinancial } from '../../../../providers/Registrations/Financial/FinancialProvider';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useEnterprise } from '../../../../providers/Registrations/Company/EnterpriseProvider';
import { FinancialModel } from '../../../../models/Registrations/Financial/FinancialModel';
import { ReceiptTypeModel } from '../../../../models/Commissioning/Receipt/ReceiptTypeModel';
import moment from 'moment';
import { useCampaign } from '../../../../providers/Commissioning/Campaign/CampaignProvider';
import { ReceiptEnterpriseIds } from '../../../../models/Commissioning/Receipt/ReceiptEnterpriseIds';

interface ModalReceiptInsertProps {
  show: boolean;
  onClose: () => void;
  receiptId: number;
  setReceiptId: any;
  receiptDate: string;
  setReceiptDate: any;
  businessDate: string;
  setBusinessDate: any;
}


const ModalReceiptInsert: FC<ModalReceiptInsertProps> = ({ show, onClose, receiptId, setReceiptId, receiptDate, setReceiptDate, businessDate, setBusinessDate }) => {

  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [selectedOptionsReceiptTypePeriod, setSelectedOptionsReceiptTypePeriod] = useState<(any)[]>([]);
  // const [selectedOptionsReceiptType, setSelectedOptionsReceiptType] = useState<(ReactSelectOptions | null)[]>([]);
  const [checkedEnterprises, setCheckedEnterprises] = useState<(number | undefined)[]>([]);
  const [selectedFinancial, setSelectedFinancial] = useState<any>(null);
  const [receiptTypes, setReceiptTypes] = useState<any[]>([])
  const [checkedEnterpriseAll, setCheckedEnterpriseAll] = useState(false);

  const {
    receipt,
    setReceipt,
    setError,
    handleList,
    handleListReceiptType,
    handleFetch,
    handleGet,
    handleListFinancialEnterprise,
    isLoadingType,
    receiptsType,
    setReceiptsType,
    receiptsTypePeriodOptions,
    financialEnterprise
  } = useReceipt();
  const { financials, setParams, params, handleList: handleListFinancials } = useFinancial();
  const { enterprises, setParams: setParamsEnterprise } = useEnterprise();
  const { campaigns, setParams: setParamsCampaign, handleListCampaignsOptions } = useCampaign();

  const { onInvalid } = useAuth();
  const animatedComponents = makeAnimated();

  const { handleSubmit, reset, control, setValue, formState: { errors } } = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });


  const handleClose = () => {
    setError('');
    setSelectedOptionsReceiptTypePeriod([]);
    setCheckedEnterprises([]);
    setSelectedFinancial(null);
    setReceiptTypes(resetReceiptTypes);
    setReceipt(undefined);
    setReceiptId(0);
    setReceiptDate('');
    handleListReceiptType();
    handleListCampaignsOptions();
    reset();
    onClose();
  }

  const onSubmit = async () => {
    setIsLoadingSubmit(true);
    setError('');

    const filteredReceiptTypes = receiptTypes?.filter(item => item?.receipt?.receiptTypePeriodId !== null && item?.receipt?.receiptTypePeriodId !== undefined);

    let dataReceipt = {};

    if (receipt?.receipts?.[0]?.id) {
      dataReceipt = {
        id: receipt?.receipts?.[0]?.id,
        financialId: receipt?.receipts?.[0]?.financialId,
        receiptTypeId: receipt?.receipts?.[0]?.receiptTypeId,
        campaignId: receipt?.receipts?.[0]?.campaignId ?? 0,
        receiptTypePeriodId: selectedOptionsReceiptTypePeriod[0]?.value,
        periods: filteredReceiptTypes?.flatMap(item => item?.receipt?.periods),
        enterprises: checkedEnterprises
      };
    } else {
      dataReceipt = {
        financialId: selectedFinancial?.value,
        receiptTypes: filteredReceiptTypes?.map(item => ({
          id: item?.type,
          receipt: {
            receiptTypePeriodId: item?.receipt?.receiptTypePeriodId,
            campaignId: item?.id,
            periods: item?.receipt?.periods
          }
        })),
        enterprises: checkedEnterprises
      };
    }

    const ret = await handleFetch(dataReceipt as any, receipt?.receipts?.[0]?.id ? 'update' : 'create');

    if (ret) {
      toast.success('Recebimento salvo com sucesso!');
      onClose();
      handleList();
      handleListReceiptType();
      handleListCampaignsOptions();
      setSelectedOptionsReceiptTypePeriod([]);
      setCheckedEnterprises([]);
      setSelectedFinancial(null);
      setReceiptId(0);
      setReceiptDate('');
      setReceiptTypes(resetReceiptTypes);
    }
    setIsLoadingSubmit(false);
  }

  useEffect(() => {
    setParams({
      page: 1,
      withPaginate: false
    });
    setParamsEnterprise({
      withPaginate: false,
      id: ReceiptEnterpriseIds,
      orderBy: [{ name: 'asc' }]
    });
  }, []);

  useEffect(() => {
    if (Object?.keys(params)?.length > 1) {
      handleListFinancials();
    }
  }, [params]);

  const returnObjectReceipt = () => {

    let _item = receiptTypes?.findIndex(item => item?.id === receipt?.receipts?.[0]?.receiptTypeId);

    const _selected = [{ label: receipt?.receipts?.[0]?.receiptTypePeriod?.name, value: receipt?.receipts?.[0]?.receiptTypePeriod?.id }];
    setSelectedOptionsReceiptTypePeriod(_selected);

    if (receipt?.receipts?.[0]?.receiptTypePeriod?.id === 1) {
      if (_item !== -1) {
        receiptTypes[_item].receipt.periods = [];

        const selectedTypePeriodValue = _selected[0]?.value;
        receiptTypes[_item].receipt.receiptTypePeriodId = selectedTypePeriodValue ?? null;
        receiptTypes[_item].receipt.campaignId = receipt?.receipts?.[0]?.campaignId ?? null;
        setReceiptTypes([...receiptTypes]);
      }
    }

    if (receipt?.receipts?.[0]?.receiptTypePeriod?.id === 2) {
      if (_item !== -1) {
        receiptTypes[_item].receipt.periods = [];

        receipt?.receipts?.[0]?.receiptWeekly?.forEach((weekly: any) => {
          receiptTypes[_item]?.receipt?.periods.push(weekly?.dayWeek);
        });
        const selectedTypePeriodValue = _selected[0]?.value;
        receiptTypes[_item].receipt.receiptTypePeriodId = selectedTypePeriodValue ?? null;
        receiptTypes[_item].receipt.campaignId = receipt?.receipts?.[0]?.campaignId ?? null;
        setReceiptTypes([...receiptTypes]);
      }
    }

    if (receipt?.receipts?.[0]?.receiptTypePeriod?.id === 3) {
      if (_item !== -1) {
        receiptTypes[_item].receipt.periods = [];

        receipt?.receipts?.[0]?.receiptMonthly?.forEach((monthly: any) => {
          receiptTypes[_item]?.receipt?.periods?.push(monthly?.dayMonth);
        });
        const selectedTypePeriodValue = _selected[0]?.value;
        receiptTypes[_item].receipt.receiptTypePeriodId = selectedTypePeriodValue;
        receiptTypes[_item].receipt.campaignId = receipt?.receipts?.[0]?.campaignId ?? null;
        setReceiptTypes([...receiptTypes]);
      }
    }

    if (receipt?.receipts?.[0]?.receiptTypePeriod?.id === 4) {
      if (_item !== -1) {
        receiptTypes[_item].receipt.periods = [];

        receipt?.receipts?.[0]?.receiptPredefined?.forEach((predefined: any) => {
          receiptTypes[_item]?.receipt?.periods?.push(moment(predefined?.date).format('YYYY-MM-DD'));
        });
        const selectedTypePeriodValue = _selected[0]?.value;
        receiptTypes[_item].receipt.receiptTypePeriodId = selectedTypePeriodValue;
        receiptTypes[_item].receipt.campaignId = receipt?.receipts?.[0]?.campaignId ?? null;
        setReceiptTypes([...receiptTypes]);
      }
    }

    if (receipt?.receipts?.[0]?.receiptTypePeriod?.id === 5) {
      if (_item !== -1) {
        receiptTypes[_item].receipt.periods = [];

        const selectedTypePeriodValue = _selected[0]?.value;
        receiptTypes[_item].receipt.receiptTypePeriodId = selectedTypePeriodValue;
        receiptTypes[_item].receipt.campaignId = receipt?.receipts?.[0]?.campaignId ?? null;
        setReceiptTypes([...receiptTypes]);
      }
    }
  }

  useEffect(() => {
    if (show && receipt) {
      const financial = financials?.find(financial => financial?.id === receipt?.receipts?.[0]?.financialId);
      if (financial) {
        setSelectedFinancial({ label: financial?.name, value: financial?.id, externalId: financial?.externalId });
        setValue('financialId', financial?.id);
      }

      const checkedEnterprises = receipt?.receipts?.[0]?.enterprises?.map((enterprise: any) => enterprise?.enterpriseId) || [];
      setCheckedEnterprises(checkedEnterprises);
    } else {
      reset();
      setReceipt(undefined);
    }
  }, [show, receipt]);

  useEffect(() => {
    if (receiptId > 0) {
      handleGet(receiptId, 'insert', receiptDate, businessDate)
    }
  }, [receiptId])

  useEffect(() => {
    if (show && campaigns?.length > 0) {
      setReceiptsType((current) => [...current, ...campaigns?.map((item) => {
        return {
          id: item?.id,
          type: 4,
          key: `c-${item?.id}`,
          name: item?.description,
          description: item?.description,
          order: 1
        }
      })])
    }
  }, [campaigns, show])

  useEffect(() => {
    if (show && receiptsType?.length > 0) {
      const returnReceiptTypes = receiptsType?.map(item => ({
        id: item?.id,
        key: item?.key ?? item?.id,
        type: item?.type ?? item?.id,
        receipt: {
          receiptTypePeriodId: null,
          campaignId: null,
          periods: []
        }
      }));

      setReceiptTypes(returnReceiptTypes);
    }
  }, [receiptsType, show]);

  useEffect(() => {
    setValue('selectedTypePeriod', selectedOptionsReceiptTypePeriod);
    setValue('receiptTypes', receiptTypes);
  }, [selectedOptionsReceiptTypePeriod])

  useEffect(() => {
    setValue('receiptTypes', receiptTypes)
  }, [receiptTypes]);

  useEffect(() => {
    setValue('checkboxEnterprise', checkedEnterprises.length > 0)
  }, [checkedEnterprises])

  useEffect(() => {
    if (!receipt) {
      setSelectedOptionsReceiptTypePeriod([]);
      setCheckedEnterprises([]);
      setReceiptsType([]);
      setReceiptTypes(resetReceiptTypes)
    }
    if (selectedFinancial && receipt) {
      returnObjectReceipt();
    }
  }, [selectedFinancial, receipt]);

  useEffect(() => {
    const allEnterpriseIds = enterprises?.filter((item) => item?.active === 1).map((item) => item?.id);
    const allChecked = checkedEnterprises?.length === allEnterpriseIds?.length;
    setCheckedEnterpriseAll(allChecked);
  }, [checkedEnterprises, enterprises]);

  useEffect(() => {
    if (financialEnterprise?.length > 0 && !receipt) {
      const companiesByFinancial = financialEnterprise?.map(x => x?.id);
      setCheckedEnterprises(companiesByFinancial);
    }
  }, [financialEnterprise]);


  const resetReceiptTypes = receiptsType?.map(item => ({
    id: item?.id,
    key: item?.key,
    type: item?.type ?? item?.id,
    receipt: {
      receiptTypePeriodId: null,
      campaignId: null,
      periods: []
    }
  }));

  const handleFinancialChange = async (val: any) => {
    setSelectedFinancial(val);
    setReceiptsType([]);
    await handleListReceiptType();
    if (val?.value) {
      await handleListFinancialEnterprise(val?.value);
    }
    setParamsCampaign({ financialId: val?.value, active: true });
  };

  const handleTypePeriodChange = (val: ReactSelectOptions | null, item: any, index: number) => {
    const updatedSelectedTypePeriod = [...selectedOptionsReceiptTypePeriod];
    updatedSelectedTypePeriod[index] = val;
    setSelectedOptionsReceiptTypePeriod(updatedSelectedTypePeriod);

    const _item = receiptId === 0 ? receiptTypes?.find((x: any, key: number) => key === index) : receiptTypes?.find((x: any, key: number) => x?.id === item?.type);
    _item.receipt.receiptTypePeriodId = val?.value;
    _item.receipt.periods = [];
    receiptTypes[receiptId === 0 ? receiptTypes?.findIndex((x: any, key: number) => key === index) : receiptTypes?.findIndex((x: any, key: number) => x?.id === item?.type)] = _item;
  };

  const handleEnterpriseChange = (item: any, event: any) => {
    const isChecked = event?.target?.checked;

    setCheckedEnterprises(enterprises => {
      const updatedEnterprises = isChecked
        ? [...enterprises, item?.id]
        : enterprises?.filter(id => id !== item?.id);

      const checkedEnterprises = updatedEnterprises.length > 0;
      setValue('checkboxEnterprise', checkedEnterprises);

      return updatedEnterprises;
    });
  };

  const isEnterpriseChecked = (item: any): boolean => {
    return checkedEnterprises?.includes(item?.id);
  };

  const handleCheckedEnterprisedAll = (e: any) => {
    const checked = e?.target?.checked;
    setCheckedEnterpriseAll(checked);

    if (checked) {
      const allEnterpriseIds = enterprises?.filter((item) => item?.active === 1).map((item) => item?.id);
      setCheckedEnterprises(allEnterpriseIds);
    } else {
      setCheckedEnterprises([]);
    }
  };

  return (
    <ModalDefault
      title={receipt?.receipts?.[0]?.id ? 'Editar Recebimento' : 'Adicionar Recebimento'}
      show={show}
      onClose={handleClose}
      sizeModal={'xxl'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalReceiptInsert" data-testid="ModalReceiptInsert">

        <Card className="mb-3">
          <Card.Body >
            <Row className="d-flex justify-content-between align-items-center">
              <Col md={3}>
                <FormGroup className="form-group">
                  <label htmlFor="financialId">Financeira</label>
                  <Controller
                    control={control}
                    name={'financialId'}
                    render={({ field: { onChange, name, ref } }) => (
                      <ReactSelect
                        isDisabled={!!receipt?.receipts?.[0]?.id}
                        ref={ref}
                        name={name}
                        isClearable
                        isSearchable
                        placeholder="Selecione..."
                        options={financials?.map((item: FinancialModel) => {
                          return { label: item?.name, value: item?.id, externalId: item?.externalId };
                        })}
                        className={`form-control p-0 ${onInvalid(errors?.financialId)}`}
                        components={animatedComponents}
                        noOptionsMessage={() => 'Não há registros'}
                        value={selectedFinancial ?? ''}
                        defaultValue={selectedFinancial}
                        onChange={(val: any) => {
                          handleFinancialChange(val);
                          onChange(val?.value ?? null);
                        }}
                        styles={customStyles}
                      />
                    )}
                  />
                  <ErrorMessage name={'Financeira'} type={errors?.financialId?.type?.toString()} />
                </FormGroup>
              </Col>
              <Col md={2} className="center-image">
                {selectedFinancial && (
                  <div id="insertImageFinancial">
                    <img
                      src={`/assets/img/financials/${selectedFinancial?.externalId}.svg`}
                      style={{ width: '90px', height: '90px' }}
                      onError={(e: any) => {
                        e.target.onerror = null;
                        const insertImageFinancial = document.getElementById('insertImageFinancial');
                        if (insertImageFinancial instanceof HTMLElement && selectedFinancial?.label) {
                          insertImageFinancial.innerHTML = `<h4>${selectedFinancial?.label}</h4>`;
                        }
                      }}
                    />
                  </div>

                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {selectedFinancial && (
          <Card className="mb-3">
            <Card.Body>
              <>
                {!isLoadingType ? (
                  <div className="table-responsive-sm" style={{ minHeight: '350px' }}>
                    <table className="table  mb-3 w-100">
                      <thead className="thead-light">
                        <tr>
                          <th className="text-uppercase opacity-7 text-center" >Tipo Recebimento</th>
                          <th className="text-uppercase opacity-7 text-center min-width" >Data Recebimento</th>
                          {/* <th className="text-uppercase opacity-7 text-center" scope="col">Período</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {receiptsType?.map((item: ReceiptTypeModel, index) => (
                          <>
                            {item?.id !== 4 &&
                              <tr key={item?.id}>
                                <td>{item?.name}</td>
                                <td>
                                  <Col md={12}>
                                    <FormGroup className="form-group">
                                      <Controller
                                        key={item?.id}
                                        control={control}
                                        name={`receiptTypePeriodId${index}`}
                                        render={({ field: { onChange, name, ref } }) => (
                                          <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={receiptsTypePeriodOptions?.map((x: any) => {
                                              return { ...x, type: item?.type ?? item?.id, key: item?.key ?? item?.id }
                                            })}
                                            placeholder="Selecione..."
                                            className={`form-control p-0 ${onInvalid(errors?.[name])}`}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            value={selectedOptionsReceiptTypePeriod[index] ?? null}
                                            defaultValue={selectedOptionsReceiptTypePeriod[index] ?? null}
                                            onChange={(val: any) => {
                                              handleTypePeriodChange(val, item, index);
                                              onChange(val?.value ?? null);
                                            }}
                                            styles={customStyles}
                                          />
                                        )}
                                      />
                                      <ErrorMessage name="Data recibemento" isArray={true} min={1} type={errors?.selectedTypePeriod?.type?.toString()} />
                                    </FormGroup>
                                    <ReceiptTypePeriod
                                      receiptTypePeriod={selectedOptionsReceiptTypePeriod[index]?.value || null}
                                      receiptTypes={receiptTypes}
                                      setReceiptTypes={setReceiptTypes}
                                      itemIdEdit={receiptId > 0 ? receiptTypes.findIndex((x: any) => x?.id === item?.type) : index}
                                    />
                                  </Col>
                                </td>
                                {/* <td>
                                  <Col>
                                    <FormGroup className="form-group">
                                      <Controller
                                        key={item?.id}
                                        control={control}
                                        name={`typeId${item?.id}`}
                                        render={({ field: { onChange, name, ref } }) => (
                                          <ReactSelect
                                            ref={ref}
                                            name={name}
                                            isClearable
                                            isSearchable
                                            options={optionsSelectType}
                                            placeholder="Selecione..."
                                            className={`form-control p-0`}
                                            components={animatedComponents}
                                            noOptionsMessage={() => 'Não há registros'}
                                            value={selectedOptionsReceiptType[item.id ?? -1] ?? null}
                                            defaultValue={selectedOptionsReceiptType[item.id ?? -1] ?? null}
                                            onChange={(val: any) => {
                                              handleTypeChange(val, item);
                                              onChange(val?.value ?? null);
                                            }}
                                            styles={customStyles}
                                          />
                                        )}
                                      />
                                    </FormGroup>
                                  </Col>
                                </td> */}
                              </tr>
                            }
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <SkeletonTable />
                )}
              </>
              <ErrorMessage name={'Período recebimento'} type={errors?.receiptTypes?.type?.toString()} />
            </Card.Body>
          </Card>
        )}

        {selectedFinancial && (
          <Card>
            <Card.Header className="pb-1 px-4">
              Empresas
            </Card.Header>
            <Card.Body className="d-flex flex-wrap">
              <Col md={12} className="form-check form-switch mb-4">
                <label className="form-check-label" htmlFor="checkboxAll">
                  TODOS
                </label>
                <input
                  id="checkboxAll"
                  name="checkboxAll"
                  className="form-check-input"
                  type="checkbox"
                  checked={checkedEnterpriseAll}
                  onChange={handleCheckedEnterprisedAll}
                />
              </Col>

              {enterprises?.filter(item => item?.active === 1).map((item) => (
                <Col key={item.id} xs={12} sm={12} md={4} xl={3} className="form-check form-switch">
                  <label className="form-check-label" htmlFor={`checkboxEnterprise`}>
                    {item?.name}
                  </label>
                  <Controller
                    key={item?.id}
                    control={control}
                    name={`checkboxEnterprise`}
                    render={({ field: { name, ref } }) => (
                      <input
                        ref={ref}
                        name={name}
                        className={`form-check-input ${onInvalid(errors?.[name])}`}
                        type="checkbox"
                        checked={isEnterpriseChecked(item)}
                        onChange={(e: any) => handleEnterpriseChange(item, e)}
                      />
                    )}
                  />
                </Col>
              ))}
              <ErrorMessage name={'Empresas'} type={errors?.checkboxEnterprise?.type?.toString()} />
            </Card.Body>
          </Card>
        )}
      </div>
    </ModalDefault>
  );
}

export default ModalReceiptInsert;
