import { FC, useEffect, useRef, useState } from "react";
import "./ModalTermsAccessProfileView.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { TermsDocumentsRulesModel } from "../../../models/Terms/TermsDocumentsRulesModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

interface ModalTermsAccessProfileViewProps {
  show: boolean;
  onClose: () => void;
  kindOfPerson: string;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsAccessProfileView: FC<ModalTermsAccessProfileViewProps> = ({ show, onClose, kindOfPerson }) => {
  const {term, handleTermsRules, rules, handleDeleteRules, error} = useTermsDocuments();

  const handleDeleteAccessProfile = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o nivel de acesso ${item?.accessProfile[0].name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDeleteRules(item.id || 0).then();
        if (ret) {
          toast.success('Nivel de acesso inativado com sucesso!');
          handleTermsRules(term.id);
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o nivel de acesso.<br>${error}`, 'error');
        }
      }
    });
  }
  const handleClose = () => {
    onClose();
  };

  const renderList = () => {
    return (
      <>
      {rules?.length > 0 ? (
        <>
        {rules?.length && rules.map((x: TermsDocumentsRulesModel, key) => (
            x.accessProfile && x.kindOfPerson === kindOfPerson && (
              <tr key={key} className="text-sm">
                <td>
                  <div className={`text-xs font-weight-bolder`}>
                    {x.accessProfile[0].name}
                  </div>
                </td>
                <td>
                    <div className={'cursor-pointer ms-3'} onClick={() => handleDeleteAccessProfile(x)}><FontAwesomeIcon icon={faTrash} /></div>
                </td>
              </tr>
            )
        ))}
        </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">
              Não há registros
            </td>
          </tr>
        )}
    </>
  )};

  return (
    <ModalDefault
      title={"Gerenciar Nivel de acesso"}
      show={show}
      onClose={handleClose}
      sizeModal={"lg"}
      showFooter={true}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsAccessProfileView" data-testid="ModalTermsAccessProfileView">
        <Row>
          <Col>
            <div className="table-responsive">
              <table className="table align-items-center mb-0">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                      NIVEL DE ACESSO
                    </th>
                    <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                      EXCLUIR
                    </th>
                  </tr>
                </thead>
                <tbody>{renderList()}</tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsAccessProfileView;
