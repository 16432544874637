import React, { FC, useEffect, useMemo, useState } from "react";
import "./PartnerStatusReportByBranch.scss";
import { useBiPartner } from "../../../../../providers/BI/Dashboard/BiPartnerProvider";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";
import FormatMoney from "../../../../../utils/FormatMoney";

interface PartnerStatusReportByBranchProps { }

const BUTTONS: any = {
  total: "Total",
  active: "Ativos",
  inactive: "Inativos",
  activePercent: "Participação Ativas",
  inactivePercent: "Participação inativas",
};

const PartnerStatusReportByBranch: FC<PartnerStatusReportByBranchProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { partnerStatusReportByBranch, setPartnerStatusReportByBranch } =
    useBiPartner();
  const {
    handleListStatusProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight,
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListStatusProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.BRANCH,
        group: {},
      });
      if (!partnerStatusReportByBranch) {
        handleFetch().then();
      }
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setPartnerStatusReportByBranch(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListStatusProduction({
      ...params,
      view: BI_VIEWS_ENUM.BRANCH,
      group: {},
    }).then();

    if (result) {
      setPartnerStatusReportByBranch(result);
    }
  };

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [partnerStatusReportByBranch]);

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Filiais: item.branchName,
        Total: item.total,
        Ativos: item.active,
        Inativos: item.inactive,
        "Participacao Ativos": item.activePercent,
        "Participacao Inativos": item.inactivePercent,
      };

      _data.push(_row);
    });
    handleExcel(_data, "Producao mensal por comercial");
  };

  const hasData = (): boolean => {
    if (
      partnerStatusReportByBranch?.items &&
      partnerStatusReportByBranch?.items?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      const _columns: any[] = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Filiais</div>
          ),
          selector: (data: any) => data?.branchName,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{`${data?.branchName}`}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data?.total ?? 0,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "total")?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Ativos</div>,
          selector: (data: any) => data?.active ?? 0,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "active")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">Inativos</div>
          ),
          selector: (data: any) => data?.inactive ?? 0,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactive")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Ativos
            </div>
          ),
          selector: (data: any) => data?.activePercent ?? 0,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data?.activePercent, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "activePercent")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Inativos
            </div>
          ),
          selector: (data: any) => data?.inactivePercent ?? 0,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data?.inactivePercent, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactivePercent")
            ?.show,
        },
      ];

      setColumns(_columns);
    }
  }, [partnerStatusReportByBranch, hideButtons]);

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];

      Object.keys(BUTTONS).forEach((key) => {
        _buttons.push({
          alias: key,
          label: BUTTONS[key],
          show: true,
        });
      });

      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const mountRows = () => {
    if (hasData()) {
      const _rows: any = [];

      partnerStatusReportByBranch?.items?.forEach((item) => {
        const _row: any = {
          branchName: item?.branchName,
          total: item?.total,
          active: item?.active,
          inactive: item?.inactive,
          activePercent: item?.activePercent,
          inactivePercent: item?.inactivePercent,
        };

        _rows.push(_row);
      });

      setRows([
        ..._rows,
        {
          ...partnerStatusReportByBranch?.total,
          branchName: 'TOTAL'
        }
      ]);
    } else {
      setRows([]);
    }
  };

  return (
    <div
      className="PartnerStatusReportByBranch"
      data-testid="PartnerStatusReportByBranch"
    >
      <Col md={12} className="d-flex justify-content-end">
        <Row className="display-button mb-3">
          <div className="d-flex justify-content-between align-items-center flex-md-row">
            <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
              {hideButtons?.map((x: any, index: number) => (
                <button
                  type="button"
                  className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                  onClick={() => onClickHideButton(x, index)}
                >
                  <FontAwesomeIcon
                    icon={x.show ? faEye : faEyeSlash}
                    className="me-1"
                  />
                  {x.label}
                </button>
              ))}
              <button
                className="btn bg-success text-white rounded m-0 ms-2"
                onClick={() => {
                  handleExport(rows);
                }}
              >
                <i className="fas fa-file-excel" /> Exportar
              </button>
            </div>
          </div>
        </Row>
      </Col>

      <Col sm={12}>
        <div
          style={{ zIndex: "auto" }}
          className="d-flex justify-content-center align-items-center alert alert-primary text-table mt-3"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
          Atenção: Este relatório apresenta estatísticas atualizadas e não
          possui filtros aplicáveis.
        </div>
      </Col>

      {!isLoading ? (
        <>
          <Card className="mt-4">
            <Card.Body>
              {rows?.length > 0 ? (
                <AppDataTable
                  columns={columns}
                  rows={rows}
                  pagination={false}
                  handleOrder={handleSortValue}
                  conditionalRowStyles={[
                    {
                      when: (data: any) => data.branchName === 'TOTAL',
                      style: biTotalRowStyle
                    }
                  ]}
                  cellColor={biCellColor}
                  cellFontSize={dataFontSize}
                  maxHeight={tableHeight}
                  display="flex"
                />
              ) : (
                <div className="d-flex mt-9 justify-content-center general-error-msg">
                  {generalErrorMsg}
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default PartnerStatusReportByBranch;
