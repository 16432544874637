import React, { FC, useEffect, useMemo, useState } from "react";
import "./DebtorsReportByBranch.scss";
import { useBiPartner } from "../../../../../providers/BI/Dashboard/BiPartnerProvider";
import FormatMoney from "../../../../../utils/FormatMoney";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface DebtorsReportByBranchProps { }

const BUTTONS: any = {
  totalQuantity: "Total",
  activeQuantity: "Ativos",
  inactiveQuantity: "Inativos",
  totalDebt: "Valor",
  totalDebtActivePercentage: "Participação Ativos",
  totalDebtInactivePercentage: "Participação inativos",
};

const DebtorsReportByBranch: FC<DebtorsReportByBranchProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  const { debtorsReportByBranch, setDebtorsReportByBranch } = useBiPartner();
  const {
    params,
    handleListDebtor,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
    biCellColor,
    dataFontSize,
    tableHeight,
  } = useBiDashboard();

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [debtorsReportByBranch]);

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListDebtor);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.BRANCH,
        group: {},
      });
      if (!debtorsReportByBranch) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setDebtorsReportByBranch(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Filiais: item.name,
        Total: item.totalQuantity,
        Ativos: item.activeQuantity,
        Inativos: item.inactiveQuantity,
        Valor: item.totalDebt,
        "Participacao ativos": item.totalDebtActivePercentage,
        "Participacao inativos": item.totalDebtInactivePercentage,
      };
      _data.push(_row);
    });
    handleExcel(_data, "Producao de parceiro por filial");
  };

  const handleFetch = async () => {
    const result = await handleListDebtor({
      ...params,
      view: BI_VIEWS_ENUM.BRANCH,
      group: {},
    }).then();

    if (result) {
      setDebtorsReportByBranch(result);
    }
  };

  const mountHideButtons = () => {
    if (
      debtorsReportByBranch?.items &&
      debtorsReportByBranch?.items?.length > 0
    ) {
      const _buttons: any = [];

      Object.keys(BUTTONS).forEach((key) => {
        _buttons.push({
          alias: key,
          label: BUTTONS[key],
          show: true,
        });
      });

      setHideButtons(_buttons);
    }
  };

  const mountColumns = useMemo(() => {
    if (
      debtorsReportByBranch?.items &&
      debtorsReportByBranch?.items?.length > 0
    ) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Filiais</div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data?.totalQuantity,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "totalQuantity")
            ?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Ativos</div>,
          selector: (data: any) => data?.activeQuantity,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "activeQuantity")
            ?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">Inativos</div>
          ),
          selector: (data: any) => data?.inactiveQuantity,
          sortable: true,
          right: true,
          omit: !hideButtons?.find((x: any) => x.alias === "inactiveQuantity")
            ?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Valor</div>,
          selector: (data: any) => data?.totalDebt,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data?.totalDebt)}</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "totalDebt")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Ativos
            </div>
          ),
          selector: (data: any) => data?.totalDebtActivePercentage,
          cell: (data: any) => (
            <div>{FormatMoney(data.totalDebtActivePercentage, false)}%</div>
          ),
          sortable: true,
          right: true,
          omit: !hideButtons?.find(
            (x: any) => x.alias === "totalDebtActivePercentage"
          )?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação <br />
              Inativos
            </div>
          ),
          selector: (data: any) => data?.totalDebtInactivePercentage,
          cell: (data: any) => (
            <div>{FormatMoney(data.totalDebtInactivePercentage, false)}%</div>
          ),
          sortable: true,
          right: true,
          omit: !hideButtons?.find(
            (x: any) => x.alias === "totalDebtInactivePercentage"
          )?.show,
        },
      ];

      setColumns(_columns);
    }
  }, [debtorsReportByBranch, hideButtons]);

  const mountRows = () => {
    if (debtorsReportByBranch?.items && debtorsReportByBranch?.items?.length > 0) {
      const _rows: any = [];

      debtorsReportByBranch?.items?.forEach((item) => {
        const _row: any = {
          name: item?.name,
        };

        item?.periods?.forEach((period: any) => {
          _row.totalQuantity = period?.totalQuantity;
          _row.activeQuantity = period?.activeQuantity;
          _row.inactiveQuantity = period?.inactiveQuantity;
          _row.totalDebt = period?.totalDebt;
          _row.totalDebtActivePercentage = period?.totalDebtActivePercentage;
          _row.totalDebtInactivePercentage =
            period?.totalDebtInactivePercentage;
        });

        _rows.push(_row);
      });

      setRows(_rows);
    } else {
      setRows([]);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  return (
    <div className="DebtorsReportByBranch" data-testid="DebtorsReportByBranch">
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                  <button
                    className="btn bg-success text-white rounded m-0 ms-2"
                    onClick={() => {
                      handleExport(rows);
                    }}
                  >
                    <i className="fas fa-file-excel" /> Exportar
                  </button>
                </div>
              </div>
            </Row>
          </Col>

          <Col sm={12}>
            <div
              style={{ zIndex: "auto" }}
              className="d-flex justify-content-center align-items-center alert alert-primary text-table mt-3"
            >
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              Atenção: Este relatório apresenta estatísticas atualizadas e não
              inclui filtros de datas.
            </div>
          </Col>

          <Card>
            <Card.Body>
              <div className="text-primary pb-1">
                {"PARCEIROS COM DÉBITOS POR FILIAL"}
              </div>
              {rows?.length > 0 ? (
                <AppDataTable
                  columns={columns}
                  rows={rows}
                  pagination={false}
                  cellColor={biCellColor}
                  cellFontSize={dataFontSize}
                  maxHeight={tableHeight}
                  display="flex"
                />
              ) : (
                <div className="d-flex mt-9 justify-content-center general-error-msg">
                  {generalErrorMsg}
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default DebtorsReportByBranch;
