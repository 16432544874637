import { FC, useEffect, useState } from 'react';
import './ModalVisitsReportsDetail.scss';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useVisitsReports } from '../../../../providers/Visits/VisitsReports/VisitsReportsProvider';
import { VisitsReportsModel } from '../../../../models/Visits/VisitsReports/VisitsReportsModel';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faHeadset, faImage, faInfoCircle, faListAlt, faMagnifyingGlassPlus, faMap, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import GoogleMaps from '../../../GoogleMaps/GoogleMaps';
import Gallery from '../../../Gallery/Gallery';

interface ModalVisitsReportsDetailProps {
  show: boolean;
  onClose: () => void;
}

const ModalVisitsReportsDetail: FC<ModalVisitsReportsDetailProps> = ({ show, onClose }) => {
  const [images, setImages] = useState<any[]>([]);

  const { report, setReport } = useVisitsReports();

  const handleClose = async () => {
    setReport({} as VisitsReportsModel);
    onClose();
  }

  useEffect(() => {
    if (show && !!report) {
      let _images: any[] = report?.visitsImages?.map((image: any) => { return { original: image?.file, thumbnail: image?.file }; }) || [];
      setImages(_images);
    }
  }, [show, report]);

  return (
    <ModalDefault
      title={`Detalhes da visita - ${report?.id}`}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      backdrop="static"
    >
      <div className="ModalVisitsReportsDetail" data-testid="ModalVisitsReportsDetail">
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitor">
                <FontAwesomeIcon icon={faUserCircle} /> COMERCIAL
              </label>
              <input
                type="text"
                className="form-control"
                value={`${report?.userVisitor?.name} - ${report?.userVisitor?.partnerCode}`}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="userVisited">
                <FontAwesomeIcon icon={faUserCircle} /> PARCEIRO
              </label>
              <input
                type="text"
                className="form-control"
                value={`${report?.userVisited?.name ?? report?.partnerRegistrationProspecting?.name} - ${report?.userVisited?.partnerCode ?? report?.partnerRegistrationProspecting?.partnerCode}`}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="address">
                <FontAwesomeIcon icon={faAddressCard} /> ENDEREÇO DO PARCEIRO
              </label>
              <input
                type="text"
                className="form-control"
                value={report?.userVisited?.address ? `${report?.userVisited?.address?.publicPlace ?? ''}, ${report?.userVisited?.address?.numbers ?? ''}, ${report?.userVisited?.address?.neighborhood ?? ''}, ${report?.userVisited?.address?.cep ?? ''}, ${report?.userVisited?.address?.city ?? ''}-${report?.userVisited?.address?.state ?? ''}` : ''}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitedAddress">
                <FontAwesomeIcon icon={faAddressCard} /> ENDEREÇO VISITADO
              </label>
              <input
                type="text"
                className="form-control"
                value={report?.address ? `${report?.address?.publicPlace ?? ''}, ${report?.address?.numbers ?? ''}, ${report?.address?.neighborhood ?? ''}, ${report?.address?.cep ?? ''}, ${report?.address?.city ?? ''}-${report?.address?.state ?? ''}` : ''}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="userVisitedAddress">
                <FontAwesomeIcon icon={faHeadset} /> TIPO DE CONTATO
              </label>
              <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                <div className="item">
                  <div className="bg-item position-relative">
                    <span>
                      {report?.visitTypeContact?.name}
                    </span>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="activities">
                <FontAwesomeIcon icon={faListAlt} /> AÇÃO
              </label>
              <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                {report?.visitsActions?.map((item: any, key: number) => (
                  <div className="item" key={key}>
                    <div className="bg-item position-relative">
                      <span>
                        {item?.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="activities">
                <FontAwesomeIcon icon={faListAlt} /> FOCO
              </label>
              <div className="badge badge-info w-100 mb-3 p-3 text-start d-flex align-items-center">
                {report?.visitsTypesActivities?.map((item: any, key: number) => (
                  <div className="item" key={key}>
                    <div className="bg-item position-relative">
                      <span>
                        {item?.name}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="observation">
                <FontAwesomeIcon icon={faInfoCircle} /> OBSERVAÇÃO
              </label>
              <textarea className="form-control" value={report?.observation} rows={4} disabled></textarea>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="location">
                <FontAwesomeIcon icon={faMap} /> LOCALIZAÇÃO
              </label>
              <GoogleMaps
                zoom={12}
                points={[
                  { lat: Number(report?.latitude) || 0, lng: Number(report?.longitude) || 0, label: 'Visita' },
                  { lat: Number(report?.userVisited?.address?.latitude) || 0, lng: Number(report?.userVisited?.address?.longitude) || 0, label: 'Parceiro' },
                ]}
                showCircle={true}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="location">
                <FontAwesomeIcon icon={faImage} /> IMAGENS
              </label>
              <Gallery images={images} thumbnailPosition="left" />
              <div className='text-end mt-2 message'>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} /> Clique na imagem para amplia-la.
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalVisitsReportsDetail;
