import React, { FC, useEffect, useState } from "react";
import "./FinancialByCard.scss";
import { Card } from "react-bootstrap";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import ContractFormTableBaseCard from "../../ContractForm/ContractFormTableBaseCard/ContractFormTableBaseCard";
import ContractFormHideButtons from "../../ContractForm/ContractFormHideButtons/ContractFormHideButtons";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";
import { useBiFinancialProduction } from "../../../../../providers/BI/Dashboard/BiFinancial/BiFinancialProductionProvider";
import moment from "moment";

interface FinancialByCardProps { }

const FinancialByCard: FC<FinancialByCardProps> = () => {
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { financialByCards, setFinancialByCards } = useBiFinancialProduction();

  const {
    handleListMonthlyProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        filter: {
          ...params?.filter,
          covenantgroup: [21],
        },
        view: BI_VIEWS_ENUM.COVENANT,
        group: {
          financial: true,
        },
      });
      if (!financialByCards) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setFinancialByCards(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      filter: {
        ...params?.filter,
        covenantgroup: [21],
      },
      view: BI_VIEWS_ENUM.COVENANT,
      group: {
        financial: true,
      },
    }).then();

    if (result) {
      setFinancialByCards(result);
    }
  };

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (financialByCards?.months && financialByCards?.months?.length > 0) {
      periods = financialByCards?.months?.map((x: any) => x);
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        "Forma de contrato": item.name,
      };

      periods?.forEach((period: any, index: number) => {
        const month = moment(period, "YYYY-MM")?.format("MMMM")?.toUpperCase();
        const values = item?.periods?.find((x: any) => x.month === period);
        _row[month] = values?.totalProduction ?? 0;
        if (index > 0) {
          _row["C/Q " + month] = values?.totalProductionGrowth ?? 0;
        }
        _row["Participação " + month] = values?.totalProductionPercentage ?? 0;
      });

      _row["Projeção"] = item?.periods[periods?.length - 1]?.totalProductionProjection ?? 0;
      _row["Projeção C/Q"] = item?.periods[periods?.length - 1]?.totalProductionProjectionGrowth ?? 0;

      _data.push(_row);
    });
    handleExcel(_data, "Forma de contrato");
  };

  const renderCard = (item: any) => {
    return (
      <Card className="mt-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="text-start text-primary">{item.name}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(item?.group);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <ContractFormTableBaseCard
            data={item?.group}
            hideButtons={hideButtons}
            setHideButtons={setHideButtons}
            months={financialByCards?.months ?? []}
            nameColumnLabel="Financeira"
          />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="FinancialByCard" data-testid="FinancialByCard">
      {!isLoading ? (
        <>
          <ContractFormHideButtons
            hideButtons={hideButtons}
            setHideButtons={setHideButtons}
          />

          {financialByCards?.items ? (
            financialByCards?.items?.map((item) => renderCard(item))
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default FinancialByCard;
