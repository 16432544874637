import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

interface ReactQuillTextEditorProps {
  editorHtml: string;
  setEditorHtml: (html: string) => void;
}

const ReactQuillTextEditor: React.FC<ReactQuillTextEditorProps> = ({editorHtml, setEditorHtml }) => {

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    'link',
    'image',
  ];

  return (
    <div className='ReactQuillTextEditor' data-testid="ReactQuillTextEditor">
      <ReactQuill
        value={editorHtml} 
        onChange={setEditorHtml} 
        modules={modules} 
        formats={formats}
        style={{ height: '350px', width: '100%' }} 
      />
    </div>
  );
};

export default ReactQuillTextEditor;
