import { FC, useEffect, useState } from "react";
import MasterPage from "../../../components/MasterPage/MasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import SidebarTermsDocumentsFilter from "../../../components/Terms/SideBarTermsDocumentsFilter/SideBarTermsDocumentsFilter";
import SidebarFilter from "../../../components/SidebarFilter/SidebarFilter";
import { useSidebarFilter } from "../../../providers/SidebarFilterProvider";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { usePaginate } from "../../../providers/PaginateProvider";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import { TermsDocumentsModel } from "../../../models/Terms/TermsDocumentsModel";
import DropdownMenuTable from "../../../components/DropdownMenuTable/DropdownMenuTable";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit,faGear,faGears,faTrash} from "@fortawesome/free-solid-svg-icons";
import ModalTermsInsert from "../../../components/Terms/ModalTermsInsert/ModalTermsInsert";
import moment from "moment";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ModalSettings from "../../../components/Terms/ModalSettings/ModalSettings";
import React from "react";

interface TermsDocumentsProps {}

const TermsDocuments: FC<TermsDocumentsProps> = () => {
  const [showModalInsert, setShowModalInsert] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();

  const { terms, handleList, params, setParams, isLoading,  pages,  error, handleDelete, setTerm} = useTermsDocuments();
  const { handlePaginate } = usePaginate();

  useEffect(() => {
    handleList(params);
  }, [params]);

  useEffect(() => {
    setExpandedRow(null);
  }, [showModalInsert]);

  const handleEdit = (item: TermsDocumentsModel) => {
    setTerm(item);
    setShowModalInsert(true);
  };

  const handleDeleteTerm = (item: TermsDocumentsModel) => {
    Swal.fire({
      title: "<strong>Atenção?</strong>",
      html: `<span>Deseja inativar o termo?</span>`,
      icon: "question",
      confirmButtonText: "Sim, deletar!",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonColor: "var(--primary)",
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDelete(item.id || 0).then();
        if (ret) {
          toast.success("Termo inativado com sucesso!");
          handleList(params);
        } else {
          await Swal.fire(
            "Ops!",
            `Não foi possível inativar o termo.<br>${error}`,
            "error"
          );
        }
      }
    });
  };

  const toggleRow = (id: number) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const renderList = () => {
    return (
      <>
        {terms?.length > 0 ? (
          <>
            {terms.map((x: TermsDocumentsModel, key) => (
              <React.Fragment key={key}>
                <tr key={key}>
                  <td>
                    <small className='font-italic font-weight-bold'> Título do termo:</small> {x?.name?.toUpperCase()} <br />
                    <small className='font-italic font-weight-bold'> Fornecedor:</small> {x?.termDocumentSignatureChannel?.[0]?.termSupplier?.name} <br />
                    <small className='font-italic font-weight-bold'> Criação: </small> {moment(x?.createdAt).format("DD/MM/YYYY HH:mm")} <br />
                    {
                      x?.hasExclusive == 1 && (
                        <><span className='badge badge-warning mt-2'> EXCLUSIVO </span></>
                      )
                    }
                  </td>
                  <td>{x?.termDocumentDepartment?.name?.toUpperCase()}</td>
                  <td className="text-wrap">
                    {x?.enterprises && x?.enterprises?.length > 0 && (
                        <>
                            {x?.enterprises?.map((enterprise:any, index: any) => (
                              <>
                                {(index === (x?.enterprises && x?.enterprises?.length -1)) ? enterprise?.name : `${enterprise?.name}, `}
                              </>
                            ))}
                        </>
                      )}
                  </td>
                  <td>
                    {x?.termDocumentSignatureChannel && x?.termDocumentSignatureChannel?.length > 0 && (
                      <>
                        <ul className='mb-0' style={{listStyleType: 'disclosure-closed'}}>
                          {x?.termDocumentSignatureChannel?.map((channels:any) => (
                            <li>
                              {`${channels?.name}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td>
                    <div
                      className={`badge badge-sm ${
                        x?.deletedAt ? "badge-danger" : "badge-success"
                      }`}
                    >
                      {x?.deletedAt ? "Inativo" : "Ativo"}
                    </div>
                  </td>
                  <td>
                    <DropdownMenuTable key={key}>
                    {
                      x?.hasExclusive == 0 && (
                      <Dropdown.Item
                        className="border-radius-md py-2"
                        onClick={() => handleEdit(x)}
                      >
                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Editar
                      </Dropdown.Item>
                      )
                    }
                      <Dropdown.Item
                        className="border-radius-md py-2"
                        onClick={() => handleDeleteTerm(x)}
                      >
                        <FontAwesomeIcon icon={faTrash} className="me-2" />{" "}
                        Remover
                      </Dropdown.Item>
                      {x.hasExclusive === 0 &&
                        <Dropdown.Item
                          className="border-radius-md py-2"
                          onClick={() => toggleRow(key)}
                        >
                          <FontAwesomeIcon icon={faGear} className="me-2" />{" "}
                          Gerenciar
                        </Dropdown.Item>
                      }
                    </DropdownMenuTable>
                  </td>
                </tr>
                {expandedRow === key && (
                  <tr className="collapse-content">
                    <td colSpan={9}>
                      <ModalSettings term={x} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">
              Não há registros
            </td>
          </tr>
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div className="table-responsive">
            <table className="table table-flush m-0 w-100">
              <thead className="thead-light">
                <tr>
                  <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                    DADOS DO DOCUMENTO
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    DEPARTAMENTO
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    EMPRESAS
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    CANAIS DE ASSINATURA
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    STATUS
                  </th>
                  <th
                    className="text-uppercase text-xs font-weight-bolder opacity-7"
                    scope="col"
                  >
                    AÇÕES
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {terms?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  };

  return (
    <MasterPage title="Termo" icon="fas fa-industry" footerFixed footerInverse>
      <div className="TermsDocuments" data-testid="TermsDocuments">
        <PageBase
          title="Documentos de Termos"
          subtitle="Gerenciamento de Documentos de Termos."
          handleFilter={() =>
            showSidebarFilter
              ? setShowSidebarFilter(false)
              : setShowSidebarFilter(true)
          }
          content={renderContent()}
          hasFilter
          handleInsert={() => setShowModalInsert(true)}
        />
      </div>

      <SidebarFilter>
        <SidebarTermsDocumentsFilter />
      </SidebarFilter>

      <ModalTermsInsert
        show={showModalInsert}
        onClose={() => setShowModalInsert(false)}
      />

    </MasterPage>
  );
};

export default TermsDocuments;
