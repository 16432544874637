import api from "../../Api";
import { BaseService } from "../../BaseService";
import FileSaver from 'file-saver';

const URL = "api/services/marketplace";

export class OpportunityService extends BaseService {

    async getOpportunitiesByCampaign(campaignId: number, page: number = 1): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/opportunity/find_by_campaign/${campaignId}?page=${page}&per_page=8`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getOpportunitiesByAgent(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/opportunity/actives_by_agent?page=${params.page || 1}`, params));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getOpportunity(opportunityId: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/opportunity/details/${opportunityId}`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getMarketplacePerformance(): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/user/performance`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async getFailedStatus(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/opportunity/failed/status`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async postFinalizeOpportunity(opportunity_id: number, new_proposal: number, results_status: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/opportunity/finalize`, { opportunity_id, new_proposal, results_status }));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async postFailedOpportunity(opportunity_id: number, failed_status: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/public/opportunity/failed`, { opportunity_id, failed_status }));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }


    async export(params: any): Promise<any[]> {
        try {
            const response = await api.post(`${URL}/public/opportunity/actives_by_agent/excel`, params, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async exportEmail(params: any): Promise<any[]> {
        try {
            const _response = await api.post(`${URL}/public/opportunity/actives_by_agent/email`, params, { responseType: 'blob' });
            return [true, undefined];
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getFilters(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/public/opportunity/panel`));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async changePartner(payload: { agent_id: number, opportunity_id_list: number[] }): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/opportunity/change_agent`, payload));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

    async accessCheck(payload: { report_password: null }): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}/admin/access/check`, payload));
        } catch (error: any) {
            return this.catchHandler(error);
        }
    }

}