import { FC, useEffect } from 'react';
import './Page404.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useStyle } from '../../providers/Style/StyleProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useSidebarFilter } from '../../providers/SidebarFilterProvider';

interface Page404Props { }

const Page404: FC<Page404Props> = () => {
  const { urlDomus } = useStyle();
  const { accessToken } = useAuth();
  const { setShowSidebarFilter } = useSidebarFilter();

  useEffect(() => {
    setShowSidebarFilter(false);
  }, []);

  return (
    <main className="Page404 main-content mt-0" data-testid="Page404">
      <div className="page-header min-vh-100">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={7} className="mx-auto text-center">
              <h1 className="display-1 text-bolder text-primary">Error 404</h1>
              <h2>Página não existe</h2>
              <p className="lead">Parece que a página que você está procurando não existe.</p>

              <a href={`${urlDomus}/app/api/v1/auth/authTokenSB?token=${accessToken}`}>
                <Button className="btn btn-primary text-white mt-4 fadeIn2 fadeInBottom">Voltar para a página inicial</Button>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default Page404;
