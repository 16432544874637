import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ReceiptService } from "../../../services/Commissioning/Receipt/ReceiptService";
import { ReceiptTypeService } from "../../../services/Commissioning/Receipt/ReceiptType/ReceiptTypeService";
import { ReceiptTypePeriodService } from "../../../services/Commissioning/Receipt/ReceiptTypePeriod/ReceiptTypePeriodService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { ToastSettings } from "../../../utils/ToastSettings";
import { ReactSelectOptions } from "../../../models/ReactSelectOptions";
import { ReceiptModel } from "../../../models/Commissioning/Receipt/ReceiptModel";
import { ReceiptTypePeriodModel } from "../../../models/Commissioning/Receipt/ReceiptTypePeriodModel";
import { ReceiptTypeModel } from "../../../models/Commissioning/Receipt/ReceiptTypeModel";
import moment from "moment";
import { ReceiptDetailedTotalsModels } from "../../../models/Commissioning/Receipt/ReceiptDetailedTotalsModels";
import { TotalsModel } from "../../../models/Commissioning/Receipt/TotalsModel";
import { PerTypeModel } from "../../../models/Commissioning/Receipt/PerTypeModel";

interface ContextProps {
    receipt?: ReceiptModel,
    setReceipt: Dispatch<SetStateAction<ReceiptModel | undefined>>,
    receiptLow?: ReceiptModel,
    setReceiptLow: Dispatch<SetStateAction<ReceiptModel | undefined>>,
    receipts: ReceiptModel[],
    receiptsType: ReceiptTypeModel[],
    setReceiptsType: Dispatch<SetStateAction<ReceiptTypeModel[]>>,
    receiptsTypePeriod: ReceiptTypePeriodModel[],
    totals: TotalsModel[],
    setTotals: Dispatch<SetStateAction<TotalsModel[]>>,
    totalsFilter: TotalsModel[],
    setTotalsFilter: Dispatch<SetStateAction<TotalsModel[]>>,
    detailedTotals: ReceiptDetailedTotalsModels[],
    perType: PerTypeModel[],
    setPerType: Dispatch<SetStateAction<PerTypeModel[]>>,
    financialEnterprise: any[];
    setFinancialEnterprise: Dispatch<SetStateAction<any[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsExcel: any,
    setParamsExcel: Dispatch<SetStateAction<any>>,
    paramsReceiptType: any,
    setParamsReceiptType: Dispatch<SetStateAction<any>>,
    paramsReceiptTypePeriod: any,
    setParamsReceiptTypePeriod: Dispatch<SetStateAction<any>>,
    paramsTotals: any,
    setParamsTotals: Dispatch<SetStateAction<any>>,
    paramsPerType: any,
    setParamsPerType: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingEdit: boolean,
    isLoadingFile: boolean,
    isLoadingType: boolean,
    isLoadingTypePeriod: boolean,
    isLoadingTotals: boolean,
    isLoadingPerType: boolean,
    error: string,
    isDataGeneral: boolean,
    setError: Dispatch<SetStateAction<string>>,
    setIsDataGeneral: Dispatch<SetStateAction<any>>,
    handleList: () => void,
    handleListTotals: () => Promise<any>,
    handleListPerType: () => Promise<any>,
    handleListTotalsFilter: (params: any) => Promise<any>,
    handleListDetailedTotals: (params: any) => Promise<any>,
    handleListReceiptType: () => Promise<any>,
    handleListReceiptTypePeriod: () => void,
    handleFetch: (data: ReceiptModel, action: 'create' | 'update') => Promise<boolean>,
    handleDelete: (id: number) => Promise<boolean>,
    handleGet: (id: number, action: 'insert' | 'low', today: string, businessDate: string) => Promise<boolean>,
    handleExcel: () => void,
    handleListFinancialEnterprise: (id: number) => any,
    handleAddEnterpriseReceipt: (data: any) => Promise<boolean>,
    handleSaveFiles: (data:any) => Promise<boolean>,
    receiptsTypeOptions: ReactSelectOptions[],
    receiptsTypePeriodOptions: ReactSelectOptions[]
}

export const ReceiptContext = createContext<ContextProps>({} as ContextProps);

interface ReceiptProviderProps {
    children: ReactNode;
}


export const ReceiptProvider = ({ children }: ReceiptProviderProps) => {
    const [receipt, setReceipt] = useState<ReceiptModel>();
    const [receiptLow, setReceiptLow] = useState<ReceiptModel>();
    const [receipts, setReceipts] = useState<ReceiptModel[]>([]);
    const [receiptsType, setReceiptsType] = useState<ReceiptTypeModel[]>([]);
    const [receiptsTypePeriod, setReceiptsTypePeriod] = useState<ReceiptTypePeriodModel[]>([]);
    const [totals, setTotals] = useState<TotalsModel[]>([]);
    const [perType, setPerType] = useState<PerTypeModel[]>([]);
    const [totalsFilter, setTotalsFilter] = useState<TotalsModel[]>([]);
    const [detailedTotals, setDetailedTotals] = useState<ReceiptDetailedTotalsModels[]>([]);
    const [financialEnterprise, setFinancialEnterprise] = useState<any[]>([]);
    const [paramsExcel, setParamsExcel] = useState<any>({
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        financials: null,
        receiptTypes: null,
        campaigns: null,
    });
    const [params, setParams] = useState<any>({ page: 1, });
    const [paramsReceiptType, setParamsReceiptType] = useState<any>();
    const [paramsReceiptTypePeriod, setParamsReceiptTypePeriod] = useState<any>();
    const [paramsTotals, setParamsTotals] = useState<any>();
    const [paramsPerType, setParamsPerType] = useState<any>();
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(true);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [isLoadingType, setIsLoadingType] = useState<boolean>(false);
    const [isLoadingTypePeriod, setIsLoadingTypePeriod] = useState<boolean>(false);
    const [isLoadingTotals, setIsLoadingTotals] = useState<boolean>(false);
    const [isLoadingPerType, setIsLoadingPerType] = useState<boolean>(false);
    const [isLoadingFinancialEnterprise, setIsLoadingFinancialEnterprise] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [receiptsTypeOptions, setReceiptsTypeOptions] = useState<ReactSelectOptions[]>([]);
    const [receiptsTypePeriodOptions, setReceiptsTypePeriodOptions] = useState<ReactSelectOptions[]>([]);
    const [isDataGeneral, setIsDataGeneral] = useState<boolean>(false);

    const service = new ReceiptService();
    const serviceReceiptType = new ReceiptTypeService()
    const serviceReceiptTypePeriod = new ReceiptTypePeriodService()
    const location = useLocation();

    const handleListReceiptType = useCallback(async () => {
        try {
            setIsLoadingType(true);
            const [_Response, _Error] = await serviceReceiptType.list(paramsReceiptType);
            setIsLoadingType(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setReceiptsType(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoadingType(false);
            setError('Não foi possível carregar os tipos de recebimentos do sistema.');
        }
    }, [paramsReceiptType]);


    const handleListReceiptTypePeriod = useCallback(async () => {
        try {
            setIsLoadingTypePeriod(true);
            const [_Response, _Error] = await serviceReceiptTypePeriod.list(paramsReceiptTypePeriod);
            setIsLoadingTypePeriod(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setReceiptsTypePeriod(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoadingTypePeriod(false);
            setError('Não foi possível carregar os tipos de recebimentos do sistema.');
        }
    }, [paramsReceiptTypePeriod]);


    const handleList = useCallback(async () => {
        if (!location.pathname.includes('financial/commissioning/receipt')) {
            return false;
        }

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setReceipts(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os recebimentos do sistema.');
        }
    }, [params, location.pathname]);


    const handleFetch = async (data: ReceiptModel, action: 'create' | 'update') => {
        try {
            const [_Response, _Error, _Code, _Errors] = action === 'create' ? await service.fetch(data) : await service.update(data);
            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setError('Houve um erro ao salvar o recebimento');
            return false;
        }
    }

    const handleDelete = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.delete(id);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o recebimento');
            return false;
        }
    }

    const handleAddEnterpriseReceipt = async (data: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.addEnterprise(data);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o recebimento');
            return false;
        }
    }

    const handleGet = async (id: number, action: 'insert' | 'low', today: string, businessDate: string) => {
        try {
            setIsLoadingEdit(true);
            const [_Response, _Error] = await service.get({ id, today, businessDate });
            setIsLoadingEdit(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            if (action === 'insert') {
                setReceipt(_Response.data);
            } else {
                setReceiptLow(_Response.data);
            }
            setError('');
            return true;
        } catch (e: any) {
            setIsLoadingEdit(false);
            setError(e.response?.data?.message || 'Houve um erro ao selecionar o recebimento');
            return false;
        }
    }

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(paramsExcel);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error ?? 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleListTotals = async () => {
        try {
            setIsLoadingTotals(true);
            const [_Response, _Error] = await service.totals(paramsTotals);
            setIsLoadingTotals(false);

            if (_Error) {
                setTotals([]);
                return setError(_Response?.message || _Error);
            }

            setTotals(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoadingTotals(false);
            setTotals([]);
            setError('Não foi possível carregar os tipos de recebimentos do sistema.');
        }
    };

    const handleListTotalsFilter = async (params: any) => {
        try {
            setIsLoadingTotals(true);
            const [_Response, _Error] = await service.totals(params);
            setIsLoadingTotals(false);

            if (_Error) {
                setTotalsFilter([]);
                return setError(_Response?.message || _Error);
            }

            setTotalsFilter(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoadingTotals(false);
            setTotalsFilter([]);
            setError('Não foi possível carregar os tipos de recebimentos do sistema.');
        }
    };

    const handleListDetailedTotals = async (params: any) => {
        try {
            setIsLoadingTotals(true);
            const [_Response, _Error] = await service.detailedTotals(params);
            setIsLoadingTotals(false);

            if (_Error) {
                setDetailedTotals([]);
                return setError(_Response?.message || _Error);
            }

            setDetailedTotals(_Response.data);
            return setError('');
        } catch (e) {
            setIsLoadingTotals(false);
            setDetailedTotals([]);
            setError('Não foi possível carregar os tipos de recebimentos do sistema.');
        }
    };

    const handleListPerType = async () => {
        try {
            setIsLoadingPerType(true);
            const [_Response, _Error] = await service.perType(paramsPerType);
            setIsLoadingPerType(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setPerType(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoadingPerType(false);
            setError('Não foi possível carregar os totais por tipos de recebimentos do sistema.');
        }
    };

    const handleListFinancialEnterprise = async (id: number) => {
        try {
            setIsLoadingFinancialEnterprise(true);
            const [_Response, _Error] = await service.financialEnterprise(id);
            setIsLoadingFinancialEnterprise(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            setFinancialEnterprise(_Response.data)
            return true;
        } catch (e: any) {
            setIsLoadingFinancialEnterprise(false);
            setError(e.response?.data?.message || 'Houve um erro ao deletar o recebimento');
            return false;
        }
    }

    const handleSaveFiles = async (data: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveFiles(data);
            setIsLoading(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e: any) {
            setIsLoading(false);
            setError(e.response?.data?.message || 'Houve um erro ao enviar o arquivo de recebimento');
            return false;
        }
    }

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        !!paramsTotals && handleListTotals().then()
    }, [paramsTotals])

    useEffect(() => {
        !!paramsPerType && handleListPerType().then()
    }, [paramsPerType])

    useEffect(() => {
        setReceiptsTypeOptions(
            receiptsType?.map((item: ReceiptTypeModel) => {
                return { label: item?.name, value: item?.id };
            })
        )
    }, [receiptsType]);

    useEffect(() => {
        setReceiptsTypePeriodOptions(
            receiptsTypePeriod?.map((item: ReceiptTypePeriodModel) => {
                return { label: item.name, value: item.id };
            })
        )
    }, [receiptsTypePeriod]);

    useEffect(() => {
        if (receipt) {
            setReceiptsType([{
                ...receipt?.receipts?.[0]?.receiptType,
                id: receipt?.receipts?.[0]?.campaignId ? receipt?.receipts?.[0]?.campaignId : receipt?.receipts?.[0]?.receiptTypeId,
                type: receipt?.receipts?.[0]?.campaignId ? 4 : receipt?.receipts?.[0]?.receiptTypeId,
                name: receipt?.receipts?.[0]?.campaignId ? receipt?.receipts?.[0]?.campaign?.description : receipt?.receipts?.[0]?.receiptType?.name
            }])
        }
    }, [receipt])

    return (
        <ReceiptContext.Provider value={useMemo(() => ({
            receipt,
            setReceipt,
            receiptLow,
            setReceiptLow,
            receipts,
            receiptsType,
            setReceiptsType,
            receiptsTypePeriod,
            totals,
            setTotals,
            totalsFilter,
            setTotalsFilter,
            detailedTotals,
            perType,
            setPerType,
            financialEnterprise,
            setFinancialEnterprise,
            params,
            setParams,
            paramsExcel,
            setParamsExcel,
            paramsReceiptType,
            setParamsReceiptType,
            paramsReceiptTypePeriod,
            setParamsReceiptTypePeriod,
            paramsTotals,
            setParamsTotals,
            paramsPerType,
            setParamsPerType,
            pages,
            setPages,
            isLoading,
            isLoadingEdit,
            isLoadingFile,
            isLoadingType,
            isLoadingTypePeriod,
            isLoadingTotals,
            isLoadingPerType,
            error,
            isDataGeneral,
            setError,
            setIsDataGeneral,
            handleList,
            handleListTotals,
            handleListTotalsFilter,
            handleListDetailedTotals,
            handleFetch,
            handleDelete,
            handleGet,
            handleExcel,
            handleListReceiptType,
            handleListReceiptTypePeriod,
            handleListPerType,
            handleListFinancialEnterprise,
            handleAddEnterpriseReceipt,
            handleSaveFiles,
            receiptsTypeOptions,
            receiptsTypePeriodOptions
        }), [
            receipt,
            setReceipt,
            receiptLow,
            setReceiptLow,
            receipts,
            receiptsType,
            setReceiptsType,
            receiptsTypePeriod,
            totals,
            setTotals,
            totalsFilter,
            setTotalsFilter,
            detailedTotals,
            perType,
            setPerType,
            financialEnterprise,
            setFinancialEnterprise,
            params,
            setParams,
            paramsExcel,
            setParamsExcel,
            paramsReceiptType,
            setParamsReceiptType,
            paramsReceiptTypePeriod,
            setParamsReceiptTypePeriod,
            paramsTotals,
            setParamsTotals,
            paramsPerType,
            setParamsPerType,
            pages,
            setPages,
            isLoading,
            isLoadingEdit,
            isLoadingFile,
            isLoadingType,
            isLoadingTypePeriod,
            isLoadingTotals,
            isLoadingPerType,
            error,
            isDataGeneral,
            setError,
            setIsDataGeneral,
            handleList,
            handleListTotals,
            handleListTotalsFilter,
            handleListDetailedTotals,
            handleFetch,
            handleDelete,
            handleGet,
            handleExcel,
            handleListReceiptType,
            handleListReceiptTypePeriod,
            handleListPerType,
            handleListFinancialEnterprise,
            handleAddEnterpriseReceipt,
            handleSaveFiles,
            receiptsTypeOptions,
            receiptsTypePeriodOptions
        ])}>
            {children}
        </ReceiptContext.Provider>
    );
}

export const useReceipt = () => useContext(ReceiptContext);