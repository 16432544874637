import React, { FC, useState } from 'react';
import './MonitoringPointsObservation.scss';
import PopoverHelp from '../../../Home/PopoverHelp/PopoverHelp';
import { BvIcon } from 'bevi-icon';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import moment from 'moment';

interface MonitoringPointsObservationProps { }

const MonitoringPointsObservation: FC<MonitoringPointsObservationProps> = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleGetExcelAnalytical } = useMonitoringCommercialPoints();

  const { value } = props;

  const Header = (): React.JSX.Element => {
    return (
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="mt-1">Observação</div>
        {/* @notes Regra inserida por conta do log iniciar somente nessa data (cadastros e visitas) */}
        {([8, 9, 12, 13, 15, 16].includes(value?.connectTypeId) && moment(value?.closingDate).format('YYYY-MM') >= moment('2024-10').format('YYYY-MM')) && (
          <div>
            <button className="btn btn-xs bg-success text-white rounded m-0 px-2" disabled={isLoading}
              onClick={(e) => { e.stopPropagation(); setIsLoading(true); handleGetExcelAnalytical(value?.id).then(() => setIsLoading(false)); }}>
              <i className="fas fa-file-excel me-1" /> Excel Analítico
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="MonitoringPointsObservation" data-testid="MonitoringPointsObservation">
      <div className="d-flex justify-content-center align-items-center text-wrap">
        <span>{value?.score?.toFixed(2)}</span>
        {value?.observation &&
          <PopoverHelp
            placement="right"
            header={Header()}
            body={
              <span dangerouslySetInnerHTML={{ __html: value?.observation }}>
              </span>
            }
          >
            <button type="button" id="btn-help"
              className="btn btn-sm text-violet-60 text-sm m-0 px-1 pt-1">
              <BvIcon name='help' className='text-violet-60' width={16} height={16} />
            </button>
          </PopoverHelp>}
      </div>
    </div>
  )
};

export default MonitoringPointsObservation;
