import { FC, useEffect, useMemo, useState } from "react";
import "./ProductionRangeValueExecutive.scss";
import { Card, Col, Row } from "react-bootstrap";
import FormatMoney from "../../../../../utils/FormatMoney";
import { useBiExecutive } from "../../../../../providers/BI/Dashboard/Executive/BiExecutiveProvider";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface ProductionRangeValueExecutiveProps { }

const ProductionRangeValueExecutive: FC<ProductionRangeValueExecutiveProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { executivesRangeValue, setExecutivesRangeValue } = useBiExecutive();
  const {
    params,
    handleListRangeProduction,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight,
  } = useBiDashboard();

  useEffect(() => {
    mountHideButtons();
    setRows(executivesRangeValue?.items ?? []);
  }, [executivesRangeValue]);

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListRangeProduction);
      setParams({
        ...params,
        group: {},
        view: BI_VIEWS_ENUM.EXECUTIVE,
      });
      if (!executivesRangeValue) {
        handleFetch();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setExecutivesRangeValue(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListRangeProduction({
      ...params,
      group: {},
      view: BI_VIEWS_ENUM.EXECUTIVE,
    });

    if (result) {
      setExecutivesRangeValue(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        "Produção em R$": item.range,
        Quantidade: item.partners,
        Total: item.production,
        Participacao: item.percentage,
      };

      _data.push(_row);
    });
    handleExcel(_data, "Producao por faixa de valores");
  };

  const mountColumns = useMemo(() => {
    if (executivesRangeValue?.items && executivesRangeValue.items.length > 0) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">
              Produção em R$
            </div>
          ),
          selector: (data: any) => data?.range,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.range}</div>
          ),
          sortable: true,
          width: "250px",
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">Quantidade</div>
          ),
          selector: (data: any) => data?.partners,
          sortable: true,
          center: true,
          omit: !hideButtons?.find((x: any) => x?.alias === "partners")?.show,
        },
        {
          name: <div className="bg-info text-table text-uppercase">Total</div>,
          selector: (data: any) => data?.production,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data?.production)}</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x?.alias === "production")?.show,
        },
        {
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data?.percentage,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data?.percentage, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x?.alias === "percentage")?.show,
        },
      ];

      setColumns(_columns);
    }
  }, [executivesRangeValue, hideButtons]);

  const mountHideButtons = () => {
    if (executivesRangeValue?.items && executivesRangeValue.items.length > 0) {
      const _buttons: any = [];

      _buttons.push({
        alias: `partners`,
        label: "Quantidade",
        show: true,
      });

      _buttons.push({
        alias: `production`,
        label: "Total",
        show: true,
      });

      _buttons.push({
        alias: `percentage`,
        label: "Participação",
        show: true,
      });

      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const handleSortValue = (columns: any, sortDirection: any, regionalRows: any, index: number) => {
    const sorted = handleSort(columns, sortDirection, regionalRows);
    const _rows: any[] = [...rows];
    _rows[index].periods = sorted;
    setRows(_rows);
  }

  const renderTable = (x: any, index: number) => {
    const _rows = x?.periods?.find((x: any) => x.range === 'TOTAL') ?
      x?.periods :
      [
        ...x?.periods,
        {
          ...x?.total,
          range: 'TOTAL',
        }
      ];

    return (
      <Card className="mt-4" key={x?.name}>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="text-start text-primary pb-1">{`${x?.name} - ${x?.partnerCode}`}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(rows);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <AppDataTable
            columns={columns}
            rows={_rows}
            pagination={false}
            handleOrder={(columns: any, sortDirection: any, rows: any) => handleSortValue(columns, sortDirection, rows, index)}
            conditionalRowStyles={[
              {
                when: (data: any) => data.range === 'TOTAL',
                style: biTotalRowStyle
              }
            ]}
            cellColor={biCellColor}
            cellFontSize={dataFontSize}
            maxHeight={tableHeight}
            display="flex"
          />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="ProductionRangeValueExecutive"
      data-testid="ProductionRangeValueExecutive"
    >
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
            </Row>
          </Col>

          {rows?.length > 0 ? (
            executivesRangeValue?.items?.map((item: any, index: number) => renderTable(item, index))
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default ProductionRangeValueExecutive;
