import { FC, useEffect, useMemo, useState } from "react";
import "./ProductionBranchSupervisor.scss";
import { Card, Col, Row } from "react-bootstrap";
import AppDataTable from "../../../../AppDataTable/AppDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useBiCovenant } from "../../../../../providers/BI/Dashboard/Covenant/BiCovenantProvider";
import FormatMoney from "../../../../../utils/FormatMoney";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";
import moment from "moment";

interface ProductionBranchSupervisorProps { }

const ProductionBranchSupervisor: FC<ProductionBranchSupervisorProps> = () => {
  const [columns, setColumns] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);

  const { covenantsBranchSupervisor, setCovenantsBranchSupervisor } =
    useBiCovenant();
  const {
    handleListMonthlyProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
    biCellColor,
    dataFontSize,
    tableHeight
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.REGIONAL,
        subview: BI_VIEWS_ENUM.BRANCH,
        group: {
          covenantgroup: true,
        },
      });
      if (!covenantsBranchSupervisor) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setCovenantsBranchSupervisor(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.REGIONAL,
      subview: BI_VIEWS_ENUM.BRANCH,
      group: {
        covenantgroup: true,
      },
    }).then();

    if (result) {
      setCovenantsBranchSupervisor(result);
    }
  };

  useEffect(() => {
    mountHideButtons();
  }, [covenantsBranchSupervisor]);

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (covenantsBranchSupervisor?.months && covenantsBranchSupervisor?.months?.length > 0) {
      periods = covenantsBranchSupervisor?.months?.map((x) => x);
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Convênios: item.name,
      };

      periods?.forEach((period: string, index: number) => {
        const month = moment(period, "YYYY-MM").format("MMMM")?.toUpperCase();
        _row[month] = item[period] ?? 0;
        if (index > 0) {
          _row["C/Q " + month] = item[period + "cq"] ?? 0;
        }
        _row["Participação " + month] = item[period + "participation"] ?? 0;
      });
      if (currentMonth) {
        _row["Projeção"] = item?.projection ?? 0;
        _row["Projeção C/Q"] = item?.projectionGrowth ?? 0;
      }
      _data.push(_row);
    });
    handleExcel(_data, "Producao mensal por comercial");
  };

  const hasData = (): boolean => {
    if (
      covenantsBranchSupervisor?.items &&
      covenantsBranchSupervisor?.items?.length > 0 &&
      covenantsBranchSupervisor?.items[0]?.group &&
      covenantsBranchSupervisor?.items[0]?.group?.length > 0 &&
      covenantsBranchSupervisor?.items[0]?.group[0]?.periods &&
      covenantsBranchSupervisor?.items[0]?.group[0]?.periods?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const mountColumns = useMemo(() => {
    if (hasData()) {
      setCurrentMonth(false);

      const currentMonthFilter = new Date().getMonth() + 1;

      covenantsBranchSupervisor?.months?.forEach(
        (item) => {
          const result = item?.split("-") || [0, 0];
          const isMonth = { month: Number(result[1]), year: Number(result[0]) };
          if (isMonth.month === currentMonthFilter) {
            setCurrentMonth(true);
          }
        }
      );

      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Convênios</div>
          ),
          selector: (data: any) => data.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
        },
      ];

      covenantsBranchSupervisor?.months?.forEach((item: string, index: number) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {moment(item, "YYYY-MM").format("MMMM")}
            </div>
          ),
          selector: (data: any) => data[item] ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">
              {FormatMoney(data[item ?? ""])}
            </div>
          ),
          sortable: true,
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          compact: true,
          omit: !hideButtons?.find((x: any) => x.alias === item)?.show,
        });

        if (index > 0) {
          _columns.push({
            name: <div className="bg-info text-table text-uppercase">C/Q</div>,
            selector: (data: any) => data[`${item}cq`] ?? 0,
            right: true,
            cell: (data: any) => (
              <div
                className={`${data[`${item}cq`] < 0 ? "bg-negative" : "bg-positive"
                  }`}
              >
                {FormatMoney(data[`${item}cq`], false)}%
                <FontAwesomeIcon
                  icon={data[`${item}cq`] < 0 ? faArrowDown : faArrowUp}
                  className="ms-2"
                />
              </div>
            ),
            sortable: true,
            omit:
              !hideButtons?.find((x: any) => x.alias === "cq")?.show ||
              !hideButtons?.find((x: any) => x.alias === item)?.show,
          });
        }

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data[`${item}participation`] ?? 0,
          right: true,
          cell: (data: any) => (
            <div>
              {FormatMoney(data[`${item}participation`], false)}%
            </div>
          ),
          sortable: true,
          compact: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "participation")
              ?.show ||
            !hideButtons?.find((x: any) => x.alias === item)?.show,
        });
      }
      );
      if (currentMonth) {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">Projeção</div>
          ),
          selector: (data: any) => data.projection ?? 0,
          right: true,
          cell: (data: any) => <div className="text-nowrap">{FormatMoney(data.projection)}</div>,
          sortable: true,
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          compact: true,
          omit: !hideButtons?.find((x: any) => x.alias === "projection")?.show,
        });

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Projeção C/Q
            </div>
          ),
          selector: (data: any) => data.projectionGrowth ?? 0,
          right: true,
          cell: (data: any) => (
            <div>{FormatMoney(data.projectionGrowth, false)}%</div>
          ),
          sortable: true,
          omit: !hideButtons?.find((x: any) => x.alias === "projectionGrowth")
            ?.show,
        });
      }
      setColumns(_columns);
    }
  }, [covenantsBranchSupervisor, hideButtons]);

  const mountRows = (items: any[]) => {
    const _rows: any = [];

    items?.forEach((item: any) => {
      const _row: any = {
        name: item?.name,
      };

      item?.periods?.forEach((period: any) => {
        _row[period.month ?? ""] = period.totalProduction;
        _row[`${period.month}cq`] = period.totalProductionGrowth;
        _row[`${period.month}participation`] = period.totalProductionPercentage;
      });

      if (item?.periods && item?.periods?.length > 0) {
        _row.projection =
          item?.periods[item?.periods?.length - 1]?.totalProductionProjection;
        _row.projectionGrowth =
          item?.periods[
            item?.periods?.length - 1
          ].totalProductionProjectionGrowth;
      }

      _rows.push(_row);
    });

    return _rows;
  };

  const mountHideButtons = () => {
    if (hasData()) {
      const _buttons: any = [];
      covenantsBranchSupervisor?.months?.forEach(
        (item) => {
          _buttons.push({
            alias: item,
            label: moment(item, "YYYY-MM").format("MMMM"),
            show: true,
          });
        }
      );

      _buttons.push({
        alias: `cq`,
        label: "Crescimento/Queda",
        show: true,
      });

      _buttons.push({
        alias: `participation`,
        label: "Participação",
        show: true,
      });
      if (currentMonth) {
        _buttons.push({
          alias: `projection`,
          label: "Projeção",
          show: true,
        });

        _buttons.push({
          alias: `projectionGrowth`,
          label: "Projeção C/Q",
          show: true,
        });
      }
      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  const renderTable = (item: any) => {
    return (
      <Card className="mt-4">
        <Card.Body>
          <div className="text-primary">
            {`${item?.name} - ${item?.partnerCode}`}{" "}
          </div>
          {item?.subView?.map((items: any) => {
            const rows = mountRows(items?.group);
            return (
              <Card className="mt-2">
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <span className="text-start text-info text-sm text-bolder">
                      {items?.name}
                    </span>
                    <button
                      className="btn bg-success text-white rounded m-0 ms-5"
                      onClick={() => {
                        handleExport(rows);
                      }}
                    >
                      <i className="fas fa-file-excel" /> Exportar
                    </button>
                  </div>
                  <div className="mt-3">
                    <AppDataTable
                      columns={columns}
                      rows={rows}
                      pagination={false}
                      cellColor={biCellColor}
                      cellFontSize={dataFontSize}
                      maxHeight={tableHeight}
                      display="flex"
                    />
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="ProductionBranchSupervisor"
      data-testid="ProductionBranchSupervisor"
    >
      {!isLoading ? (
        <>
          <Col md={12} className="d-flex justify-content-end">
            <Row className="display-button mb-3">
              <div className="d-flex justify-content-between align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  {hideButtons?.map((x: any, index: number) => (
                    <button
                      type="button"
                      className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                      onClick={() => onClickHideButton(x, index)}
                    >
                      <FontAwesomeIcon
                        icon={x.show ? faEye : faEyeSlash}
                        className="me-1"
                      />
                      {x.label}
                    </button>
                  ))}
                </div>
              </div>
            </Row>
          </Col>

          {covenantsBranchSupervisor?.items ? (
            covenantsBranchSupervisor?.items?.map((item: any) =>
              renderTable(item)
            )
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default ProductionBranchSupervisor;
