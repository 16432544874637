import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { LocalizationContext, LocalizationMap } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import pt_BR from './different-language/pt_BR.json';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ pdfUrl }: { pdfUrl: string }) => {
    const [l10n, setL10n] = React.useState(pt_BR as any as LocalizationMap);
    const localizationContext = { l10n, setL10n };

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const pageLayout = {
        transformSize: ({ size }: { size: { height: number, width: number } }) => ({
            height: size.height,
            width: size.width + 30,
        }),
    };

    return (
        <div style={{ height: '100%' }} data-testid="PDFViewer">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}>
                <LocalizationContext.Provider value={localizationContext}>
                    <Toolbar />

                    <Viewer
                        fileUrl={pdfUrl}
                        plugins={[toolbarPluginInstance]}
                        pageLayout={pageLayout}
                    />
                </LocalizationContext.Provider>
            </Worker>
        </div>
    );
};

export default PDFViewer;