import { FC, useEffect, useMemo, useState } from "react";
import "./AnnualProduction.scss";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import { useBiCommercial } from "../../../../../../providers/BI/Dashboard/BiCommercialProvider";
import FormatMoney from "../../../../../../utils/FormatMoney";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";
import { TabBiCommercialEnum } from "../ProductionByCommercial";

interface AnnualProductionProps {
  currentTab: TabBiCommercialEnum
}

const BUTTONS: any = {
  monthsAmount: "Produção Anual",
  annualProjectionAmount: "Projeção Anual",
  participation: "Participação",
  annualAverage: "Média D.U.",
};

const AnnualProduction: FC<AnnualProductionProps> = ({ currentTab }) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const { annualProduction, setAnnualProduction } = useBiCommercial();
  const {
    params,
    handleListAnnualProduction,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight
  } = useBiDashboard();

  useEffect(() => {
    mountHideButtons();
  }, [annualProduction]);

  useEffect(() => {
    if (currentTab === TabBiCommercialEnum.ANNUAL_PRODUCTION) {
      setHandleListOnFilter(() => handleListAnnualProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.COMMERCIAL,
      });
      if (!annualProduction) {
        handleFetch().then();
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (resultOnFilter) {
      setAnnualProduction(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.COMMERCIAL,
    }).then();

    if (result) {
      setAnnualProduction(result);
    }
  };

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [annualProduction]);

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Comercial: `${item?.name} - ${item?.partnerCode}`,
      };

      item?.periods?.forEach((period: any) => {
        _row["Producao anual"] = period.totalProduction;
        _row["Producao anual D.U"] = period.totalProductionProjection;
        _row["Participacao"] = period.totalProductionPercentage;
        _row["Media D.U"] = period.totalProductionByWorkdays;
      });
      _data.push(_row);
    });
    handleExcel(_data, "Producao anual por comercial ");
  };

  const mountColumns = useMemo(() => {
    if (annualProduction?.items && annualProduction?.items?.length > 0) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">Comercial</div>
          ),
          selector: (data: any) => data?.name,
          sortable: true,
          width: "250px",
          cell: (data: any) => (
            data?.name !== 'TOTAL' ?
              <div
                className="text-wrap text-bold"
                style={{
                  color:
                    data.active === "I"
                      ? "rgb(247, 179, 21)"
                      : data.status === "D"
                        ? "rgb(243, 41, 14)"
                        : "",
                }}
              >
                {`${data?.name} - ${data?.partnerCode}`}
              </div>
              :
              <div>{data?.name}</div>
          ),
        },
      ];

      annualProduction?.items[0].periods?.forEach(
        (item: any, index: number) => {
          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Produção Anual
              </div>
            ),
            selector: (data: any) => data?.periods[index]?.totalProduction,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProduction)}
              </div>
            ),
            sortable: true,
            omit: !hideButtons?.find((x: any) => x.alias === "monthsAmount")
              ?.show,
            right: true,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Projeção Anual D.U
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionProjection,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionProjection)}
              </div>
            ),
            sortable: true,
            omit: !hideButtons?.find(
              (x: any) => x.alias === "annualProjectionAmount"
            )?.show,
            right: true,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                {" "}
                Participação{" "}
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionPercentage,
            cell: (data: any) => (
              <div>
                {FormatMoney(
                  data?.periods[index]?.totalProductionPercentage,
                  false
                )}
                %
              </div>
            ),
            sortable: true,
            right: true,
            compact: true,
            omit: !hideButtons?.find((x: any) => x.alias === "participation")
              ?.show,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">Média D.U</div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionByWorkdays,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionByWorkdays)}
              </div>
            ),
            sortable: true,
            right: true,
            omit: !hideButtons?.find((x: any) => x.alias === "annualAverage")
              ?.show,
          });
        }
      );

      setColumns(_columns);
    }
  }, [annualProduction, hideButtons]);

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const mountRows = () => {
    if (annualProduction) {
      const _rows: any[] = [];
      annualProduction.items?.forEach((item) => {
        if (item.periods && item.periods.length > 0) {
          _rows.push({
            ...item,
            ...item.periods[0],
          });
        }
      });
      setRows([
        ..._rows,
        {
          name: 'TOTAL',
          periods: [{ ...annualProduction?.total }]
        }
      ]);
    }
  };

  const mountHideButtons = () => {
    if (annualProduction) {
      const _buttons: any = [];

      Object.keys(BUTTONS).forEach((key) => {
        _buttons.push({
          alias: key,
          label: BUTTONS[key],
          show: true,
        });
      });

      setHideButtons(_buttons);
    }
  };

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  return (
    <div className="AnnualProduction" data-testid="AnnualProduction">
      {!isLoading ? (
        <>
          {rows?.length > 0 ? (
            <>
              <Col md={12} className="d-flex justify-content-end">
                <Row className="display-button mb-3">
                  <div className="d-flex justify-content-between align-items-center flex-md-row">
                    <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                      {hideButtons?.map((x: any, index: number) => (
                        <button
                          type="button"
                          className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                          onClick={() => onClickHideButton(x, index)}
                        >
                          <FontAwesomeIcon
                            icon={x.show ? faEye : faEyeSlash}
                            className="me-1"
                          />
                          {x.label}
                        </button>
                      ))}
                      <button
                        className="btn bg-success text-white rounded m-0 ms-2"
                        onClick={() => {
                          handleExport(rows);
                        }}
                      >
                        <i className="fas fa-file-excel" /> Exportar
                      </button>
                    </div>
                  </div>
                </Row>
              </Col>

              <Row>
                <Col className="text-sm text-bolder d-flex gap-3">
                  <div className="d-flex align-items-center gap-1">
                    <div className="inactive"></div>
                    <span>Inativo</span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <div className="unsubscribed"></div>
                    <span>Descadastrado</span>
                  </div>
                </Col>
              </Row>

              <AppDataTable
                columns={columns}
                rows={rows}
                pagination={false}
                handleOrder={handleSortValue}
                conditionalRowStyles={[
                  {
                    when: (data: any) => data.name === 'TOTAL',
                    style: biTotalRowStyle
                  }
                ]}
                cellColor={biCellColor}
                cellFontSize={dataFontSize}
                maxHeight={tableHeight}
                display="flex"
              />
            </>
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default AnnualProduction;
