import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PartnerRegistrationUpdateService } from "../../../services/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateService";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { PartnerRegistrationUpdateModel } from "../../../models/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateModel";
import { ToastSettings } from "../../../utils/ToastSettings";
import moment from "moment";

interface ContextProps {
    partnerUpdate?: PartnerRegistrationUpdateModel,
    setPartnerUpdate: Dispatch<SetStateAction<PartnerRegistrationUpdateModel | undefined>>,
    partnerUpdates: PartnerRegistrationUpdateModel[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsExcel: any,
    setParamsExcel: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingFile: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void
    handleExcel: () => void,
    handleExcelSynthetic: () => void,
    handleExcelAnalytical: () => void,
}

export const PartnerRegistrationUpdateContext = createContext<ContextProps>({} as ContextProps);

interface PartnerRegistrationUpdateProviderProps {
    children: ReactNode;
}

export const PartnerRegistrationUpdateProvider = ({ children }: PartnerRegistrationUpdateProviderProps) => {
    const [partnerUpdate, setPartnerUpdate] = useState<PartnerRegistrationUpdateModel>();
    const [partnerUpdates, setPartnerUpdates] = useState<PartnerRegistrationUpdateModel[]>([]);
    const [params, setParams] = useState<any>({
        page: 1,
        startDate: moment(new Date()).format('YYYY-MM-01 HH:mm:ss'),
        endDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    });
    const [paramsExcel, setParamsExcel] = useState<any>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const service = new PartnerRegistrationUpdateService();

    const handleList = useCallback(async () => {
        try {
            params.startDate = params?.startDate ? moment(params?.startDate).format('YYYY-MM-DD') : '';
            params.endDate = params?.endDate ? moment(params?.endDate).format('YYYY-MM-DD') : '';

            setIsLoading(true);
            const [_Response, _Error] = await service.list(params);
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setPartnerUpdates(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as atualizações cadastrais do sistema.');
        }
    }, [params]);

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const { page, ...paramsExcel } = params;

        const [_Response, _Error] = await service.export(paramsExcel);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error ?? 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleExcelSynthetic = async () => {
        setError('');
        setIsLoadingFile(true);

        const { page, ...paramsExcel } = params;

        const [_Response, _Error] = await service.exportSynthetic(paramsExcel);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error ?? 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleExcelAnalytical = async () => {
        setError('');
        setIsLoadingFile(true);

        const { page, ...paramsExcel } = params;

        const [_Response, _Error] = await service.exportAnalytical(paramsExcel);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error ?? 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <PartnerRegistrationUpdateContext.Provider value={{
            partnerUpdate,
            setPartnerUpdate,
            partnerUpdates,
            params,
            setParams,
            paramsExcel,
            setParamsExcel,
            pages,
            setPages,
            isLoading,
            isLoadingFile,
            error,
            setError,
            handleList,
            handleExcel,
            handleExcelSynthetic,
            handleExcelAnalytical
        }}>
            {children}
        </PartnerRegistrationUpdateContext.Provider>
    );
}

export const usePartnerRegistrationUpdate = () => useContext(PartnerRegistrationUpdateContext);