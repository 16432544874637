import { FC, useState } from 'react';
import './PartnerRegistrationUpdate.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import { usePaginate } from '../../../providers/PaginateProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ModalPartnerRegistrationUpdate from '../../../components/Registrations/PartnerUpdate/ModalPartnerRegistrationUpdateInsert/ModalPartnerRegistrationUpdate';
import SidebarPartnerRegistrationUpdateFilter from '../../../components/Registrations/PartnerUpdate/SidebarPartnerRegistrationUpdateFilter/SidebarPartnerRegistrationUpdateFilter';
import { usePartnerRegistrationUpdate } from '../../../providers/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateProvider';
import { PartnerRegistrationUpdateModel, pendingDocumentModel } from '../../../models/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdateModel';
import { dateFormat } from '../../../utils/FormatDate';
import PopoverHelp from '../../../components/Home/PopoverHelp/PopoverHelp';
import ExportButtons from '../../../components/ExportButtons/ExportButtons';

interface PartnerRegistrationUpdateProps { }

const PartnerRegistrationUpdate: FC<PartnerRegistrationUpdateProps> = () => {
  const [showModalPartnerUpdate, setShowModalPartnerUpdate] = useState<boolean>(false);

  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { partnerUpdates, setPartnerUpdate, isLoading, params, setParams, pages, error, handleExcel, isLoadingFile, handleExcelSynthetic, handleExcelAnalytical } = usePartnerRegistrationUpdate();
  const { handlePaginate } = usePaginate();

  const handleViewPartnerRegistrationUpdate = (x: PartnerRegistrationUpdateModel) => {
    setPartnerUpdate(x);
    setShowModalPartnerUpdate(true);
  }

  const changesCountLog = (log: any) => {
    let count = 0;

    for (let key in log) {
      const obj = log[key];
      if (Array.isArray(obj?.after)) {
        if (obj?.after?.length > 0) {
          count += obj?.after?.length;
        }
      } else {
        if (obj?.after !== undefined && obj?.after !== null) {
          count += 1;
        }
      }
    }
    return count;
  };


  const renderList = (): JSX.Element => {
    return (
      <>
        {partnerUpdates?.length > 0 ? (
          <>
            {partnerUpdates?.map((x: PartnerRegistrationUpdateModel, key: number) => (
              <tr key={key} className="text-sm">
                <td>
                  {x?.user?.partnerCode &&
                    <span className='mb-0'>
                      {`${x?.user?.partnerCode} - ${x?.user?.name}`}
                    </span>
                  }
                  <br />
                  {x?.user?.active?.id &&
                    <span
                      className={`badge ${x?.user?.active?.id == 1 ? 'badge badge-success' : (x?.user?.active?.id == 2 ? 'badge-danger' : 'badge-primary')}`}>
                      {x?.user?.active?.name}
                    </span>
                  }

                </td>
                <td style={{ width: '320px' }}>
                  {x?.user?.active?.id == 2 && x?.user?.userInactiveCategory && (
                    <p style={{ lineHeight: '20px' }}>
                      <span className='text-danger font-weight-lighter mb-0' style={{ fontSize: '1rem' }}> <b> Classificação: </b> {`${x.user.userInactiveCategory?.name}`} </span> <br />
                      <span className='text-dark font-weight-lighter' style={{ fontSize: '1rem' }}> <b> Motivo: </b> {`${x.user.userInactiveCategory?.observation}`} </span>
                    </p>
                  )}
                </td>
                <td>
                  {
                    x?.user?.pendingDocument && (
                      <>
                        <PopoverHelp
                          placement="bottom"
                          header={<>Documentos Pendentes </>}
                          body={<>
                            {
                              x?.user?.pendingDocument && x?.user?.pendingDocument?.map((image: pendingDocumentModel) => (
                                <label className='mb-1 font-weight-light'> {image?.documentTypeName} </label>
                              ))
                            }
                          </>}
                        >
                          <span className='badge badge-danger'> SIM </span>
                        </PopoverHelp>
                      </>
                    )
                  }
                </td>
                <td>{dateFormat({ date: x?.updatedAt, datetime: true })}</td>
                <td>{x?.userUpdated?.partnerCode && `${x?.userUpdated?.partnerCode} - ${x?.userUpdated?.name}`}</td>
                <td className="text-center">{changesCountLog(x?.log)}</td>
                <td className="text-center">
                  <span className={`badge ${x?.finalized ? 'badge-success' : 'badge-danger'}`}>
                    {x?.finalized ? 'Finalizado' : 'Não finalizado'}
                  </span>
                </td>
                <td className="text-center">
                  <DropdownMenuTable key={key}>
                    <Dropdown.Item className="border-radius-md py-2" onClick={() => handleViewPartnerRegistrationUpdate(x)}>
                      <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar Detalhes
                    </Dropdown.Item>
                  </DropdownMenuTable>
                </td>
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={8} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div>
            <div>
              <div className="d-flex">
                <div className="ms-auto my-auto mt-lg-0 mt-3">
                  <div className="ms-auto my-auto">
                    <ExportButtons
                      getExcel={handleExcel}
                      getExcelAddtional={handleExcelSynthetic}
                      isLoading={isLoadingFile}
                    >
                      <>
                        <button className="btn bg-success text-white rounded" disabled={isLoadingFile}
                          onClick={handleExcelAnalytical}>
                          <i className="fas fa-file-excel me-1" /> Excel Analítico
                        </button>
                      </>
                    </ExportButtons>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="table-responsive mt-3">
                <table className="table table-flush m-0 w-100">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-uppercase opacity-7" scope="col">Parceiro</th>
                      <th className="text-uppercase opacity-7" scope="col">Categoria de<br /> Inativação </th>
                      <th className="text-uppercase opacity-7" scope="col">Pendente o envio de<br /> documentação </th>
                      <th className="text-uppercase opacity-7" scope="col">Data<br />Atualização </th>
                      <th className="text-uppercase opacity-7" scope="col">Comercial</th>
                      <th className="text-uppercase opacity-7 text-center" scope="col">Quantidade de dados<br /> Alterados</th>
                      <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                      <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderList()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {partnerUpdates?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  }

  return (
    <MasterPage
      title="Atualização Cadastral"
      icon="fas fa-file"
      footerFixed
      footerInverse
    >
      <div className="PartnerRegistrationUpdate" data-testid="PartnerRegistrationUpdate">
        <PageBase
          title="Atualização Cadastral"
          subtitle="Gerenciamento de atualizações cadastrais de agentes."
          handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
          error={error}
          content={renderContent()}
          hasFilter
          isLoadingFile={isLoadingFile}
        />

        <ModalPartnerRegistrationUpdate
          show={showModalPartnerUpdate}
          onClose={() => setShowModalPartnerUpdate(false)}
        />
      </div>

      <SidebarPartnerRegistrationUpdateFilter />
    </MasterPage>
  );
}

export default PartnerRegistrationUpdate;
