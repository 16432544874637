import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { VisitsService } from "../../services/Visits/VisitsService";
import { ToastSettings } from "../../utils/ToastSettings";
import { ReactSelectOptions } from "../../models/ReactSelectOptions";
import { VisitModel } from "../../models/Visits/IndicatorManagement/VisitModel";
import { useIndicatorManagementWalletVisits } from "./IndicatorManagement/IndicatorManagementWalletProvider";
import { useReportRequest } from "../Reports/ReportRequest/ReportRequestProvider";
import { useLocation } from "react-router-dom";

interface ContextProps {
    visits: any[],
    contactsTypeOptions: ReactSelectOptions[],
    attendanceOptions: ReactSelectOptions[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    pagesQuantity: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingSave: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => Promise<any>,
    handleSave: (payload: VisitModel) => Promise<boolean>,
    handleDownload: (id: number) => Promise<boolean>,
    getContactsTypes: () => Promise<any>,
    getAttendance: () => Promise<any>,
    handleExcel: () => void,
    handleListQuantity: () => Promise<any>,
    visitsQtd: any[],
}

export const VisitsContext = createContext<ContextProps>({} as ContextProps);

interface VisitsProviderProps {
    children: ReactNode;
}

export const VisitsProvider = ({ children }: VisitsProviderProps) => {
    const [visits, setVisits] = useState<any[]>([]);
    const [visitsQtd, setVisitsQtd] = useState<any[]>([]);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [pagesQuantity, setPagesQuantity] = useState<PagesPaginateModel>({});
    const [contactsTypeOptions, setContactsTypeOptions] = useState<ReactSelectOptions[]>([]);
    const [attendanceOptions, setAttendanceOptions] = useState<ReactSelectOptions[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any>(null);

    const { params: paramsWallet } = useIndicatorManagementWalletVisits();
    const { setIsLoadingFile } = useReportRequest();
    const location = useLocation();

    const service = new VisitsService();

    useEffect(() => {
        location.pathname !== '/app-visits/report-visit/list' && setParams(paramsWallet);
    }, [paramsWallet]);

    const handleList = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({ ...params, withPaginate: true });
            setIsLoading(false);

            if (_Error) {
                setVisits([]);
                return setError(_Response?.message || _Error);
            }

            setVisits(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setVisits([]);
            setIsLoading(false);
            setError('Não foi possível carregar as visitas.');
        }
    };

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleSave = async (payload: VisitModel) => {
        try {
            setIsLoadingSave(true);
            const [_Response, _Error] = await service.save(payload);
            setIsLoadingSave(false);

            if (_Error) {
                setError(_Response?.message || _Error);
                return false;
            }

            setError('');
            return true;
        } catch (e) {
            setIsLoadingSave(false);
            setError('Não foi possível salvar a visita!');
            return false;
        }
    };

    const handleDownload = async (id: number) => {
        try {
            const [_Response, _Error, _Code, _Errors] = await service.download(id);

            if (_Error) {
                ToastSettings(_Response?.message || _Error, 'bottom-center', 'error', () => { }, _Errors);
                return false;
            }

            setError('');

            ToastSettings('Arquivo baixado com sucesso!', 'bottom-center', 'success');

            return true;
        } catch (e) {
            setError('Houve um erro ao baixar o arquivo');
            return false;
        }
    }

    const getContactsTypes = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getContactsType();
            setIsLoading(false);

            if (_Error) {
                setContactsTypeOptions([]);
                return setError(_Response?.message || _Error);
            }

            setContactsTypeOptions(_Response?.data?.map((item: any) => {
                return { value: item.id, label: item.description }
            }));
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setContactsTypeOptions([]);
            setError('Não foi possível carregar os tipos de contato.');
        }
    }

    const getAttendance = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getAttendance();
            setIsLoading(false);

            if (_Error) {
                setAttendanceOptions([]);
                return setError(_Response?.message || _Error);
            }

            setAttendanceOptions(_Response?.data?.map((item: any) => {
                return { value: item.id, label: item.name }
            }));
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setAttendanceOptions([]);
            setError('Não foi possível carregar os tipos de contato.');
        }
    }

    const handleListQuantity = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listQuantity({ ...params, withPaginate: true });
            setIsLoading(false);

            if (_Error) {
                return setError(_Response?.message || _Error);
            }

            setVisitsQtd(_Response.data);
            setPagesQuantity(_Response?.pagesQuantity);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar as quantidades de visitas.');
        }
    };

    useEffect(() => {
        if (error) {
            toast.error(error);
            setError('');
        }
    }, [error]);

    return (
        <VisitsContext.Provider value={useMemo(() => ({
            visits,
            contactsTypeOptions,
            attendanceOptions,
            params,
            setParams,
            pages,
            pagesQuantity,
            setPages,
            isLoading,
            isLoadingSave,
            error,
            setError,
            handleList,
            handleSave,
            handleDownload,
            getContactsTypes,
            getAttendance,
            handleExcel,
            handleListQuantity,
            visitsQtd
        }), [
            visits,
            contactsTypeOptions,
            attendanceOptions,
            params,
            setParams,
            pages,
            pagesQuantity,
            setPages,
            isLoading,
            isLoadingSave,
            error,
            setError,
            handleList,
            handleSave,
            handleDownload,
            getContactsTypes,
            getAttendance,
            handleExcel,
            handleListQuantity,
            visitsQtd
        ])}>
            {children}
        </VisitsContext.Provider>
    );
}

export const useVisits = () => useContext(VisitsContext);