import { FC, useEffect, useState } from 'react';
import './SidebarTermsTrackingFilter.scss';
import SidebarFilter from '../../../SidebarFilter/SidebarFilter';
import { Controller, useForm } from 'react-hook-form';
import { Col, FormGroup, Row } from 'react-bootstrap';

import InputSearchPartner from '../../../Registrations/Partner/InputSearchPartner/InputSearchPartner';
import ReactSelect from 'react-select';
import { customStyles } from '../../../../models/SelectCustomStyles';
import makeAnimated from 'react-select/animated';
import FormatCpfCnpj from '../../../../utils/FormatCpfCnpj';
import { useTermsTracking } from '../../../../providers/Terms/TermsTrackingProvider';
import { useCompany } from '../../../../providers/Registrations/Company/CompanyProvider';
import { useUserDependent } from '../../../../providers/User/UserDependentProvider';
import InputDateRange from '../../../InputDateRange/InputDateRange';
import moment from 'moment';

interface SidebarTermsTrackingFilterProps { }

// @ts-ignore
export const defaultValues = {
  sectorId: null,
  departmentId: null,
  userId: '',
  dependentId: null,
  cpf: '',
  termsId: '',
  statusTermId: null,
  promoterId: null,
  startDate: '',
  endDate: '',
} as any;

const SidebarTermsTrackingFilter: FC<SidebarTermsTrackingFilterProps> = () => {
  const [selectedSector, setSelectedSector] = useState<any>();
  const [userId, setUserId] = useState<string>('');
  const [partnerObj, setPartnerObj] = useState<any>();
  const [selectedDependent, setSelectedDepedent] = useState<any>();
  const [cpf, setCpf] = useState<any>('');
  const [termsId, setTermsId] = useState<any>('');
  const [selectedStatusTermId, setSelectedStatusTermId] = useState<any>();
  const [selectedPromoterId, setSelectedPromoterId] = useState<any>();
  const { status, departments } = useTermsTracking();
  const { companysOptions } = useCompany();
  const { handleList, userDependent, setUserDependent } = useUserDependent();
  const { params, setParams } = useTermsTracking();
  const [startDate, setStartDate] = useState<any>(moment(new Date()).format('YYYY-MM-01 HH:mm:ss'));
  const [endDate, setEndDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));

  const animatedComponents = makeAnimated();

  const { handleSubmit, setValue, control } = useForm<any>({ defaultValues });

  useEffect(() => {
    setValue('sectorId', selectedSector)
  }, [selectedSector]);

  useEffect(() => {
    setValue('dependentId', selectedDependent)
  }, [selectedDependent]);


  useEffect(() => {
    setValue('cpf', cpf)
  }, [cpf]);

  useEffect(() => {
    setValue('termsId', termsId)
  }, [termsId]);

  useEffect(() => {
    setValue('statusTermId', selectedStatusTermId)
  }, [selectedStatusTermId]);

  useEffect(() => {
    setValue('promoterId', selectedPromoterId)
  }, [selectedPromoterId]);

  useEffect(() => {
    if (partnerObj) {
      handleList(partnerObj.id).then();
    }
    else {
      setUserDependent([]);
    }
  }, [partnerObj])

  const onSubmit = async (data: any) => {

    setParams({ ...params, ...{
      departmentId: data?.sectorId ? data?.sectorId?.value : null,
      userId: data?.userId ? data?.userId?.value : '',
      dependentId: data?.dependentId ? data?.dependentId?.value : null,
      cpf: data?.cpf ? data?.cpf : '',
      termId: data?.termsId ? parseInt(data?.termsId) : '',
      statusId: data?.statusTermId ? data?.statusTermId?.value : null,
      companyId: data?.promoterId ? data?.promoterId?.value : null,
      startDate: data?.startDate ? moment(data?.startDate).format('YYYY-MM-DD') : null,
      endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : null,
    }, ...{ page: 1, withPaginate: true } });
  }

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div className="SidebarTermsTrackingFilter" data-testid="SidebarTermsTrackingFilter">
        <Row className="mt-1">
          <Col md={12} xs={12}>
            <FormGroup className="form-group mb-0">
              <InputDateRange
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setValue={setValue}
                  startLabelText={'DATA INICIAL'}
                  endLabelText={'DATA FINAL'}
              />
            </FormGroup>
          </Col>
          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="sectorId">DEPARTAMENTO</label>
              {control && (
                <Controller
                  name="sectorId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      value={selectedSector}
                      isClearable
                      isSearchable
                      options={departments}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      onChange={(val: any) => {
                        onChange(val?.value ?? null);
                        setSelectedSector(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="form-group">
              <label htmlFor="userId">PARCEIRO</label>
              <InputSearchPartner
                name="userId"
                control={control}
                setValue={setValue}
                valuePartner={userId}
                setValuePartner={setUserId}
                setPartnerObj={setPartnerObj}
              />
            </FormGroup>
          </Col>

          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="dependentId">DEPENDENTE</label>
              {control && (
                <Controller
                  name="dependentId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      value={selectedDependent || []}
                      isClearable
                      isSearchable
                      options={userDependent}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      onChange={(val: any) => {
                        onChange(val?.value ?? null);
                        setSelectedDepedent(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="cpf">CPF</label>
              {control && (
                <Controller
                  name="cpf"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <input
                      ref={ref}
                      name={name}
                      value={FormatCpfCnpj(cpf)}
                      maxLength={14}
                      placeholder="Digite o CPF"
                      className={`form-control`}
                      onChange={(e: any) => {
                        onChange(e?.target?.value.replace(/[.-]/g, '') ?? '');
                        setCpf(e?.target?.value.replace(/[.-]/g, ''));
                      }}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="termsId">ID TERMO</label>
              {control && (
                <Controller
                  name="termsId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <input
                      ref={ref}
                      name={name}
                      value={termsId}
                      placeholder="Digite o ID do Termo"
                      className={`form-control`}
                      onChange={(e: any) => {
                        onChange(e?.target?.value ?? '');
                        setTermsId(e?.target?.value);
                      }}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="statusTermId">STATUS</label>
              {control && (
                <Controller
                  name="statusTermId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      value={selectedStatusTermId}
                      isClearable
                      isSearchable
                      options={status}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      onChange={(val: any) => {
                        onChange(val?.value ?? null);
                        setSelectedStatusTermId(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

          <Col xl={12}>
            <FormGroup className="form-group">
              <label htmlFor="promoterId">PROMOTORA</label>
              {control && (
                <Controller
                  name="promoterId"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <ReactSelect
                      ref={ref}
                      name={name}
                      value={selectedPromoterId}
                      isClearable
                      isSearchable
                      options={companysOptions}
                      components={animatedComponents}
                      noOptionsMessage={() => 'Não há registros'}
                      placeholder="Selecione..."
                      className={`form-control p-0`}
                      onChange={(val: any) => {
                        onChange(val?.value ?? null);
                        setSelectedPromoterId(val);
                      }}
                      styles={customStyles}
                    />
                  )}
                />
              )}
            </FormGroup>
          </Col>

        </Row>
      </div>
    </SidebarFilter>
  );
}

export default SidebarTermsTrackingFilter;
