import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required(), 
  hasExclusive: yup.mixed(),
  termDocumentDepartmentId: yup.mixed().required(), 
  htmlContent: yup.string().required(),
  termsTagsColumnsTablesIds: yup.mixed(),
  enterprise: yup.mixed(),
  withWitnesses: yup.mixed().required(),
  blockAccess: yup.mixed().required(), 
  releasePartnerBusiness: yup.mixed().required(), 
  releasePartnerEmployee: yup.mixed().required(),
  releaseMasterSignature: yup.mixed().required(),
  justAccept: yup.mixed().required(),
  withLogo: yup.mixed().required(),
  automaticShipping: yup.mixed().required(),
  visiblePartner: yup.mixed().required(),
  termDocumentSignatureChannel: yup.mixed().required(), 
  enterprises: yup.mixed().required(),
  userId: yup.mixed(),
  suppliers: yup.mixed().required(),
  reasonAction: yup.mixed().required(),
});
