import { FC} from 'react';
import './ModalAccessCheck.scss';
import { toast } from 'react-toastify';
import { FormGroup } from 'react-bootstrap';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import { useOpportunity } from '../../../../providers/Marketplace/Opportunities/OpportunityProvider';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface ModalAccessCheckProps {
  show: boolean,
  onClose: any
}

const ModalAccessCheck: FC<ModalAccessCheckProps> = ({onClose, show}) => {
  
  const {handleAccessCheck} = useOpportunity();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  
  const { handleSubmit, register, setValue, reset, formState: { errors } } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (data: any) => {
    const psw = await handleAccessCheck({report_password: data.password});
    
    if(psw.data.access){
      toast.success('Senha enviada com sucesso!');
      onClose();
    }else{
      toast.error('Acesso negado, Digite novamente.');
    }
  }

  const renderContent = (): JSX.Element => {
    return (
      <>
        <FormGroup className="form-group">
          <input
            className="form-control"
            {...register('password')}
          />
        </FormGroup>
      </>
    );
  }

  return (
    <ModalDefault
      sizeModal = 'md'
      title={`Digite a senha`}
      show={show}
      showCloseButton={false}
      handleSubmit={handleSubmit(onSubmit)}
      buttonText='Confirmar'
    >
      <div className="ModalAccessCheck" data-testid="ModalAccessCheck">
      {renderContent()}
      </div>
    </ModalDefault>
  );
}

export default ModalAccessCheck;
