import { FC, useCallback, useEffect } from 'react';
import './PageBase.scss';
import ExportButtons from "../ExportButtons/ExportButtons";
import { Card, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useReportRequest } from '../../providers/Reports/ReportRequest/ReportRequestProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useSidebarFilter } from '../../providers/SidebarFilterProvider';

interface PageBaseProps {
    title?: string;
    subtitle?: string;
    content: JSX.Element;
    textButtonAdd?: string;
    textButtonFilter?: string;
    textButtonAddtional?: string;
    textButtonAddtionalExtra?: string;
    textButtonAddtionalExtra2?: string;
    showAddButton?: boolean;
    showAddtionalButton?: boolean;
    showAddtionalButtonExtra?: boolean;
    showAddtionalButtonExtra2?: boolean;
    hasFilter?: boolean;
    hasExcelPdf?: boolean;
    handleAddtionalMenu?: any;
    handleFilter?: any;
    handleExcel?: any;
    handlePdf?: any;
    handleInsert?: any;
    handleAddtional?: any;
    handleAddtionalExcel?: any;
    handleAddtionalExtra?: any;
    handleAddtionalExtra2?: any;
    hasPagination?: boolean;
    isLoadingFile?: boolean;
    error?: string;
    showCardHeader?: boolean;
    contentButtonLine?: any;
}

const PageBase: FC<PageBaseProps> = ({
    title,
    subtitle,
    content,
    textButtonAdd = "Adicionar",
    textButtonFilter = "Filtros",
    textButtonAddtional = "",
    textButtonAddtionalExtra = "",
    textButtonAddtionalExtra2 = "",
    hasFilter,
    handleAddtionalMenu,
    hasExcelPdf,
    handleFilter,
    handleExcel,
    handlePdf,
    handleInsert,
    showAddButton = !!handleInsert,
    handleAddtional,
    showAddtionalButton,
    handleAddtionalExtra,
    showAddtionalButtonExtra,
    handleAddtionalExtra2,
    showAddtionalButtonExtra2,
    handleAddtionalExcel,
    hasPagination = true,
    isLoadingFile = false,
    error = '',
    showCardHeader = true,
    contentButtonLine = false,
}) => {
    const { setShowSidebar } = useReportRequest();
    const { setShowSidebarFilter } = useSidebarFilter();

    useCallback(async () => {
        error && await Swal.fire('Ops!', error, 'error');
    }, [error]);

    useEffect(() => {
        isLoadingFile && setShowSidebar(true);
    }, [isLoadingFile]);

    useEffect(() => {
        setShowSidebarFilter(true);
    }, []);

    return (
        <div className="PageBase" data-testid="PageBase">
            <Row>
                <Col md={12}>
                    <Card>
                        {showCardHeader &&
                            <Card.Header className="pb-0">
                                <div className="d-flex flex-column flex-lg-row">
                                    <div className='d-flex gap-2'>
                                        {handleAddtionalMenu &&
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={faBars} className='cursor-pointer'
                                                    onClick={handleAddtionalMenu}
                                                />
                                            </div>
                                        }
                                        <div>
                                            <h5 className="mb-0">{title}</h5>
                                            <p className="text-sm mb-0">
                                                {subtitle}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="ms-auto my-auto mt-lg-0 mt-3">
                                        <div className="d-flex gap-1">
                                            {showAddtionalButton && (
                                                <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                    onClick={handleAddtional}>
                                                    {textButtonAddtional}
                                                </button>
                                            )}
                                            {handleInsert && showAddButton && (
                                                <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                    onClick={handleInsert}>
                                                    {textButtonAdd}
                                                </button>
                                            )}
                                            {showAddtionalButtonExtra && (
                                                <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                    onClick={handleAddtionalExtra}>
                                                    {textButtonAddtionalExtra}
                                                </button>
                                            )}
                                            {showAddtionalButtonExtra2 && (
                                                <button type="button" className="btn bg-primary text-white btn-sm mb-0"
                                                    onClick={handleAddtionalExtra2}>
                                                    {textButtonAddtionalExtra2}
                                                </button>
                                            )}
                                            {contentButtonLine}
                                            {hasFilter && (
                                                <button type="button"
                                                    className="btn btn-outline-primary btn-sm mb-0"
                                                    onClick={handleFilter}>
                                                    {textButtonFilter}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="ms-auto my-auto mt-lg-0 mt-3">
                                        {hasExcelPdf && (
                                            <div className="ms-auto my-auto">
                                                <ExportButtons
                                                    getExcel={handleExcel}
                                                    getExcelAddtional={handleAddtionalExcel}
                                                    getPdf={handlePdf}
                                                    isLoading={isLoadingFile}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Card.Header>
                        }
                        <Card.Body className={`${hasPagination ? 'pb-2' : ''}`}>
                            {content}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default PageBase;
