import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from "react";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { OpportunityService } from "../../../services/Marketplace/Opportunity/OpportunityService";
import { ToastSettings } from "../../../utils/ToastSettings";
import { toast } from "react-toastify";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";
import { OpportunityModel } from "../../../models/Marketplace/Opportunity/OpportunityModel";
import { FailedStatusModel } from "../../../models/Marketplace/Opportunity/FailedStatusModel";
import { MarketplacePerformanceModel } from "../../../models/Marketplace/Opportunity/MarketplacePerformanceModel";
import { OpportunityFilterModel } from "../../../models/Marketplace/Opportunity/OpportunityFilterModel";
import { OpportunitiesParams } from "../../../models/Marketplace/Opportunity/OpportunitiesParams";

interface ContextProps {
    opportunities: OpportunityModel[],
    setOpportunities: Dispatch<SetStateAction<OpportunityModel[]>>,
    opportunity: OpportunityModel,
    setOpportunity: Dispatch<SetStateAction<OpportunityModel>>,
    opportunityFilter: OpportunityFilterModel | undefined,
    marketplacePerformance: MarketplacePerformanceModel,
    setMarketplacePerformance: Dispatch<SetStateAction<MarketplacePerformanceModel>>,
    failedStatus: FailedStatusModel[],
    setFailedStatus: Dispatch<SetStateAction<FailedStatusModel[]>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    opportunitiesPages: PagesPaginateModel,
    setOpportunitiesPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingFile: boolean,
    loadedOpportunities: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleExcel: () => void,
    handleExcelEmail: () => void,
    loading: Loading,
    handleFinalizeOpportunity: (id: number, new_proposal: number) => Promise<any>,
    handleFailedOpportunity: (id: number) => Promise<void>,
    handleGetOpportunities: (page: number, params: OpportunitiesParams) => any,
    handleGetOpportunity: (opportunityId: number) => any,
    handleGetMarketplacePerformance: () => any,
    handleGetOpportunitiesByCampaign: (page: number, campaignId: number) => Promise<void>,
    handleGetFailedStatus: () => Promise<void>,
    handleGetFilters: () => Promise<any>,
    handleChangePartner: (payload: { agent_id: number, opportunity_id_list: number[] }) => Promise<boolean>
    handleAccessCheck: (payload: { report_password: any}) => Promise<any>
}

export const OpportunityContext = createContext<ContextProps>({} as ContextProps);

export interface Loading {
    listSimulation: boolean,
    createCampaign: boolean,
    listOpportunities: boolean,
}

interface OpportunityProviderProps {
    children: ReactNode;
}

export const OpportunityProvider = ({ children }: OpportunityProviderProps) => {
    const [opportunities, setOpportunities] = useState<any[]>([]);
    const [opportunity, setOpportunity] = useState<OpportunityModel>({});
    const [marketplacePerformance, setMarketplacePerformance] = useState<MarketplacePerformanceModel>({});
    const [opportunityFilter, setOpportunityFilter] = useState<OpportunityFilterModel>();
    const [failedStatus, setFailedStatus] = useState<FailedStatusModel[]>([]);
    const [params, setParams] = useState<any>({});
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [opportunitiesPages, setOpportunitiesPages] = useState<PagesPaginateModel>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadedOpportunities, setLoadedOpportunities] = useState<boolean>(false);
    const { isLoadingFile, setIsLoadingFile } = useReportRequest();

    const [loading, _setLoading] = useState<Loading>({
        listSimulation: false,
        createCampaign: false,
        listOpportunities: false
    });

    const service = new OpportunityService();

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleExcelEmail = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportEmail(params);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error, 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório enviado por email!');
        setIsLoadingFile(false);
    };

    const handleGetOpportunitiesByCampaign = async (page: number = 1, campaignId: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getOpportunitiesByCampaign(campaignId, page);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return;
            }

            setOpportunities(_Response?.data);
            setLoadedOpportunities(true);
            setPages(_Response?.pages);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setOpportunities([]);
            setError(error);
            return;
        }
    }

    const handleGetOpportunities = async (page: number = 1, params: OpportunitiesParams) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getOpportunitiesByAgent({ ...params, page });
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                setOpportunities([]);
                setOpportunitiesPages({});
                return;
            }

            setOpportunities(_Response?.data);
            setOpportunitiesPages(_Response?.pages);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setOpportunities([]);
            setOpportunitiesPages({});
            setError(error);
            return;
        }
    }

    const handleGetOpportunity = async (opportunityId: number) => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getOpportunity(opportunityId);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return;
            }

            setOpportunity(_Response?.data);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setOpportunity({});
            setError(error);
            return;
        }
    }

    const handleGetMarketplacePerformance = async () => {
        try {

            const [_Response, _Error] = await service.getMarketplacePerformance();

            if (_Error) {
                setError(_Error);
                return;
            }

            setMarketplacePerformance(_Response?.data);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setMarketplacePerformance({});
            setError(error);
            return;
        }
    }


    const handleGetFailedStatus = async () => {
        try {
            setIsLoading(true);

            const [_Response, _Error] = await service.getFailedStatus(params);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return;
            }

            setFailedStatus(_Response?.data);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setIsLoading(false);
            setFailedStatus([]);
            setError(error);
            return;
        }
    }

    const handleFinalizeOpportunity = async (id: number, new_proposal: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.postFinalizeOpportunity(id, new_proposal, 1);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return;
            }
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return;
        }
    }

    const handleFailedOpportunity = async (id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.postFailedOpportunity(id, 1);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return;
            }
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return;
        }
    }

    const handleGetFilters = async () => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.getFilters();
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                setOpportunityFilter(undefined);
                return;
            }
            setOpportunityFilter(_Response?.data);
            setError('');

            return;

        } catch (error: any) {
            console.warn(error);
            setOpportunityFilter(undefined);
            setError(error);
            return;
        }
    }

    const handleChangePartner = async (payload: { agent_id: number, opportunity_id_list: number[] }): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.changePartner(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return true;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return false;
        }
    }

    const handleAccessCheck = async (payload: { report_password: any }): Promise<boolean> => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.accessCheck(payload);
            setIsLoading(false);

            if (_Error) {
                setError(_Error);
                return false;
            }

            setError('');
            return _Response;

        } catch (error: any) {
            console.warn(error);
            setError(error);
            return false;
        }
    }

    return (
        <OpportunityContext.Provider value={useMemo(() => ({
            opportunities,
            setOpportunities,
            opportunity,
            setOpportunity,
            opportunityFilter,
            marketplacePerformance,
            setMarketplacePerformance,
            failedStatus,
            setFailedStatus,
            isLoading,
            isLoadingFile,
            loadedOpportunities,
            error,
            setError,
            params,
            setParams,
            pages,
            opportunitiesPages,
            setOpportunitiesPages,
            handleExcel,
            handleExcelEmail,
            handleFinalizeOpportunity,
            loading,
            handleGetOpportunities,
            handleGetOpportunity,
            handleGetOpportunitiesByCampaign,
            handleGetFailedStatus,
            handleFailedOpportunity,
            handleGetMarketplacePerformance,
            handleGetFilters,
            handleChangePartner,
            handleAccessCheck
        }), [
            opportunities,
            setOpportunities,
            opportunity,
            setOpportunity,
            opportunityFilter,
            marketplacePerformance,
            setMarketplacePerformance,
            failedStatus,
            setFailedStatus,
            isLoading,
            isLoadingFile,
            loadedOpportunities,
            error,
            setError,
            params,
            setParams,
            pages,
            opportunitiesPages,
            setOpportunitiesPages,
            handleExcel,
            handleExcelEmail,
            handleFinalizeOpportunity,
            loading,
            handleGetOpportunities,
            handleGetOpportunity,
            handleGetOpportunitiesByCampaign,
            handleGetFailedStatus,
            handleFailedOpportunity,
            handleGetMarketplacePerformance,
            handleGetFilters,
            handleChangePartner,
            handleAccessCheck
        ])}>
            {children}
        </OpportunityContext.Provider>
    )
}

export const useOpportunity = () => useContext(OpportunityContext);