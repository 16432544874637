import React, { FC, useEffect, useMemo, useState } from "react";
import "./TableAnnualProduction.scss";
import { useBiPartner } from "../../../../../../providers/BI/Dashboard/BiPartnerProvider";
import FormatMoney from "../../../../../../utils/FormatMoney";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";
import { TabEnumProductionByPartner } from "../ProductionByPartner";

interface TableAnnualProductionProps {
  currentTab: TabEnumProductionByPartner
}

const TableAnnualProduction: FC<TableAnnualProductionProps> = ({ currentTab }) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [colAnnualProduction, setColAnnualProduction] = useState<boolean>(true);
  const [colAnnualProjection, setColAnnualProjection] = useState<boolean>(true);
  const [colParticipation, setColParticipation] = useState<boolean>(true);
  const [colAnnualAverage, setColAnnualAverage] = useState<boolean>(true);
  const [rows, setRows] = useState<any[]>([]);

  const { annualProductionPartners, setAnnualProductionPartners } =
    useBiPartner();
  const {
    handleListAnnualProduction,
    params,
    isLoading,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight
  } = useBiDashboard();

  useEffect(() => {
    if (currentTab === TabEnumProductionByPartner.ANNUAL_PRODUCTION) {
      setHandleListOnFilter(() => handleListAnnualProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.AGENT,
        group: {},
      });
      if (!annualProductionPartners) {
        handleFetch().then();
      }
    }
  }, [currentTab]);

  useEffect(() => {
    if (resultOnFilter) {
      setAnnualProductionPartners(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListAnnualProduction({
      ...params,
      view: BI_VIEWS_ENUM.AGENT,
      group: {},
    }).then();

    if (result) {
      setAnnualProductionPartners(result);
    }
  };

  const handleExport = (rows: any) => {
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        Comercial: `${item?.name} - ${item?.partnerCode}`
      };

      item?.periods?.forEach((period: any) => {
        _row["Producao anual"] = period.totalProduction;
        _row["Producao anual D.U"] = period.totalProductionProjection;
        _row["Participacao"] = period.totalProductionPercentage;
        _row["Media D.U"] = period.totalProductionByWorkdays;
      });
      _data.push(_row);
    });
    handleExcel(_data, "Producao anual de parceiro ");
  };

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const mountRows = () => {
    if (annualProductionPartners) {
      const _rows: any[] = [];
      annualProductionPartners.items?.forEach((item) => {
        if (item.periods && item.periods.length > 0) {
          _rows.push({
            ...item,
            ...item.periods[0],
          });
        }
      });
      setRows([
        ..._rows,
        {
          name: 'TOTAL',
          periods: [{ ...annualProductionPartners?.total }]
        }
      ]);
    }
  };

  const mountColumns = useMemo(() => {
    if (
      annualProductionPartners?.items &&
      annualProductionPartners?.items?.length > 0
    ) {
      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">
              Código Agente
            </div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">
              {`${data?.name} - ${data?.partnerCode}`}
            </div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      annualProductionPartners?.items[0].periods?.forEach(
        (item: any, index: number) => {
          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Produção Anual
              </div>
            ),
            selector: (data: any) => data?.periods[index]?.totalProduction,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProduction)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProduction,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                Projeção Anual D.U
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionProjection,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionProjection)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualProjection,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                {" "}
                Participação{" "}
              </div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionPercentage,
            right: true,
            cell: (data: any) => (
              <div>
                {FormatMoney(
                  data?.periods[index]?.totalProductionPercentage,
                  false
                )}
                %
              </div>
            ),
            sortable: true,
            omit: !colParticipation,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">Média D.U</div>
            ),
            selector: (data: any) =>
              data?.periods[index]?.totalProductionByWorkdays,
            right: true,
            cell: (data: any) => (
              <div className="text-nowrap">
                {FormatMoney(data?.periods[index]?.totalProductionByWorkdays)}
              </div>
            ),
            sortable: true,
            omit: !colAnnualAverage,
          });
        }
      );
      mountRows();
      setColumns(_columns);
    }
  }, [
    annualProductionPartners,
    colAnnualProduction,
    colAnnualProjection,
    colParticipation,
    colAnnualAverage,
  ]);

  return (
    <div className="TableAnnualProduction" data-testid="TableAnnualProduction">
      {!isLoading ? (
        rows?.length > 0 ? (
          <>
            <Row md={12} className="display-button">
              <div className="d-flex justify-content-end align-items-center flex-md-row">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                  <button
                    type="button"
                    className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                    onClick={() => setColAnnualProduction((current) => !current)}
                  >
                    <FontAwesomeIcon
                      icon={colAnnualProduction ? faEye : faEyeSlash}
                      className="me-1"
                    />
                    Produção Anual
                  </button>

                  <button
                    type="button"
                    className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                    onClick={() => setColAnnualProjection((current) => !current)}
                  >
                    <FontAwesomeIcon
                      icon={colAnnualProjection ? faEye : faEyeSlash}
                      className="me-1"
                    />
                    Projeção Anual D.U
                  </button>

                  <button
                    type="button"
                    className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                    onClick={() => setColParticipation((current) => !current)}
                  >
                    <FontAwesomeIcon
                      icon={colParticipation ? faEye : faEyeSlash}
                      className="me-1"
                    />
                    Participação
                  </button>

                  <button
                    type="button"
                    className="btn bg-primary text-white mb-0 ms-2 min-width-btn"
                    onClick={() => setColAnnualAverage((current) => !current)}
                  >
                    <FontAwesomeIcon
                      icon={colAnnualAverage ? faEye : faEyeSlash}
                      className="me-1"
                    />
                    Média D.U
                  </button>
                  <button
                    className="btn bg-success text-white rounded m-0 ms-2"
                    onClick={() => {
                      handleExport(annualProductionPartners?.items);
                    }}
                  >
                    <i className="fas fa-file-excel" /> Exportar
                  </button>
                </div>
              </div>
            </Row>

            <Col md={12} className='mx-1 px-2 warning-register mb-2' style={{ backgroundColor: '#efefef' }}>
              <div className='text-sm font-italic'>
                O resultado está limitado a 300 linhas
              </div>
            </Col>

            <AppDataTable
              columns={columns}
              rows={rows}
              pagination={false}
              handleOrder={handleSortValue}
              conditionalRowStyles={[
                {
                  when: (data: any) => data.name === 'TOTAL',
                  style: biTotalRowStyle
                }
              ]}
              cellColor={biCellColor}
              cellFontSize={dataFontSize}
              maxHeight={tableHeight}
              display="flex"
            />
          </>
        ) : (
          <div className="d-flex mt-9 justify-content-center general-error-msg">
            {generalErrorMsg}
          </div>
        )
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default TableAnnualProduction;
