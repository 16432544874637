import { FC, useState } from 'react';
import './VisitsReports.scss';
import MasterPage from '../../../components/MasterPage/MasterPage';
import PageBase from '../../../components/PageBase/PageBase';
import { useVisitsReports } from '../../../providers/Visits/VisitsReports/VisitsReportsProvider';
import SkeletonTable from '../../../components/SkeletonTable/SkeletonTable';
import { VisitsReportsModel } from '../../../models/Visits/VisitsReports/VisitsReportsModel';
import SidebarVisitsReportsFilter from '../../../components/Visits/VisitsReports/SidebarVisitsReportsFilter/SidebarVisitsReportsFilter';
import { useSidebarFilter } from '../../../providers/SidebarFilterProvider';
import DropdownMenuTable from '../../../components/DropdownMenuTable/DropdownMenuTable';
import FileDownloadButton from '../../../components/FileDownloadButton/FileDownloadButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Card, CardBody, CardHeader, Col, Dropdown, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VisitsReportsChart from '../../../components/Visits/VisitsReports/VisitsReportsChart/VisitsReportsChart';
import VisitsReportsRanking from '../../../components/Visits/VisitsReports/VisitsReportsRanking/VisitsReportsRanking';
import ModalVisitsReportsDetail from '../../../components/Visits/VisitsReports/ModalVisitsReportsDetail/ModalVisitsReportsDetail';
import AppPagination from '../../../components/AppPagination/AppPagination';
import { usePaginate } from '../../../providers/PaginateProvider';
import FileSaver from 'file-saver';
import { useVisits } from '../../../providers/Visits/VisitsProvider';
import Gallery from '../../../components/Gallery/Gallery';
import moment from 'moment';
import TooltipItem from '../../../components/TooltipItem/TooltipItem';


interface VisitsReportsProps { };

const VisitsReports: FC<VisitsReportsProps> = () => {
    const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

    const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
    const { handlePaginate } = usePaginate();
    const { visits, pages, isLoading, params, setParams } = useVisits();
    const { isLoadingChart, isLoadingRanking, error, setReport } = useVisitsReports();

    const handleDownload = (id: number) => {
        setIsLoadingDownload(true);
        Array.prototype.forEach.call(document.getElementsByClassName(`thumb-${id}`), (i: any) => {
            fetch(i.src)
                .then(response => response.blob())
                .then(blob => {
                    FileSaver.saveAs(blob, `image-visit-${i.alt}`, { autoBom: false });
                })
                .catch(() => { });
        });

        setTimeout(() => {
            setIsLoadingDownload(false);
        }, 1000);
    }

    const renderList = () => {
        return (
            <>
                {visits?.length > 0 ? (
                    <>
                        {visits.map((item: VisitsReportsModel, key: number) => (
                            <tr key={item?.id ?? key} className="text-sm">
                                <td className="text-center">
                                    <div className="avatar mr-3">
                                        {item?.visitsImages && item?.visitsImages?.length > 0 ? (
                                            <div>
                                                <Gallery images={item?.visitsImages?.map((x: any) => { return { original: x.file } })} />
                                            </div>
                                        ) : (<></>)}
                                    </div>
                                </td>
                                <td className="text-center">{moment(item?.visitDate).format('DD/MM/YYYY')}</td>
                                <td className='text-wrap' style={{ minWidth: '250px' }}>{item?.userVisitor?.name || ''} - <b>{item?.userVisitor?.partnerCode || ''}</b></td>
                                <td className='text-wrap' style={{ minWidth: '250px' }}>
                                    {item?.userVisited ?
                                        <>{item?.userVisited?.name || ''} - <b>{item?.userVisited?.partnerCode || ''}</b></> :
                                        <>
                                            {item?.partnerRegistrationProspecting?.name} - <b>{item?.partnerRegistrationProspecting?.partnerCode}</b><br />
                                            <span className='text-xs text-primary bg-secondary p-1 rounded-2'>Prospecção</span>
                                        </>}
                                </td>
                                <td className="text-center">
                                    {(item?.wasDivergence === null || item?.wasDivergence === true) ? (
                                        <TooltipItem
                                            position='top'
                                            text={item?.divergenceObservation ? <span>{item?.divergenceObservation}</span> : <span>Endereço do parceiro não está dentro do raio de distância permitido no endereço da visita.</span>}
                                        >
                                            <span className="badge-dot mr-4 badge">
                                                <i className="bg-danger" />
                                                <span className="status text-sm text-gray">Sim</span>
                                            </span>
                                        </TooltipItem>
                                    ) : (
                                        <span className="badge-dot mr-4 badge">
                                            <i className="bg-success" />
                                            <span className="status text-sm text-gray">Não</span>
                                        </span>
                                    )}
                                </td>
                                <td className="text-center">{item?.wasFinished ? 'Sim' : 'Não'}</td>
                                <td className="text-center">
                                    <DropdownMenuTable key={item?.id ?? 0}>
                                        <Dropdown.Item className="border-radius-md py-2" onClick={() => { setShowModalDetail(true); setReport(item); }}>
                                            <FontAwesomeIcon icon={faEye} className="me-2" /> Visualizar detalhes
                                        </Dropdown.Item>
                                        <FileDownloadButton
                                            handleDownload={() => handleDownload(item?.id ?? 0)}
                                            isLoading={isLoadingDownload}
                                            error={error}
                                            textButton="Baixar fotos da visita"
                                        />
                                    </DropdownMenuTable>
                                </td>
                            </tr>
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colSpan={7} className="text-center text-sm">Não há registros</td>
                    </tr>
                )
                }
            </>
        );
    }

    const renderContent = (): JSX.Element => {
        return (
            <>
                <Row>
                    <Col xl="6" className="mb-4">
                        <Card className="h-100">
                            <CardHeader className="pb-0">
                                <h6 className="surtitle text-center text-gray m-0">Visitas nos últimos 5 meses</h6>
                            </CardHeader>
                            <CardBody>
                                {!isLoadingChart ? (
                                    <VisitsReportsChart />
                                ) : (<></>)}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="6" className="mb-4">
                        <Card className="h-100">
                            <CardHeader className="pb-0">
                                <h6 className="surtitle text-center text-gray m-0">Comerciais que mais visitaram</h6>
                            </CardHeader>
                            <CardBody>
                                {!isLoadingRanking ? (
                                    <VisitsReportsRanking />
                                ) : (
                                    <SkeletonTable columns={2} rows={3} />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {!isLoading ? (
                    <Card className="mb-4">
                        <CardBody className="pb-3">
                            <div className="table-responsive">
                                <table className="table table-flush m-0 w-100">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="text-center text-uppercase opacity-7" scope="col">FOTOS</th>
                                            <th className="text-center text-uppercase opacity-7 text-wrap" scope="col">DATA DA VISITA</th>
                                            <th className="text-uppercase opacity-7" scope="col">RESPONSÁVEL</th>
                                            <th className="text-uppercase opacity-7" scope="col">PARCEIRO </th>
                                            <th className="text-center text-uppercase opacity-7" scope="col">DIVERGÊNCIAS?</th>
                                            <th className="text-center text-uppercase opacity-7" scope="col">FINALIZADA</th>
                                            <th className="text-center text-uppercase opacity-7" scope="col">AÇÃO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderList()}
                                    </tbody>
                                </table>
                            </div>

                            {(visits?.length > 0) && (
                                <div>
                                    <nav aria-label="...">
                                        <AppPagination
                                            pages={pages}
                                            handlePaginate={handlePaginate}
                                            params={params}
                                            setParams={setParams}
                                            style={{ backgroundColor: '#fff' }}
                                        />
                                    </nav>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                ) : (
                    <SkeletonTable />
                )}
            </>
        );
    };

    return (
        <MasterPage
            title="Relatório de Visitas"
            icon="fas fa-building-columns"
            footerFixed
            footerInverse
        >
            <div className="VisitsReports" data-testid="VisitsReports">
                <PageBase
                    title="Relatório de Visitas"
                    subtitle="Listagem de relatórios de visitas."
                    handleFilter={() => showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
                    content={renderContent()}
                    hasFilter
                />
            </div>

            <SidebarVisitsReportsFilter />

            <ModalVisitsReportsDetail
                show={showModalDetail}
                onClose={() => setShowModalDetail(false)}
            />
        </MasterPage>
    );
}

export default VisitsReports;