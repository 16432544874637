import { FC, useEffect, useState } from 'react';
import './ModalManualGoalInsert.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { schema } from './Validate';
import { useAuth } from '../../../../providers/AuthProvider';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import moment from 'moment';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { customStyles } from '../../../../models/SelectCustomStyles';
import InputDropzone from '../../../InputDropzone/InputDropzone';
import Swal from 'sweetalert2';
import { useMonthlyGoalPeriodConfiguration } from '../../../../providers/PanelConnects/MonthlyGoal/MonthlyGoalPeriodConfigurationProvider';
import { useMonitoringCommercialPoints } from '../../../../providers/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPointsProvider';
import { monthlyGoalAccessProfileOptions } from '../../../../models/PanelConnects/MonthlyGoalAccessProfileOptions';

interface ModalManualGoalInsertProps {
  show: boolean;
  onClose: () => void;
}



const ModalManualGoalInsert: FC<ModalManualGoalInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [periodOptions, setPeriodOptions] = useState<any[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<any>();
  const [selectedAccessProfileId, setSelectedAccessProfileId] = useState<any>();
  const [selectedGoal, setSelectedGoal] = useState<any>();
  const [files, setFiles] = useState<any[]>([]);
  const [fileTypes] = useState<any>({
    'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  });

  const animatedComponents = makeAnimated();

  const { listGoals, setGoals, goalsOptions } = useMonthlyGoalPeriodConfiguration();
  const { handleFetchManualGoal, setError, handleListPoints, params } = useMonitoringCommercialPoints();
  const { onInvalid } = useAuth();


  const { handleSubmit, setValue, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (files?.length > 0) {
      const fileExtension = files[0]?.path?.split('.').pop()?.toLowerCase();

      if (['xls', 'xlsx', 'csv'].includes(fileExtension)) {
        setValue('file', files[0]?.path);
      } else {
        Swal.fire('Atenção', 'Apenas arquivos do tipo .xls, .xlsx ou .csv serão aceitos', 'warning');
        setValue('file', '');
        setFiles([]);
      }
    } else {
      setValue('file', '');
    }
  }, [files]);

  useEffect(() => {
    setValue('period', selectedPeriod);
    setValue('accessProfileId', selectedAccessProfileId);
    setSelectedGoal(null);
    if (selectedPeriod && selectedAccessProfileId) {
      listGoals(moment(selectedPeriod?.value, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'), selectedAccessProfileId?.value, true);
    } else {
      setGoals([]);
    }
  }, [selectedPeriod, selectedAccessProfileId]);

  useEffect(() => {
    setValue('goal', selectedGoal)
  }, [selectedGoal]);

  useEffect(() => {
    if (periodOptions.length === 0) {
      getPeriodOptions();
    }
  }, []);

  const getPeriodOptions = () => {
    Array.from(Array(3)).forEach((x, i) => {
      periodOptions?.push({
        label: moment(new Date()).subtract(i, 'M').format('MMMM/YY')?.toUpperCase(),
        value: moment(new Date()).subtract(i, 'M').format('YYYY-MM-01')
      });
    });
    setSelectedPeriod(periodOptions[0]);
  }

  const handleClose = () => {
    setError('');
    reset();
    setSelectedPeriod(null);
    setSelectedAccessProfileId(null);
    setSelectedGoal(null);
    onClose();
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError('');

    const _data: any = {};

    _data.period = data?.period?.value;
    _data.accessProfileId = data.accessProfileId?.value;
    _data.goal = data?.goal?.value;
    _data.file = data?.file

    const ret = await handleFetchManualGoal(_data, files);
    setIsLoadingSubmit(false);

    if (ret) {
      toast.success('Importação realizada com sucesso!');
      handleListPoints({
        ...params,
        closingDate: params?.closingDate ?? moment(new Date()).format('YYYY-MM-01')
      }).then();
      onClose();
    }
  }


  return (
    <ModalDefault
      title={'Adicionar Meta Manual'}
      show={show}
      onClose={handleClose}
      sizeModal={'lg'}
      showFooter={true}
      buttonText={isLoadingSubmit ? 'Salvando...' : 'Salvar'}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
    >
      <div className="ModalManualGoalInsert" data-testid="ModalManualGoalInsert">
        <Row>
          <Col>
            <p>
              Essa importação irá impactar diretamente os pontos dos comercias.<br />
              O valor informado na coluna <b>"Pontos"</b> da planilha, será efetivamente a quantidade de pontos do comercial.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <div className="form-group">
              <label htmlFor="period">PERÍODO *</label>
              <ReactSelect
                name='period'
                value={selectedPeriod}
                isSearchable
                isClearable={false}
                options={periodOptions}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.period)}`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedPeriod(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name="Período" type={errors?.period?.type?.toString()} />
            </div>
          </Col>

          <Col md={6}>
            <FormGroup className="form-group">
              <label>TIPO COMERCIAL *</label>
              <ReactSelect
                name='commercialType'
                value={selectedAccessProfileId}
                isClearable={false}
                options={monthlyGoalAccessProfileOptions}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedAccessProfileId(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name='Tipo Comercial' type={errors?.accessProfileId?.type?.toString()} />
            </FormGroup>
          </Col>

          <Col sm={12}>
            <div className="form-group">
              <label htmlFor="goal">TIPOS DE META *</label>
              <ReactSelect
                name='goal'
                value={selectedGoal}
                isSearchable
                isClearable={false}
                options={goalsOptions}
                placeholder="Selecione..."
                className={`form-control p-0 ${onInvalid(errors?.goal)}`}
                components={animatedComponents}
                noOptionsMessage={() => 'Não há registros'}
                onChange={(val: any) => {
                  setSelectedGoal(val);
                }}
                styles={customStyles}
              />
              <ErrorMessage name="Tipo de Meta" type={errors?.goal?.type?.toString()} />
            </div>
          </Col>

          <Col md={12}>
            <FormGroup className="form-group mb-1">
              <InputDropzone
                label="Arquivo"
                name="file"
                files={files}
                setFiles={setFiles}
                types={fileTypes}
                maxSize={157286400}
                error={!!errors?.file}
              />
              <ErrorMessage name="Arquivo" type={errors?.file?.type?.toString()} />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className='text-end'>
            <a
              className='model-file'
              href={`${process.env.PUBLIC_URL}/model/PanelConnects/MonthlyGoal/modeloImportarPontuacao.csv`}
              download
            >
              Arquivo Modelo
            </a>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
}

export default ModalManualGoalInsert;
