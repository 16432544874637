import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../providers/AuthProvider";
import { StyleProvider } from "../providers/Style/StyleProvider";
import { AuthenticationGate } from "./PrivateRoute";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import Page404 from "../pages/Page404/Page404";
import { InformativeProvider } from "../providers/InformativeProvider";
import AccessLevel from "../pages/Registrations/AccessLevel/AccessLevel";
import Partner from "../pages/Registrations/Partner/Partner";
import CurrentAccount from "../pages/CurrentAccount/CurrentAccount";
import BeviUniversity from "../pages/BeviUniversity/BeviUniversity";
import Company from "../pages/Registrations/Company/Company";
import AuthenticatorDomus from "../pages/Authenticator/AuthenticatorDomus/AuthenticatorDomus";
import { SidebarFilterProvider } from "../providers/SidebarFilterProvider";
import Opportunities from "../pages/Marketplace/Opportunities/Opportunities";
import Endpoints from "../pages/Marketplace/Endpoints/Endpoints";
import Campaign from "../pages/Marketplace/Campaign/Campaign";
import ProposalImport from "../pages/Proposals/Import/ProposalImport/ProposalImport";
import ProposalConfig from "../pages/Proposals/Config/ProposalConfig/ProposalConfig";
import ProposalRule from "../pages/Proposals/Rule/ProposalRule/ProposalRule";
import { PartnerRoute } from "./Modules/PartnerRoute";
import { ProposalRoute } from "./Modules/ProposalRoute";
import { AccessLevelRoute } from "./Modules/AccessLevelRoute";
import { FinancialRoute } from "./Modules/FinancialRoute";
import { MarketplaceRoute } from "./Modules/MarketplaceRoute";
import Financial from "../pages/Registrations/Financial/Financial";
import { LogRoute } from "./Modules/LogRoute";
import LogsScreens from "../pages/Logs/LogScreen/LogScreen";
import { LogProvider } from "../providers/Logs/LogProvider";
import ProposalExport from "../pages/Proposals/Export/ProposalExport/ProposalExport";
import { ReportRoute } from "./Modules/ReportRoute";
import Report from "../pages/Reports/Report/Report";
import ReportRequest from "../pages/Reports/ReportRequest/ReportRequest";
import IndicatorManagement from "../pages/Visits/IndicatorManagement/IndicatorManagement";
import PanelImport from "../pages/Proposals/Import/PanelImport/PanelImport";
import { FirebaseMessagingProvider } from "../providers/FirebaseMessaging";
import ProposalConfigExport from "../pages/Proposals/Config/ProposalConfigExport/ProposalConfigExport";
import AuthenticatorValidation from "../pages/Authenticator/AuthenticatorValidation/AuthenticatorValidation";
import HomeNotice from "../pages/HomeNotice/HomeNotice";
import { AuthenticatorValidationProvider } from "../providers/Authenticator/AuthenticatorValidationProvider";
import { AuthenticatorValidationAlternativeProvider } from "../providers/Authenticator/AuthenticatorValidationAlternativeProvider";
import AuthenticatorValidationAlternative from "../pages/Authenticator/AuthenticatorValidationAlternative/AuthenticatorValidationAlternative";
import AuthenticatorValidationEmail from "../pages/Authenticator/AuthenticatorValidationEmail/AuthenticatorValidationEmail";
import { AuthenticatorValidationEmailProvider } from "../providers/Authenticator/AuthenticatorValidationEmailProvider";
import Receipt from "../pages/Commissioning/Receipt/Receipt";
import VisitsTypesContacts from "../pages/Visits/VisitsTypesContacts/VisitsTypesContacts";
import VisitsActions from "../pages/Visits/VisitsActions/VisitsActions";
import VisitsTypesActivities from "../pages/Visits/VisitsTypesActivities/VisitsTypesActivities";
import VisitsReports from "../pages/Visits/VisitsReports/VisitsReports";
import VisitsAnalysis from "../pages/Visits/VisitsAnalysis/VisitsAnalysis";
import { VisitRoute } from "./Modules/VisitRoute";
import PreVisitsAnalysis from "../pages/Visits/PreVisitsAnalysis/PreVisitsAnalysis";
import { ModuleToolsRoute } from "./Modules/ModuleToolsRoute";
import ModulesTools from "../pages/Registrations/ModulesTools/ModulesTools";
import { NotificationRoute } from "./Modules/NotificationRoute";
import NotificationCenter from "../pages/Registrations/Notifications/NotificationCenter/NotificationCenter";
import Invitation from "../pages/Invitation/Invitation";
import ContractFormConfig from "../pages/Tools/Configurations/ContractFormConfig/ContractFormConfig";
import CovenantTableConfig from "../pages/Tools/Configurations/CovenantTableConfig/CovenantTableConfig";
import VisitsDivergencesAssessments from "../pages/Visits/VisitsDivergencesAssessments/VisitsDivergencesAssessments";
import BoardReceipt from "../pages/Commissioning/BoardReceipt/BoardReceipt";
import Covenant from "../pages/Registrations/Covenant/Covenant/Covenant";
import CovenantGroup from "../pages/Registrations/Covenant/CovenantGroup/CovenantGroup";
import MasterCovenantGroup from "../pages/Registrations/Covenant/MasterCovenantGroup/MasterCovenantGroup";
import DashboardAdmin from "../pages/Marketplace/DashboardAdmin/DashboardAdmin";
import Simulator from "../pages/Marketplace/Simulator/Simulator";
import CampaignAutoGen from "../pages/Marketplace/CampaignAutoGen/CampaignAutoGen";
import EasyPort from "../pages/Marketplace/EasyPort/EasyPort";
import CyberIncidentResponseForm from "../pages/Security/CyberIncidentResponseForm/CyberIncidentResponseForm";
import { SecurityRoute } from "./Modules/SecurityRoute";
import PartnerRegistrationUpdate from "../pages/Registrations/PartnerRegistrationUpdate/PartnerRegistrationUpdate";
import MetricManagement from "../pages/PanelConnects/MetricManagement/MetricManagement";
import { PanelConnectsRoute } from "./Modules/PanelConnectsRoute";
import MonthlyGoal from "../pages/PanelConnects/MonthlyGoal/MonthlyGoal";
import TermsDocuments from "../pages/Terms/Documents/TermsDocuments";
import { TermsRoute } from "./Modules/TermsRoute";
import DashboardProduction from "../pages/Bi/Dashboard/DashboardProduction/DashboardProduction";
import FinancialUpdateFrequency from "../pages/Bi/FinancialUpdateFrequency/FinancialUpdateFrequency";
import { BiRoute } from "./Modules/BiRoute";
import MonitoringCommercialPoints from "../pages/PanelConnects/MonitoringCommercialPoints/MonitoringCommercialPoints";
import AccessControl from "../pages/Marketplace/AccessControl/AccessControl";
import TermsTracking from "../pages/Terms/TermsTracking/TermsTracking";
import MonthlyGoalPeriodConfiguration from "../pages/PanelConnects/MonthlyGoalPeriodConfiguration/MonthlyGoalPeriodConfiguration";
import MonthlyGoalCategory from "../pages/PanelConnects/MonthlyGoalCategory/MonthlyGoalCategory";
import { UserDependentProvider } from "../providers/User/UserDependentProvider";
import TermsDashboard from "../pages/Terms/Dashboard/TermsDashboard";
import TermsPersonal from "../pages/Terms/TermsPersonal/TermsPersonal";

const RootRoute = () => {
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <StyleProvider>
          <FirebaseMessagingProvider>
            <SidebarFilterProvider>
              <InformativeProvider>
                <AuthProvider>
                  <LogProvider>
                    <AuthenticatorValidationProvider>
                      <AuthenticatorValidationAlternativeProvider>
                        <AuthenticatorValidationEmailProvider>
                          <UserDependentProvider>
                            <Routes>
                              <Route
                                path="/"
                                element={
                                  !process.env.NODE_ENV ||
                                    process.env.NODE_ENV === "development" ? (
                                    <Login />
                                  ) : (
                                    <HomeNotice />
                                  )
                                }
                              />
                              <Route path="/login" element={<Login />} />
                              <Route
                                path="/convite/:code"
                                element={<Invitation />}
                              />

                              <Route path="authenticator">
                                <Route
                                  path="domus"
                                  element={<AuthenticatorDomus />}
                                />
                                <Route
                                  path="validation"
                                  element={<AuthenticatorValidation />}
                                />
                                <Route
                                  path="validation-alternative"
                                  element={<AuthenticatorValidationAlternative />}
                                />
                                <Route
                                  path="validation-email"
                                  element={<AuthenticatorValidationEmail />}
                                />
                              </Route>

                              <Route element={<AuthenticationGate />}>
                                <Route path="/home" element={<Home />} />
                                <Route
                                  path="/current-account"
                                  element={<CurrentAccount />}
                                />
                                <Route
                                  path="/bevi-university"
                                  element={<BeviUniversity />}
                                />

                                <Route path="registrations">
                                  <Route
                                    path="access-level"
                                    element={<AccessLevelRoute />}
                                  >
                                    <Route
                                      path="list"
                                      element={<AccessLevel />}
                                    />
                                  </Route>

                                  <Route
                                    path="partner"
                                    element={<PartnerRoute />}
                                  >
                                    <Route path="list" element={<Partner />} />
                                    <Route
                                      path="registration-update"
                                      element={<PartnerRegistrationUpdate />}
                                    />
                                  </Route>

                                  <Route path="company">
                                    <Route path="list" element={<Company />} />
                                  </Route>

                                  <Route
                                    path="financial"
                                    element={<FinancialRoute />}
                                  >
                                    <Route path="list" element={<Financial />} />
                                  </Route>

                                  <Route
                                    path="module-tools"
                                    element={<ModuleToolsRoute />}
                                  >
                                    <Route
                                      path="list"
                                      element={<ModulesTools />}
                                    />
                                  </Route>

                                  <Route
                                    path="notification"
                                    element={<NotificationRoute />}
                                  >
                                    <Route
                                      path="notification-center"
                                      element={<NotificationCenter />}
                                    />
                                  </Route>
                                </Route>

                                <Route
                                  path="marketplace"
                                  element={<MarketplaceRoute />}
                                >
                                  <Route path="opportunities">
                                    <Route
                                      path="list"
                                      element={<Opportunities />}
                                    />
                                  </Route>

                                  <Route path="endpoints">
                                    <Route path="list" element={<Endpoints />} />
                                  </Route>

                                  <Route path="campaign">
                                    <Route path="list" element={<Campaign />} />
                                    <Route
                                      path="autogen"
                                      element={<CampaignAutoGen />}
                                    />
                                  </Route>

                                  <Route path="admin">
                                    <Route
                                      path="dashboard"
                                      element={<DashboardAdmin />}
                                    />
                                  </Route>

                                  <Route path="simulator">
                                    <Route path="cpf" element={<Simulator />} />
                                    <Route
                                      path="easyport"
                                      element={<EasyPort />}
                                    />
                                  </Route>

                                  <Route path="access-control">
                                    <Route
                                      path="list"
                                      element={<AccessControl />}
                                    />
                                  </Route>
                                </Route>

                                <Route
                                  path="proposal"
                                  element={<ProposalRoute />}
                                >
                                  <Route path="import">
                                    <Route
                                      path="list"
                                      element={<ProposalImport />}
                                    />
                                  </Route>

                                  <Route path="config">
                                    <Route
                                      path="list"
                                      element={<ProposalConfig />}
                                    />
                                    <Route
                                      path="export/list"
                                      element={<ProposalConfigExport />}
                                    />
                                  </Route>

                                  <Route path="rule">
                                    <Route
                                      path="list"
                                      element={<ProposalRule />}
                                    />
                                  </Route>

                                  <Route path="export">
                                    <Route
                                      path="list"
                                      element={<ProposalExport />}
                                    />
                                  </Route>

                                  <Route path="panel">
                                    <Route
                                      path="list"
                                      element={<PanelImport />}
                                    />
                                  </Route>

                                  <Route path="configuration">
                                    <Route
                                      path="contract-form"
                                      element={<ContractFormConfig />}
                                    />
                                    <Route
                                      path="covenant-table"
                                      element={<CovenantTableConfig />}
                                    />
                                  </Route>

                                  <Route path="covenant">
                                    <Route path="list" element={<Covenant />} />
                                    <Route
                                      path="group"
                                      element={<CovenantGroup />}
                                    />
                                    <Route
                                      path="master-group"
                                      element={<MasterCovenantGroup />}
                                    />
                                  </Route>
                                </Route>

                                <Route path="tool">
                                  <Route path="log" element={<LogRoute />}>
                                    <Route path="screen">
                                      <Route
                                        path="list"
                                        element={<LogsScreens />}
                                      />
                                    </Route>
                                  </Route>
                                </Route>

                                <Route path="report" element={<ReportRoute />}>
                                  <Route path="list" element={<Report />} />

                                  <Route path="request">
                                    <Route
                                      path="list"
                                      element={<ReportRequest />}
                                    />
                                  </Route>
                                </Route>

                                <Route path="app-visits" element={<VisitRoute />}>
                                  <Route path="types-contacts">
                                    <Route
                                      path="list"
                                      element={<VisitsTypesContacts />}
                                    />
                                  </Route>
                                  <Route path="types-activities">
                                    <Route
                                      path="list"
                                      element={<VisitsTypesActivities />}
                                    />
                                  </Route>
                                  <Route path="actions">
                                    <Route
                                      path="list"
                                      element={<VisitsActions />}
                                    />
                                  </Route>
                                  <Route path="report-visit">
                                    <Route
                                      path="list"
                                      element={<VisitsReports />}
                                    />
                                  </Route>
                                  <Route path="indicator-management">
                                    <Route
                                      path="list"
                                      element={<IndicatorManagement />}
                                    />
                                  </Route>
                                  <Route path="analysis-visits">
                                    <Route
                                      path="list"
                                      element={<VisitsAnalysis />}
                                    />
                                  </Route>
                                  <Route path="pre-visits-analysis">
                                    <Route
                                      path="list"
                                      element={<PreVisitsAnalysis />}
                                    />
                                  </Route>
                                  <Route path="visits-divergence">
                                    <Route
                                      path="list"
                                      element={<VisitsDivergencesAssessments />}
                                    />
                                  </Route>
                                </Route>

                                <Route
                                  path="panel-connects"
                                  element={<PanelConnectsRoute />}
                                >
                                  <Route
                                    path="monthly-goal"
                                    element={<MonthlyGoal />}
                                  />
                                  <Route
                                    path="monitoring-commercial-points"
                                    element={<MonitoringCommercialPoints />} />

                                  <Route
                                    path="metric"
                                    element={<MetricManagement />}
                                  />

                                  <Route
                                    path="period-configuration"
                                    element={<MonthlyGoalPeriodConfiguration />}
                                  />

                                  <Route
                                    path="categories"
                                    element={<MonthlyGoalCategory />}
                                  />
                                </Route>

                                <Route
                                  path="financial"
                                  element={<FinancialRoute />}
                                >
                                  <Route path="commissioning">
                                    <Route path="receipt" element={<Receipt />} />
                                    <Route
                                      path="board-receipt"
                                      element={<BoardReceipt />}
                                    />
                                  </Route>
                                </Route>

                                <Route path="terms" element={<TermsRoute />}>
                                  <Route
                                    path="terms-documents"
                                    element={<TermsDocuments />}
                                  />

                                  <Route
                                    path="terms-tracking"
                                    element={<TermsTracking />}
                                  />

                                  <Route
                                    path="terms-dashboard"
                                    element={<TermsDashboard />}
                                  />

                                  <Route
                                    path="terms-personal"
                                    element={<TermsPersonal />}
                                  />
                                </Route>

                                <Route
                                  path="security"
                                  element={<SecurityRoute />}
                                >
                                  <Route path="cyber-incidents">
                                    <Route
                                      path="list"
                                      element={<CyberIncidentResponseForm />}
                                    />
                                  </Route>
                                </Route>

                                <Route
                                  path="business-intelligence"
                                  element={<BiRoute />}
                                >
                                  <Route
                                    path="dashboard"
                                    element={<DashboardProduction />}
                                  />

                                  <Route
                                    path="financial-update-frequency"
                                    element={<FinancialUpdateFrequency />}
                                  />
                                </Route>
                              </Route>

                              <Route
                                path="unauthorized"
                                element={<Unauthorized />}
                              />
                              <Route path="*" element={<Page404 />} />
                            </Routes>
                          </UserDependentProvider>
                        </AuthenticatorValidationEmailProvider>
                      </AuthenticatorValidationAlternativeProvider>
                    </AuthenticatorValidationProvider>
                  </LogProvider>
                </AuthProvider>
              </InformativeProvider>
            </SidebarFilterProvider>
          </FirebaseMessagingProvider>
        </StyleProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default RootRoute;
