import { FC, useEffect } from "react";
import MasterPage from "../../../components/MasterPage/MasterPage";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { useSidebarFilter } from "../../../providers/SidebarFilterProvider";

interface TermsDashboardProps { }

const TermsDashboard: FC<TermsDashboardProps> = () => {
  const { termsTotals, handleTermsTotals } = useTermsDocuments();
  const { setShowSidebarFilter } = useSidebarFilter();

  useEffect(() => {
    setShowSidebarFilter(false);
  }, []);

  useEffect(() => {
    handleTermsTotals();
  }, []);

  const renderContent = (): JSX.Element => {

    return (
      <>
        <Row className="mt-4">
          <Col md={12}>
            <Card className="h-100">
              <Card.Header className="pb-0">
                <div className="card-body d-flex justify-content">
                  <h4>
                    <FontAwesomeIcon icon={faChartLine} />
                    <b className="ps-2">Dashboard</b>
                  </h4>
                </div>
              </Card.Header>
              <Card.Body className="pt-0">
                <div className="card-body d-flex justify-content-between">

                  <div className="col-xl-3 col-lg-6 col-md-12 col-12">
                    <div className="card  mb-4 h-100 ">
                      <div className="card-body p-3">
                        <div className="row">
                          <div className="col-8">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-uppercase font-weight-bold">TERMOS GERADOS</p>
                              <h5 className="position-absolute bottom-0 start-0 p-3" style={{ color: 'green' }}>
                                {termsTotals?.total}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                              <i className="fa-solid fa-file-circle-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-12 col-12 ps-2">
                    <div className="card  mb-4 h-100 ">
                      <div className="card-body p-3">
                        <div className="row">
                          <div className="col-8">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-uppercase font-weight-bold">TERMOS CONCLUIDOS</p>
                              <h5 className="position-absolute bottom-0 start-0 p-3" style={{ color: 'x.color' }}>
                                {termsTotals?.completed}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                              <i className="fa-solid fa-file-circle-check" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-12 col-12 ps-2">
                    <div className="card  mb-4 h-100 ">
                      <div className="card-body p-3">
                        <div className="row">
                          <div className="col-8">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-uppercase font-weight-bold">TERMOS PENDENTES</p>
                              <h5 className="position-absolute bottom-0 start-0 p-3" style={{ color: 'x.color' }}>
                                {termsTotals?.pending}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                              <i className="fa-solid fa-file-circle-exclamation"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-md-12 col-12 ps-2">
                    <div className="card  mb-4 h-100 ">
                      <div className="card-body p-3">
                        <div className="row">
                          <div className="col-8">
                            <div className="numbers">
                              <p className="text-sm mb-0 text-uppercase font-weight-bold">TERMOS CANCELADOS</p>
                              <h5 className="position-absolute bottom-0 start-0 p-3" style={{ color: 'x.color' }}>
                                {termsTotals?.canceled}
                              </h5>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                              <i className="fa-solid fa-file-circle-xmark"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col >
        </Row>
      </>
    );
  }

  return (
    <div
      className="TermsDashboard"
      data-testid="TermsDashboard"
    >
      <MasterPage
        title="Dashboard Termos"
        icon="fas fa-line-chart"
        footerFixed
        footerInverse
      >
        {renderContent()}
      </MasterPage>
    </div>
  );
};

export default TermsDashboard;
