import { FC, useEffect, useRef, useState } from "react";
import "./ModalTermsRecord.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { Col, Row } from "react-bootstrap";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import moment from "moment";
import AppPagination from "../../AppPagination/AppPagination";
import { usePaginate } from "../../../providers/PaginateProvider";
import SkeletonTable from "../../SkeletonTable/SkeletonTable";

interface ModalTermsRecordProps {
  show: boolean;
  onClose: () => void;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsRecord: FC<ModalTermsRecordProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const {handleHistory, history, term, paramsRecord, setParamsRecord, pagesRecord, setHistory, isLoading} = useTermsDocuments();
  const { handlePaginate } = usePaginate();

  useEffect(()=>{
    if (term?.id) {
      handleHistory(term.id)
    };
  },[paramsRecord]);

  const handleClose = () => {
    setHistory([]);
    setParamsRecord(defaultValues);
    onClose();
  };

  const renderList = () => {
    return (
      <>
        {history?.length > 0 ? (
        <>
          {history?.map((x: any, key: number) => (
            <>
              <tr>
                <td> 
                  {x?.user?.name}
                </td>
                <td> 
                  {moment(x?.createdAt).format('DD/MM/YYYY')}
                </td>
                <td>
                  {x?.name}
                </td>
                <td className="scrollable-column">
                  {x?.htmlContent}
                </td>
                <td>
                  {x?.reasonAction}
                </td>
              </tr>
            </>
          ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  return (
    <ModalDefault
      title={"Histórico de documentos"}	
      show={show}
      onClose={handleClose}
      sizeModal={"xl"}
      showFooter={true}
      showButtonSpinner={isLoadingSubmit}
      backdrop="static"
    >
      {!isLoading ? (
      <div className="ModalTermsRecord" data-testid="ModalTermsRecord">
        <Row>
          <Col md={12} sm={12}>
            <table className="table table-flush m-0 w-100">
              <thead>
                <tr>
                  <th>
                    Responsável
                  </th>
                  <th>
                    Data
                  </th>
                  <th>
                    Título
                  </th>
                  <th>
                    Conteúdo
                  </th>
                  <th>
                    Obs
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderList()}
              </tbody>
            </table>

            {history?.length > 0 && (
              <div>
                <nav aria-label="...">
                  <AppPagination
                    pages={pagesRecord}
                    handlePaginate={handlePaginate}
                    params={paramsRecord}
                    setParams={setParamsRecord}
                    style={{ backgroundColor: '#fff' }}
                  />
                </nav>
              </div>
            )}
          </Col>
        </Row>
      </div>
      ) : (
        <SkeletonTable />
      )}
    </ModalDefault>
  );
};

export default ModalTermsRecord;
