import React, { Dispatch, FC, useEffect, useState } from "react";
import "./SidebarBiDashboardFilter.scss";
import SidebarFilter from "../../../SidebarFilter/SidebarFilter";
import { useForm } from "react-hook-form";
import { Col, FormGroup, Row } from "react-bootstrap";
import InputDateRange from "../../../InputDateRange/InputDateRange";
import moment from "moment";
import { useBiDashboard } from "../../../../providers/BI/Dashboard/BiDashboardProvider";
import InputSearchPartner from "../../../Registrations/Partner/InputSearchPartner/InputSearchPartner";
import { useCommercial } from "../../../../providers/Registrations/User/UserCommercialProvider";
import ReactSelect from "react-select";
import { customStyles } from "../../../../models/SelectCustomStyles";
import { useSubsidiary } from "../../../../providers/Registrations/Company/SubsidiaryProvider";
import { ufs } from "../../../../models/OptionsValues";
import { useCovenant } from "../../../../providers/Registrations/Covenant/CovenantProvider";
import { useCovenantGroup } from "../../../../providers/Registrations/Covenant/CovenantGroupProvider";
import { useMasterCovenantGroup } from "../../../../providers/Registrations/Covenant/MasterCovenantGroupProvider";
import { useContractForm } from "../../../../providers/Proposals/ContractForm/ContractFormProvider";
import { useFinancial } from "../../../../providers/Registrations/Financial/FinancialProvider";

interface SidebarBiDashboardFilterProps { }

const SidebarBiDashboardFilter: FC<SidebarBiDashboardFilterProps> = () => {
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [selectedCommercial, setSelectedCommercial] = useState<any>();
  const [selectedRegional, setSelectedRegional] = useState<any>();
  const [selectedExecutive, setSelectedExecutive] = useState<any>();
  const [selectedBranchs, setSelectedBranchs] = useState<any[]>();
  const [selectedUfs, setSelectedUfs] = useState<any[]>();
  const [selectedFinancials, setSelectedFinancials] = useState<any[]>();
  const [selectedCovenants, setSelectedCovenants] = useState<any[]>();
  const [selectedCovenantGroups, setSelectedCovenantGroups] = useState<any[]>();
  const [selectedCovenantMasterGroups, setSelectedCovenantMasterGroups] =
    useState<any[]>();
  const [selectedContractForm, setSelectedContractForm] = useState<any[]>();
  const [valuePartner, setValuePartner] = useState<any>();
  const [partnerObj, setPartnerObj] = useState<any>();

  const { setParams, params, handleListOnFilter, setResultOnFilter, currentTab } =
    useBiDashboard();
  const {
    commercialsOptions,
    handleList: handleListCommercial,
    regionalsOptions,
    handleListRegional,
    executivesOptions,
    handleListExecutive,
  } = useCommercial();
  const { handleList: handleListSubsidiary, subsidiarysOptions } =
    useSubsidiary();
  const {
    handleListFinancialsOptions: handleListFinancials,
    financialsOptions,
  } = useFinancial();
  const { handleList: handleListMasterGroup, masterGroupOptions } =
    useMasterCovenantGroup();
  const { handleList: handleListCovenantGroup, covenantGroupsOptions } =
    useCovenantGroup();
  const {
    handleList: handleListCovenant,
    covenantsOptions,
    params: paramsCovenant,
    setParams: setParamsCovenant,
  } = useCovenant();
  const {
    handleList: handleListContractForm,
    contractFormsOptions,
    params: paramsContractForm,
    setParams: setParamsContractForm,
  } = useContractForm();
  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    handleListCommercial().then();
    handleListRegional({});
    handleListExecutive();
    handleListSubsidiary();
    handleListFinancials(false);
    handleListMasterGroup().then();
    handleListCovenantGroup().then();
    handleListCovenant().then();
    setParamsContractForm({
      withPaginate: false,
      active: "S",
    });
  }, []);

  useEffect(() => {
    if (!valuePartner) {
      setPartnerObj(undefined);
    }
  }, [valuePartner]);

  useEffect(() => {
    if (selectedCovenantGroups) {
      handleListCovenant();
    }
  }, [paramsCovenant]);

  useEffect(() => {
    handleListContractForm().then();
  }, [paramsContractForm]);

  useEffect(() => {
    if (selectedCovenantGroups) {
      setParamsCovenant({
        withPaginate: false,
        active: "S",
        proposalsGroupId: selectedCovenantGroups?.map((x) => x.externalId),
      });
    }
  }, [selectedCovenantGroups]);

  const onSubmit = async (data: any) => {
    if (handleListOnFilter) {
      const _data: any = {};
      _data.agent = partnerObj?.partnerId ? [partnerObj.partnerId] : [];
      _data.commercial = selectedCommercial ? selectedCommercial?.map((x: any) => x.partnerId) : [];
      _data.regional = selectedRegional ? selectedRegional?.map((x: any) => x.partnerId) : [];
      _data.executive = selectedExecutive ? selectedExecutive?.map((x: any) => x.partnerId) : [];
      _data.branch = selectedBranchs ? selectedBranchs?.map((x) => x.externalId) : [];
      _data.state = selectedUfs ? selectedUfs?.map((x) => x.value) : [];
      _data.financial = selectedFinancials ? selectedFinancials?.map((x) => x.externalId) : [];
      _data.mastergroup = selectedCovenantMasterGroups ? selectedCovenantMasterGroups?.map((x) => x.externalId) : [];
      _data.covenantgroup = ["production-financial-card", "contract-form-production-by-card",].includes(currentTab?.name) ? [21]
        : selectedCovenantGroups ? selectedCovenantGroups?.map((x) => x.externalId) : [];
      _data.covenant = selectedCovenants ? selectedCovenants?.map((x) => x.externalId) : [];
      _data.formcontract = selectedContractForm ? selectedContractForm?.map((x) => x.externalId) : [];

      setParams({
        ...params,
        filter: {
          ...params?.filter,
          startDate: moment(data?.startDate).format("YYYY-MM-DD"),
          endDate: moment(data?.endDate).format("YYYY-MM-DD"),
          ..._data,
        },
      });

      const result = await handleListOnFilter({
        ...params,
        filter: {
          ...params?.filter,
          startDate: moment(data?.startDate).format("YYYY-MM-DD"),
          endDate: moment(data?.endDate).format("YYYY-MM-DD"),
          ..._data,
        },
      });
      setResultOnFilter && setResultOnFilter(result);
    }
  };

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div
        className="SidebarBiDashboardFilter"
        data-testid="SidebarBiDashboardFilter"
      >
        <Row>
          <Col>
            <InputDateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setValue={setValue}
              startLabelText={"DATA INICIAL"}
              endLabelText={"DATA FINAL"}
              maxEndDate={moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <FormGroup className="form-group">
              <label htmlFor="user">PARCEIRO</label>
              <InputSearchPartner
                name="partner"
                control={control}
                setValue={setValue}
                valuePartner={valuePartner}
                setValuePartner={setValuePartner}
                setPartnerObj={setPartnerObj}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>COMERCIAL</label>
              <ReactSelect
                value={selectedCommercial}
                isClearable
                isSearchable
                isMulti
                options={commercialsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedCommercial(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>REGIONAL</label>
              <ReactSelect
                value={selectedRegional}
                isClearable
                isSearchable
                isMulti
                options={regionalsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedRegional(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>EXECUTIVO</label>
              <ReactSelect
                value={selectedExecutive}
                isClearable
                isSearchable
                isMulti
                options={executivesOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedExecutive(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>FILIAIS</label>
              <ReactSelect
                value={selectedBranchs}
                isClearable
                isSearchable
                isMulti
                options={subsidiarysOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedBranchs(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>ESTADO</label>
              <ReactSelect
                value={selectedUfs}
                isClearable
                isSearchable
                isMulti
                options={ufs}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedUfs(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>FINANCEIRAS</label>
              <ReactSelect
                value={selectedFinancials}
                isClearable
                isSearchable
                isMulti
                options={financialsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedFinancials(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>FORMAS DE CONTRATO</label>
              <ReactSelect
                value={selectedContractForm}
                isClearable
                isSearchable
                isMulti
                options={contractFormsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedContractForm(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>GRUPO MASTER</label>
              <ReactSelect
                value={selectedCovenantMasterGroups}
                isClearable
                isSearchable
                isMulti
                options={masterGroupOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedCovenantMasterGroups(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>GRUPO DE CONVÊNIO</label>
              <ReactSelect
                value={selectedCovenantGroups}
                isClearable
                isSearchable
                isMulti
                options={covenantGroupsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedCovenantGroups(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup className="form-group">
              <label>CONVÊNIO</label>
              <ReactSelect
                value={selectedCovenants}
                isClearable
                isSearchable
                isMulti
                options={covenantsOptions}
                noOptionsMessage={() => "Não há registros"}
                placeholder="Selecione..."
                className={`form-control p-0`}
                onChange={(val: any) => {
                  setSelectedCovenants(val);
                }}
                styles={customStyles}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
};

export default SidebarBiDashboardFilter;
