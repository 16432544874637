import React, { FC, useEffect, useState } from "react";
import "./ContractFormProductionByCovenant.scss";
import { useBiContractForm } from "../../../../../providers/BI/Dashboard/BiContractFormProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ContractFormHideButtons from "../ContractFormHideButtons/ContractFormHideButtons";
import ContractFormTableBaseCard from "../ContractFormTableBaseCard/ContractFormTableBaseCard";
import { BiContractFormModel } from "../../../../../models/BI/Dashboard/ContractForm/BiContractFormModel";
import { Card } from "react-bootstrap";
import { useBiDashboard } from "../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../models/BI/Dashboard/BiProductionParamsModel";
import moment from "moment";
import SkeletonTable from "../../../../SkeletonTable/SkeletonTable";

interface ContractFormProductionByCovenantProps { }

const ContractFormProductionByCovenant: FC<
  ContractFormProductionByCovenantProps
> = () => {
  const [hideButtons, setHideButtons] = useState<any[]>([]);

  const {
    contractFormProductionByCovenant,
    setContractFormProductionByCovenant,
  } = useBiContractForm();
  const {
    handleListMonthlyProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.COVENANT,
        group: {
          formcontract: true,
        },
      });
      if (!contractFormProductionByCovenant) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (resultOnFilter) {
      setContractFormProductionByCovenant(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.COVENANT,
      group: {
        formcontract: true,
      },
    }).then();

    if (result) {
      setContractFormProductionByCovenant(result);
    }
  };

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (contractFormProductionByCovenant?.months && contractFormProductionByCovenant?.months?.length > 0) {
      periods = contractFormProductionByCovenant?.months?.map((x: any) => x);
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        "Forma de contrato": item.name,
      };

      periods?.forEach((period: string, index: number) => {
        const month = moment(period, "YYYY-MM").format("MMMM")?.toUpperCase();
        const values = item?.periods?.find((x: any) => x.month === period);
        _row[month] = values?.totalProduction ?? 0;
        if (index > 0) {
          _row["C/Q " + month] = values?.totalProductionGrowth ?? 0;
        }
        _row["Participação " + month] = values?.totalProductionPercentage ?? 0;
      });
      if (item?.periods && item?.periods?.length > 0) {
        _row["Projeção"] = item?.periods[item?.periods?.length - 1]?.totalProductionProjection ?? 0;
        _row["Projeção C/Q"] = item?.periods[item?.periods?.length - 1]?.totalProductionProjectionGrowth ?? 0;
      }
      _data.push(_row);
    });
    handleExcel(_data, "Forma de contrato");
  };

  const renderCard = (item: any) => {
    return (
      <Card className="mt-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <span className="text-start text-primary pb-1">{item?.name}</span>
            <button
              className="btn bg-success text-white rounded m-0 ms-5"
              onClick={() => {
                handleExport(item?.group);
              }}
            >
              <i className="fas fa-file-excel" /> Exportar
            </button>
          </div>
          <ContractFormTableBaseCard
            data={item?.group}
            hideButtons={hideButtons}
            setHideButtons={setHideButtons}
            months={contractFormProductionByCovenant?.months ?? []}
          />
        </Card.Body>
      </Card>
    );
  };

  return (
    <div
      className="ContractFormProductionByCovenant"
      data-testid="ContractFormProductionByCovenant"
    >
      {!isLoading ? (
        <>
          <ContractFormHideButtons
            hideButtons={hideButtons}
            setHideButtons={setHideButtons}
          />

          {contractFormProductionByCovenant?.items ? (
            contractFormProductionByCovenant?.items?.map((item) =>
              renderCard(item)
            )
          ) : (
            <div className="d-flex mt-9 justify-content-center general-error-msg">
              {generalErrorMsg}
            </div>
          )}
        </>
      ) : (
        <SkeletonTable />
      )}
    </div>
  );
};

export default ContractFormProductionByCovenant;
