import React, { FC, useEffect, useMemo, useState } from "react";
import "./ContractFormMonthlyProduction.scss";
import { useBiContractForm } from "../../../../../../providers/BI/Dashboard/BiContractFormProvider";
import moment from "moment";
import FormatMoney from "../../../../../../utils/FormatMoney";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import AppDataTable from "../../../../../AppDataTable/AppDataTable";
import { useBiDashboard } from "../../../../../../providers/BI/Dashboard/BiDashboardProvider";
import { BI_VIEWS_ENUM } from "../../../../../../models/BI/Dashboard/BiProductionParamsModel";
import SkeletonTable from "../../../../../SkeletonTable/SkeletonTable";
import { TabBiContractFormEnum } from "../ProductionByContractForm";

interface ContractFormMonthlyProductionProps {
  activeTab: TabBiContractFormEnum;
}

const ContractFormMonthlyProduction: FC<ContractFormMonthlyProductionProps> = ({
  activeTab,
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [hideButtons, setHideButtons] = useState<any[]>([]);
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);

  const {
    monthlyProductionByContractForm,
    setMonthlyProductionByContractForm,
  } = useBiContractForm();
  const {
    handleListMonthlyProduction,
    isLoading,
    params,
    setParams,
    setHandleListOnFilter,
    resultOnFilter,
    setResultOnFilter,
    generalErrorMsg,
    isParamsUpdated,
    setIsParamsUpdated,
    handleExcel,
    handleSort,
    biTotalRowStyle,
    biCellColor,
    dataFontSize,
    tableHeight,
    biTotalWorkDayRowStyle,
    isTotalWorkDay,
    totalWorkDayLabel
  } = useBiDashboard();

  useEffect(() => {
    if (isParamsUpdated) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
      setParams({
        ...params,
        view: BI_VIEWS_ENUM.CONTRACT_FORM,
        group: {},
      });
      if (!monthlyProductionByContractForm) {
        handleFetch().then();
      }
      setIsParamsUpdated(false);
    }
  }, [isParamsUpdated]);

  useEffect(() => {
    if (activeTab === TabBiContractFormEnum.MONTHLY_PRODUCTION) {
      setHandleListOnFilter(() => handleListMonthlyProduction);
    }
  }, [activeTab]);

  useEffect(() => {
    if (resultOnFilter) {
      setMonthlyProductionByContractForm(resultOnFilter);
      setResultOnFilter(undefined);
    }
  }, [resultOnFilter]);

  const handleFetch = async () => {
    const result = await handleListMonthlyProduction({
      ...params,
      view: BI_VIEWS_ENUM.CONTRACT_FORM,
      group: {},
    }).then();

    if (result) {
      setMonthlyProductionByContractForm(result);
    }
  };

  const handleExport = (rows: any) => {
    let periods: string[] = [];
    if (
      monthlyProductionByContractForm?.months && monthlyProductionByContractForm?.months?.length > 0) {
      periods = monthlyProductionByContractForm?.months?.map((x) => x);
    }
    const _data: any[] = [];
    rows.forEach((item: any) => {
      const _row: any = {
        "Forma de contrato": item.name,
      };

      periods?.forEach((period: string, index: number) => {
        const month = moment(period, "YYYY-MM").format("MMMM")?.toUpperCase();
        _row[month] = item[period] ?? 0;
        if (index > 0) {
          _row["C/Q " + month] = item[period + "cq"] ?? 0;
        }
        _row["Participação " + month] = item[period + "participation"] ?? 0;
      });
      if (currentMonth) {
        _row["Projeção"] = item?.projection ?? 0;
        _row["Projeção C/Q"] = item?.projectionGrowth ?? 0;
      }
      _data.push(_row);
    });
    handleExcel(_data, "Producao mensal de forma de contrato");
  };

  const handleSortValue = (columns: any, sortDirection: any, rows: any) => {
    const valueRows = handleSort(columns, sortDirection, rows);
    setRows(valueRows);
  }

  const mountColumns = useMemo(() => {
    if (
      monthlyProductionByContractForm?.items &&
      monthlyProductionByContractForm?.items?.length > 0
    ) {
      setCurrentMonth(false);

      const currentMonthFilter = new Date().getMonth() + 1;

      monthlyProductionByContractForm?.months?.forEach((item) => {
        const result = item.split("-") || [0, 0];
        const isMonth = { month: Number(result[1]), year: Number(result[0]) };
        if (isMonth.month === currentMonthFilter) {
          setCurrentMonth(true);
        }
      });

      const _columns: any = [
        {
          id: "colIndex",
          name: (
            <div className="bg-info text-table text-uppercase">
              Forma de contrato
            </div>
          ),
          selector: (data: any) => data?.name,
          cell: (data: any) => (
            <div className="text-wrap text-bold">{data?.name}</div>
          ),
          sortable: true,
          width: "250px",
        },
      ];

      monthlyProductionByContractForm?.months?.forEach((item: string, index: number) => {
        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              {moment(item, "YYYY-MM").format("MMMM")}
            </div>
          ),
          selector: (data: any) => data[item] ?? 0,
          right: true,
          cell: (data: any) => (
            <div className="text-nowrap">{FormatMoney(data[item ?? ""])}</div>
          ),
          sortable: true,
          style: {
            paddingLeft: '18px',
            paddingRight: '18px',
          },
          compact: true,
          omit: !hideButtons?.find((x: any) => x.alias === item)?.show,
        });

        if (index > 0) {
          _columns.push({
            name: <div className="bg-info text-table text-uppercase">C/Q</div>,
            selector: (data: any) => data[`${item}cq`] ?? 0,
            right: true,
            cell: (data: any) => (
              <div className={`${data?.name === 'TOTAL' ?
                (data[`${item}cq`] < 0 ? "bg-negative-total" :
                  "bg-positive-total") : (data[`${item}cq`] < 0 ? "bg-negative" : "bg-positive")} text-nowrap`}>
                {!isTotalWorkDay(data) &&
                  <>
                    {FormatMoney(data[`${item}cq`], false)}%
                    <FontAwesomeIcon
                      icon={data[`${item}cq`] < 0 ? faArrowDown : faArrowUp}
                      className="ms-2"
                    />
                  </>
                }
              </div>
            ),
            sortable: true,
            omit:
              !hideButtons?.find((x: any) => x.alias === "cq")?.show ||
              !hideButtons?.find((x: any) => x.alias === item)?.show,
          });
        }

        _columns.push({
          name: (
            <div className="bg-info text-table text-uppercase">
              Participação
            </div>
          ),
          selector: (data: any) => data[`${item}participation`] ?? 0,
          right: true,
          cell: (data: any) => (
            !isTotalWorkDay(data) &&
            <div>{FormatMoney(data[`${item}participation`], false)}%</div>
          ),
          sortable: true,
          compact: true,
          omit:
            !hideButtons?.find((x: any) => x.alias === "participation")?.show ||
            !hideButtons?.find((x: any) => x.alias === item)?.show,
        });
        if (currentMonth && Number(item.split("-")[1]) === currentMonthFilter) {
          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">PROJEÇÃO</div>
            ),
            selector: (data: any) => data?.projection ?? 0,
            right: true,
            cell: (data: any) => (
              !isTotalWorkDay(data) &&
              <div className="text-nowrap">{FormatMoney(data?.projection)}</div>
            ),
            sortable: true,
            style: {
              paddingLeft: '18px',
              paddingRight: '18px',
            },
            compact: true,
            omit: !hideButtons?.find((x: any) => x.alias === "projection")?.show,
          });

          _columns.push({
            name: (
              <div className="bg-info text-table text-uppercase">
                PROJEÇÃO C/Q
              </div>
            ),
            selector: (data: any) => data?.projectionGrowth ?? 0,
            right: true,
            cell: (data: any) => (
              <div className={`${data?.name === 'TOTAL' ?
                (data?.projectionGrowth < 0 ? "bg-negative-total" :
                  "bg-positive-total") : (data?.projectionGrowth < 0 ? "bg-negative" : "bg-positive")} text-nowrap`}>
                {!isTotalWorkDay(data) &&
                  <>
                    {FormatMoney(data?.projectionGrowth, false)}%
                    <FontAwesomeIcon
                      icon={data?.projectionGrowth < 0 ? faArrowDown : faArrowUp}
                      className="ms-2"
                    />
                  </>
                }
              </div>
            ),
            sortable: true,
            omit: !hideButtons?.find((x: any) => x.alias === "projectionGrowth")
              ?.show,
          });
        }
      });

      setColumns(_columns);
    }
  }, [monthlyProductionByContractForm, hideButtons]);

  const mountRows = () => {
    if (
      monthlyProductionByContractForm?.items &&
      monthlyProductionByContractForm?.items?.length > 0
    ) {
      const _rows: any = [];

      monthlyProductionByContractForm?.items?.forEach((item) => {
        const _row: any = {
          name: item.name,
        };

        item?.periods?.forEach((period) => {
          _row[period.month ?? ""] = period.totalProduction;
          _row[`${period.month}cq`] = period.totalProductionGrowth;
          _row[`${period.month}participation`] =
            period.totalProductionPercentage;
        });

        if (item?.periods && item?.periods?.length > 0) {
          _row.projection =
            item?.periods[item?.periods?.length - 1]?.totalProductionProjection;
          _row.projectionGrowth =
            item?.periods[
              item?.periods?.length - 1
            ].totalProductionProjectionGrowth;
        }

        _rows.push(_row);
      });

      const _rowTotal: any = {
        name: 'TOTAL',
      };

      const _rowTotalWorkDay: any = {
        name: totalWorkDayLabel,
      };

      monthlyProductionByContractForm?.months?.forEach((period, key) => {
        _rowTotal[period ?? ""] = monthlyProductionByContractForm?.total?.[key].totalProduction;
        _rowTotal[`${period}cq`] = monthlyProductionByContractForm?.total?.[key].totalProductionGrowth;
        _rowTotal[`${period}participation`] = 100;

        _rowTotalWorkDay[period] = monthlyProductionByContractForm?.total?.[key].totalProductionPerWorkday;
      });

      if (monthlyProductionByContractForm?.total && monthlyProductionByContractForm?.total?.length > 0) {
        _rowTotal.projection = monthlyProductionByContractForm?.total[monthlyProductionByContractForm?.total?.length - 1].totalProductionProjection
        _rowTotal.projectionGrowth = monthlyProductionByContractForm?.total[monthlyProductionByContractForm?.total?.length - 1].totalProductionProjectionGrowth
      }

      _rows.push(_rowTotal);
      _rows.push(_rowTotalWorkDay);

      setRows(_rows);
    } else {
      setRows([]);
    }
  };

  const mountHideButtons = () => {
    if (
      monthlyProductionByContractForm?.items &&
      monthlyProductionByContractForm?.items?.length > 0
    ) {
      const _buttons: any = [];
      monthlyProductionByContractForm?.months?.forEach((item) => {
        _buttons.push({
          alias: item,
          label: moment(item, "YYYY-MM").format("MMMM"),
          show: true,
        });
      });

      _buttons.push({
        alias: `cq`,
        label: "Crescimento/Queda",
        show: true,
      });

      _buttons.push({
        alias: `participation`,
        label: "Participação",
        show: true,
      });

      if (currentMonth) {
        _buttons.push({
          alias: `projection`,
          label: "Projeção",
          show: true,
        });

        _buttons.push({
          alias: `projectionGrowth`,
          label: "Projeção C/Q",
          show: true,
        });
      }
      setHideButtons(_buttons);
    }
  };

  useEffect(() => {
    mountRows();
    mountHideButtons();
  }, [monthlyProductionByContractForm]);

  const onClickHideButton = (button: any, index: number) => {
    const _hideButtons = [...hideButtons];
    button.show = !button.show;
    _hideButtons[index] = button;
    setHideButtons(_hideButtons);
  };

  return (
    <div
      className="ContractFormMonthlyProduction"
      data-testid="ContractFormMonthlyProduction"
    >
      <>
        {!isLoading ? (
          <>
            {rows?.length > 0 ? (
              <>
                <Col md={12} className="d-flex justify-content-end">
                  <Row className="display-button mb-3">
                    <div className="d-flex justify-content-between align-items-center flex-md-row">
                      <div className="d-flex gap-1 justify-content-center align-items-center flex-md-row">
                        {hideButtons?.map((x: any, index: number) => (
                          <button
                            type="button"
                            className="btn bg-primary text-white mb-0 ms-2 min-width-btn text-capitalize"
                            onClick={() => onClickHideButton(x, index)}
                          >
                            <FontAwesomeIcon
                              icon={x.show ? faEye : faEyeSlash}
                              className="me-1"
                            />
                            {x.label}
                          </button>
                        ))}
                        <button
                          className="btn bg-success text-white rounded m-0 ms-2"
                          onClick={() => {
                            handleExport(rows);
                          }}
                        >
                          <i className="fas fa-file-excel" /> Exportar
                        </button>
                      </div>
                    </div>
                  </Row>
                </Col>

                <AppDataTable
                  rows={rows}
                  columns={columns}
                  pagination={false}
                  handleOrder={handleSortValue}
                  conditionalRowStyles={[
                    {
                      when: (data: any) => data.name === 'TOTAL',
                      style: {
                        ...biTotalRowStyle,
                        borderBottomLeftRadius: '0',
                        borderBottomRightRadius: '0',
                        bottom: '50px'
                      }
                    },
                    {
                      when: (data: any) => isTotalWorkDay(data),
                      style: biTotalWorkDayRowStyle
                    }
                  ]}
                  cellColor={biCellColor}
                  cellFontSize={dataFontSize}
                  maxHeight={tableHeight}
                  display="flex"
                />
              </>
            ) : (
              <div className="d-flex mt-9 justify-content-center general-error-msg">
                {generalErrorMsg}
              </div>
            )}
          </>
        ) : (
          <SkeletonTable />
        )}
      </>
    </div>
  );
};

export default ContractFormMonthlyProduction;
