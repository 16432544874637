import { FC, useEffect, useState } from "react";
import "./ModalTermsUser.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { useForm } from "react-hook-form";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { Col, FormGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import InputSearchPartner from "../../Registrations/Partner/InputSearchPartner/InputSearchPartner";

interface ModalTermsUserProps {
  show: boolean;
  onClose: () => void;
  kindOfPerson: string;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsUser: FC<ModalTermsUserProps> = ({ show, onClose, kindOfPerson }) => {
  const [isLoadingSubmit] = useState<boolean>(false);
  const [valuePartner, setValuePartner] = useState<string>('');
  const { control} = useForm<any>({ defaultValues });
  const {handleSaveRule, term, handleTermsRules, rules, handleDeleteRules, error} = useTermsDocuments();

  const { handleSubmit, register, setValue, reset, formState: { errors }, } = useForm({
    mode: "onChange"
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: any) => {
    data.id = null;
    data.termDocumentId = term.id;
    data.companyId = null;
    data.accessProfileId = null;
    data.userTypeId = null;
    data.userId = [parseInt(valuePartner)];
    data.kindOfPerson = kindOfPerson;

    const ret = await handleSaveRule(data as any);

    if (ret) {
      toast.success("Regra salva com sucesso!");
      onClose();
      handleTermsRules(term.id);
      setValuePartner("");
    }
  };

  return (
    <ModalDefault
      title={"Gerenciar Agente"}
      show={show}
      onClose={handleClose}
      sizeModal={"lg"}
      showFooter={true}
      buttonText={isLoadingSubmit ? "Salvando..." : "Salvar"}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsUser" data-testid="ModalTermsUser">
        <Row>
          <Col>
            <FormGroup className="form-group d-flex align-items-center">
              <div style={{width: '100%'}}>
                <div className="text-wrap align-middle">
                  <label htmlFor="userId">AGENTE</label>
                  <br />
                  <InputSearchPartner
                    name="userId"
                    control={control}
                    setValue={setValue}
                    valuePartner={valuePartner}
                    setValuePartner={setValuePartner}
                  />
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsUser;
