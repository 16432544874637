import { FC, useEffect, useState } from 'react';
import './Login.scss';
import Footer from '../../components/Footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../providers/AuthProvider';
import { useStyle } from '../../providers/Style/StyleProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSidebarFilter } from '../../providers/SidebarFilterProvider';

interface LoginProps {
  logoDefault?: string;
  backgroundLogin?: string;
  colorPrimary?: string;
  thumb?: boolean;
}

interface Inputs {
  username: string,
  password: string,
}

const usePathname = (): string => {
  const location = useLocation();
  return location.pathname;
}

const Login: FC<LoginProps> = ({
  logoDefault = '',
  backgroundLogin = '',
  colorPrimary = '',
  thumb = false
}) => {
  const [, setFocusedUsername] = useState<boolean>(false);
  const [, setFocusedPassword] = useState<boolean>(false);

  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const { login, error, setError, onInvalid, isLoading, setIsLoading } = useAuth();
  const { isLoading: isLoadingStyle, style, handleStyle } = useStyle();
  const { setShowSidebarFilter } = useSidebarFilter();

  const navigate = useNavigate();
  const pathname = usePathname();

  const handleLogin = async (data: { username: string, password: string }) => {
    setError('');
    setIsLoading(false);
    const logged = await login(data);
    logged && navigate('/home');
  }

  useEffect(() => {
    !thumb && (pathname !== '/login') && navigate('/login');
  }, [pathname, navigate]);

  useEffect(() => {
    setShowSidebarFilter(false);
    !thumb && handleStyle().then();
  }, []);

  return (
    <main className="Login main-content main-content-bg mt-0 h-100" data-testid="Login">
      {(!isLoadingStyle && style) && (
        <>
          <div className={`page-header ${!thumb ? 'min-vh-100' : 'h-100'}`} style={{ backgroundImage: `url('${thumb ? backgroundLogin : style?.backgroundLogin}')` }}>
            <span className="mask bg-gradient-dark opacity-3"></span>
            <Container>
              <Row className="justify-content-center">
                <Col lg={4} md={7}>
                  <Card className="border-0 mb-0">
                    <Card.Header className="bg-transparent">
                      <h5 className="text-dark text-center mt-2 mb-3">
                        {!thumb ? (
                          <img
                            src={style?.logoDefault}
                            alt="Logo"
                            style={{ maxHeight: 48 }}
                          />
                        ) : (
                          <>
                            {logoDefault ? (
                              <img
                                src={logoDefault}
                                alt="Logo"
                                style={{ maxHeight: 48 }}
                              />
                            ) : (
                              <p className="mb-0 text-primary bg-logo-dest">Logo Principal</p>
                            )}
                          </>
                        )}
                      </h5>
                      <div className="d-flex align-items-center justify-content-center btn-wrapper text-center gap-2 w-75 m-auto">
                        <div>
                          <a href="https://apps.apple.com/br/app/beviguard/id1495034890?l=pt&amp;ls=1" target="_blank" className="btn btn-neutral btn-icon btn-xs mb-0 px-3">
                            <img src="/assets/img/app-store.png" alt="App Store" className="w-100" />
                          </a>
                        </div>

                        <div>
                          <a href="https://play.google.com/store/apps/details?id=br.com.bevicred.bevicredmobiletoken&amp;hl=pt" target="_blank" className="btn btn-neutral btn-icon btn-xs mb-0 px-3">
                            <img src="/assets/img/google-play.png" alt="Google Play" className="w-100" />
                          </a>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body className="px-lg-5 pt-0">
                      <div className="text-center text-muted mb-4">
                        <small className="text-sm font-weight-bold mb-2 text-gray text-border d-inline z-index-2 px-3">Área do Parceiro</small>
                      </div>
                      <form role="form" className="text-start" onSubmit={handleSubmit(handleLogin)}>
                        <div className="mb-3">
                          <input
                            {...register('username', { required: true, maxLength: 25 })}
                            type="text"
                            className={`form-control ${onInvalid(errors?.username)}`}
                            placeholder="Login"
                            aria-label="Login"
                            onFocus={() => setFocusedUsername(true)}
                            onBlur={() => setFocusedUsername(true)}
                          />
                          <ErrorMessage name="Login" type={errors?.username?.type} max={25} />
                        </div>
                        <div className="mb-3">
                          <input
                            {...register('password', { required: true, minLength: 6 })}
                            type="password"
                            className={`form-control ${onInvalid(errors?.password)}`}
                            placeholder="Senha"
                            aria-label="Senha"
                            onFocus={() => setFocusedPassword(true)}
                            onBlur={() => setFocusedPassword(true)}
                          />
                          <ErrorMessage name="Senha" type={errors?.password?.type} min={6} />
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className={`btn ${!thumb || !colorPrimary ? 'btn-primary' : ''} text-white w-100 my-4 mb-2`}
                            disabled={isLoading}
                            style={{ backgroundColor: colorPrimary }}
                          >
                            {!isLoading ? (
                              'Entrar'
                            ) : (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            )}
                          </button>
                        </div>

                        {error &&
                          <label className="text-center text-danger error-login mt-3">
                            {error}
                          </label>
                        }
                      </form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>

          <Footer fixed={!thumb} />
        </>
      )}
    </main>
  );
}

export default Login;
