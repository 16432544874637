import { FC, useEffect, useState } from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { customStyles } from "../../../models/SelectCustomStyles";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import SidebarFilter from "../../SidebarFilter/SidebarFilter";
import { useForm } from "react-hook-form";
import { yesNoOption } from "../../../models/YesNoOptions";

interface SidebarTermsDocumentsFilterProps {}

export const defaultValues: TermsDocumentsParams = {
  name: "",
  departament: 0,
  hasExclusive: 0,
};

const SidebarTermsDocumentsFilter: FC<
  SidebarTermsDocumentsFilterProps
> = () => {
  const { termsDepartamentOptions, setParams, handleList } = useTermsDocuments();
  const { handleSubmit, control, setValue } = useForm<TermsDocumentsParams>({
    defaultValues,
  });
  const [selectedDepartament, setSelectedDepartament] = useState<any>();
  const [selectedExclusive, setSelectedExclusive] = useState<any>();
  const [selectedConfigured, setSelectedConfigured] = useState<any>();

  useEffect(() => {
    setValue("departament", selectedDepartament?.value);
  }, [selectedDepartament]);

  useEffect(() => {
    setValue("hasExclusive", selectedExclusive?.value);
  }, [selectedExclusive]);

  const onSubmit = async (data: any) => {
    const _data = { ...data };
    _data.name = data.name;
    _data.hasExclusive = data.hasExclusive === 'S' ? 1 : 0;
    _data.termDocumentDepartmentId = data.departament;

    setParams(_data);
  };

  return (
    <SidebarFilter handleSubmit={handleSubmit(onSubmit)}>
      <div
        className="SidebarTermsDocumentsFilter"
        data-testid="SidebarTermsDocumentsFilter"
      >
        <Row className="mt-1">
          <Col md={12}>
            <FormGroup className="form-group">
              <label>TÍTULO DO DOCUMENTO</label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => {
                  setValue("name", e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="departament">DEPARTAMENTO</label>
              <ReactSelect
                isClearable
                isSearchable
                options={termsDepartamentOptions}
                value={selectedDepartament ?? ""}
                defaultValue={selectedDepartament}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => "Não há registros"}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedDepartament(val);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="form-group">
              <label htmlFor="exclusive">DOCUMENTO EXCLUSIVO?</label>
              <ReactSelect
                isClearable
                isSearchable
                options={yesNoOption}
                value={selectedExclusive ?? ""}
                defaultValue={selectedExclusive}
                placeholder="Selecione..."
                className={`form-control p-0`}
                noOptionsMessage={() => "Não há registros"}
                styles={customStyles}
                onChange={(val: any) => {
                  setSelectedExclusive(val);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </SidebarFilter>
  );
};

export default SidebarTermsDocumentsFilter;
