import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../../models/Paginate/PagesPaginate";
import { VisitsAnalysisService } from "../../../services/Visits/VisitsAnalysis/VisitsAnalysisService";
import moment from "moment";
import { ToastSettings } from "../../../utils/ToastSettings";
import { useReportRequest } from "../../Reports/ReportRequest/ReportRequestProvider";

interface ContextProps {
    visitsAnalysis: any[],
    visitsChartByRegional: any[],
    visitsChartByUf: any[],
    visitsChartByFocus: any[],
    visitsChartByOtherActions: any[],
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    paramsChartByRegional: any,
    setParamsChartByRegional: Dispatch<SetStateAction<any>>,
    paramsChartByUf: any,
    setParamsChartByUf: Dispatch<SetStateAction<any>>,
    paramsChartByFocus: any,
    setParamsChartByFocus: Dispatch<SetStateAction<any>>,
    paramsChartByOtherActions: any,
    setParamsChartByOtherActions: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    isLoadingChartByRegional: boolean,
    isLoadingChartByUf: boolean,
    isLoadingChartByFocus: boolean,
    isLoadingChartByOtherActions: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleExcel: (type: 'perRegional' | 'perUf' | 'perFocus' | 'perActivities' | 'geral') => void,
    handleExcelCommercialWallet: (id: number) => void,
    handleExcelRegionalWallet: (id: number) => void,
}

export const VisitsAnalysisContext = createContext<ContextProps>({} as ContextProps);

interface VisitsAnalysisProviderProps {
    children: ReactNode;
}

const _startDate = moment(new Date()).format('YYYY-MM-01');
const _endDate = moment(new Date()).format('YYYY-MM-DD');

export const VisitsAnalysisProvider = ({ children }: VisitsAnalysisProviderProps) => {
    const [visitsAnalysis, setVisitsAnalysis] = useState<any[]>([]);
    const [visitsChartByRegional, setVisitsChartByRegional] = useState<any[]>([]);
    const [visitsChartByUf, setVisitsChartByUf] = useState<any[]>([]);
    const [visitsChartByFocus, setVisitsChartByFocus] = useState<any[]>([]);
    const [visitsChartByOtherActions, setVisitsChartByOtherActions] = useState<any[]>([]);
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingChartByRegional, setIsLoadingChartByRegional] = useState<boolean>(true);
    const [isLoadingChartByUf, setIsLoadingChartByUf] = useState<boolean>(true);
    const [isLoadingChartByFocus, setIsLoadingChartByFocus] = useState<boolean>(true);
    const [isLoadingChartByOtherActions, setIsLoadingChartByOtherActions] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [params, setParams] = useState<any>({ page: 1, startDate: _startDate, endDate: _endDate });
    const [paramsChartByRegional, setParamsChartByRegional] = useState<any>({ startDate: _startDate, endDate: _endDate });
    const [paramsChartByUf, setParamsChartByUf] = useState<any>({ startDate: _startDate, endDate: _endDate });
    const [paramsChartByFocus, setParamsChartByFocus] = useState<any>({ startDate: _startDate, endDate: _endDate });
    const [paramsChartByOtherActions, setParamsChartByOtherActions] = useState<any>({ startDate: _startDate, endDate: _endDate });

    const { setIsLoadingFile } = useReportRequest();

    const service = new VisitsAnalysisService();
    const location = useLocation();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/app-visits/analysis-visits/list')) {
            return false;
        }

        try {
            handleIsLoading(true);
            const [_Response, _Error] = await service.list(params);


            if (!!_Error) {
                handleIsLoading(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsAnalysis(_Response.data);
            setPages(_Response?.pages);

            handleListChartByRegional().then();
            handleListChartByUf().then();
            handleListChartByFocus().then();
            handleListChartByOtherActions().then();

            handleIsLoading(false);
            return setError('');
        } catch (e) {
            handleIsLoading(false);
            setError('Não foi possível carregar as visitas.');
        }
    }, [params, location.pathname]);

    const handleExcel = async (type: 'perRegional' | 'perUf' | 'perFocus' | 'perActivities' | 'geral') => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.export(params, type);

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleExcelCommercialWallet = async (id: number) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportCommercialWallet({ commercial: id });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleExcelRegionalWallet = async (id: number) => {
        setError('');
        setIsLoadingFile(true);

        const [_Response, _Error] = await service.exportRegionalWallet({ regional: id });

        if (_Error) {
            setIsLoadingFile(false);
            ToastSettings(_Error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_Response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    const handleListChartByRegional = async () => {
        try {
            setIsLoadingChartByRegional(true);
            const [_Response, _Error] = await service.listChartByRegional(params);

            if (!!_Error) {
                setIsLoadingChartByRegional(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByRegional(_Response.data);
            setIsLoadingChartByRegional(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByRegional(false);
            setError('Não foi possível carregar o gráfico por Regional.');
        }
    };

    const handleListChartByUf = async () => {
        try {
            setIsLoadingChartByUf(true);
            const [_Response, _Error] = await service.listChartByUf(params);

            if (!!_Error) {
                setIsLoadingChartByUf(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByUf(_Response.data);
            setIsLoadingChartByUf(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByUf(false);
            setError('Não foi possível carregar o gráfico por UF.');
        }
    };

    const handleListChartByFocus = async () => {
        try {
            setIsLoadingChartByFocus(true);
            const [_Response, _Error] = await service.listChartByFocus(params);

            if (!!_Error) {
                setIsLoadingChartByFocus(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByFocus(_Response.data);
            setIsLoadingChartByFocus(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByFocus(false);
            setError('Não foi possível carregar o gráfico por Foco.');
        }
    };

    const handleListChartByOtherActions = async () => {
        try {
            setIsLoadingChartByOtherActions(true);
            const [_Response, _Error] = await service.listChartByOtherActions(params);

            if (!!_Error) {
                setIsLoadingChartByOtherActions(false);
                return setError(_Response?.message || _Error);
            }

            setVisitsChartByOtherActions(_Response.data);
            setIsLoadingChartByOtherActions(false);
            return setError('');
        } catch (e) {
            setIsLoadingChartByOtherActions(false);
            setError('Não foi possível carregar as visitas.');
        }
    };

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    const handleIsLoading = (action: boolean) => {
        setIsLoading(action);
        setIsLoadingChartByRegional(action);
        setIsLoadingChartByUf(action);
        setIsLoadingChartByFocus(action);
        setIsLoadingChartByOtherActions(action);
    }

    useEffect(() => { handleList().then(); }, [handleList]);

    return (
        <VisitsAnalysisContext.Provider value={{
            visitsAnalysis,
            visitsChartByRegional,
            visitsChartByUf,
            visitsChartByFocus,
            visitsChartByOtherActions,
            params,
            setParams,
            paramsChartByRegional,
            setParamsChartByRegional,
            paramsChartByUf,
            setParamsChartByUf,
            paramsChartByFocus,
            setParamsChartByFocus,
            paramsChartByOtherActions,
            setParamsChartByOtherActions,
            pages,
            setPages,
            isLoading,
            isLoadingChartByRegional,
            isLoadingChartByUf,
            isLoadingChartByFocus,
            isLoadingChartByOtherActions,
            error,
            setError,
            handleList,
            handleExcel,
            handleExcelCommercialWallet,
            handleExcelRegionalWallet
        }}>
            {children}
        </VisitsAnalysisContext.Provider>
    );
}

export const useVisitsAnalysis = () => useContext(VisitsAnalysisContext);