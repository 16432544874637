import { FC, useEffect, useState } from "react";
import "./ModalTermsAccessProfile.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { useForm } from "react-hook-form";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { Col, FormGroup, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { customStyles } from "../../../models/SelectCustomStyles";
import { toast } from "react-toastify";
import { useAccessProfile } from "../../../providers/Registrations/Access/AccessProfileProvider";

interface ModalTermsAccessProfileProps {
  show: boolean;
  onClose: () => void;
  kindOfPerson: string;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsAccessProfile: FC<ModalTermsAccessProfileProps> = ({ show, onClose, kindOfPerson }) => {
  const [isLoadingSubmit] = useState<boolean>(false);
  const [selectedAccessProfile, setSelectedAccessProfile] = useState<any>();
  const {handleListGetAll, profilesGetAllOptions} = useAccessProfile();
  const {handleSaveRule, term, handleTermsRules} = useTermsDocuments();

  useEffect(() => {
    handleListGetAll();   
  }, []);

  const { handleSubmit, register, setValue, reset, formState: { errors }, } = useForm({
    mode: "onChange"
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: any) => {
    data.id = null;
    data.termDocumentId = term.id;
    data.companyId = null;
    data.accessProfileId = selectedAccessProfile?.map((x: any) => x.value);
    data.userTypeId = null;
    data.userId = null
    data.kindOfPerson = kindOfPerson;

    const ret = await handleSaveRule(data as any);

    if (ret) {
      toast.success("Regra salva com sucesso!");
      onClose();
      handleTermsRules(term.id);
      setSelectedAccessProfile("");
    }
  };

  return (
    <ModalDefault
      title={"Gerenciar Nivel de Acesso"}
      show={show}
      onClose={handleClose}
      sizeModal={"lg"}
      showFooter={true}
      buttonText={isLoadingSubmit ? "Salvando..." : "Salvar"}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsAccessProfile" data-testid="ModalTermsAccessProfile">
        <Row>
          <Col>
            <FormGroup className="form-group d-flex align-items-center">
              <div style={{width: '100%'}}>
                <label>NÍVEIS DE ACESSO</label>
                <ReactSelect
                  isClearable
                  isMulti
                  isSearchable
                  options={profilesGetAllOptions}
                  value={selectedAccessProfile ?? ""}
                  defaultValue={selectedAccessProfile}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  noOptionsMessage={() => "Não há registros"}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedAccessProfile(val);
                  }}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsAccessProfile;
