import { FC, useEffect, useRef, useState } from "react";
import "./ModalTermsUserView.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { TermsDocumentsRulesModel } from "../../../models/Terms/TermsDocumentsRulesModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

interface ModalTermsUserViewProps {
  show: boolean;
  onClose: () => void;
  kindOfPerson: string;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsUserView: FC<ModalTermsUserViewProps> = ({ show, onClose, kindOfPerson }) => {
  const {term, handleTermsRules, rules, handleDeleteRules, error} = useTermsDocuments();

  const handleDeleteUser = (item: any) => {
    Swal.fire({
      title: '<strong>Atenção?</strong>',
      html: `<span>Deseja excluir o agente ${item?.user[0].name}?</span>`,
      icon: 'question',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
    }).then(async (x) => {
      if (x.isConfirmed) {
        const ret = await handleDeleteRules(item.id || 0).then();
        if (ret) {
          toast.success('Agente inativado com sucesso!');
          handleTermsRules(term.id);
        } else {
          await Swal.fire('Ops!', `Não foi possível inativar o agente.<br>${error}`, 'error');
        }
      }
    });
  }

  const handleClose = () => {
    onClose();
  };

  const renderList = () => {
    return (
      <>
      {rules?.length > 0 ? (
        <>
        {rules?.length && rules.map((x: TermsDocumentsRulesModel, key) => (
            x.user && x.kindOfPerson === kindOfPerson && (
              <tr key={key} className="text-sm">
                <td>
                  <div className={`text-xs font-weight-bolder`}>
                    {x.user[0].name}
                  </div>
                </td>
                <td>
                    <div className={'cursor-pointer ms-3'} onClick={() => handleDeleteUser(x)}><FontAwesomeIcon icon={faTrash} /></div>
                </td>
              </tr>
            )
        ))}
      </>
          ) : (
            <tr>
              <td colSpan={8} className="text-center text-sm">
                Não há registros
              </td>
            </tr>
          )}
      </>
  )};

  return (
    <ModalDefault
      title={"Gerenciar Agente"}
      show={show}
      onClose={handleClose}
      sizeModal={"lg"}
      showFooter={true}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsUserView" data-testid="ModalTermsUserView">
        <Row>
          <Col>
            <div className="table-responsive">
              <table className="table align-items-center mb-0">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                      AGENTE
                    </th>
                    <th className="text-uppercase text-xs font-weight-bolder opacity-7">
                      EXCLUIR
                    </th>
                  </tr>
                </thead>
                <tbody>{renderList()}</tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsUserView;
