import { AccessPageModel } from "../../../models/Registrations/Access/AccessPageModel";
import api from "../../Api";
import { BaseService } from "../../BaseService";

const URL = 'api/access/profile';

export class AccessProfileService extends BaseService {
    async list(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async details(id: number, type?: string): Promise<any> {
        try {
            const url = type ? `${URL}/${id}?type=${type}` : `${URL}/${id}`;

            return this.handleResponse(await api.get(url));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getAll(params: any): Promise<any> {
        try {
            return this.handleResponse(await api.post(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async create(params: AccessPageModel): Promise<any> {
        try {
            return this.handleResponse(await api.put(`${URL}`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async delete(id: number): Promise<any> {
        try {
            return this.handleResponse(await api.delete(`${URL}`, { data: { id } }));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async getLogged(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async listGetAll(): Promise<any> {
        try {
            return this.handleResponse(await api.get(`${URL}/all`));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}