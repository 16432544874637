import { FC, useEffect, useState } from "react";
import './ModalViewDocument.scss';
import ModalDefault from "../../../ModalDefault/ModalDefault";
import PDFViewer from "../../../PDFViewer/PDFViewer";
import { useTermsPersonal } from "../../../../providers/Terms/TermsPersonalProvider";

interface ModalViewDocumentProps {
    show: boolean;
    onClose: () => void;
  }
  
  const ModalViewDocument: FC<ModalViewDocumentProps> = ({ show, onClose }) => {

    const {termsPersonal, handleViewDocument, url} = useTermsPersonal();

    useEffect(()=>{
        if(termsPersonal.id){
            handleViewDocument(termsPersonal.id);
        }
    },[]);

    const handleClose = () => {
        onClose();
    }

    return (
        <ModalDefault
            title={"Visualizar Documento"}
            show={show}
            onClose={handleClose}
            sizeModal={'lg'}
            showFooter={false}
            backdrop="static"
            backgroundColor="#f8f9fa"
            >
            <div className="ModalViewDocument" data-testid="ModalViewDocument">
                <PDFViewer pdfUrl={url} />
            </div>
        </ModalDefault>
    );
  };

  export default ModalViewDocument;