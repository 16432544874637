import { FC, useEffect, useState } from "react";
import "./ModalTermsUserType.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { useForm } from "react-hook-form";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import "react-quill/dist/quill.snow.css";
import { Col, FormGroup, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import { customStyles } from "../../../models/SelectCustomStyles";
import { toast } from "react-toastify";
import { useType } from "../../../providers/Registrations/Partner/PartnerTypeProvider";

interface ModalTermsUserTypeProps {
  show: boolean;
  onClose: () => void;
  kindOfPerson: string;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsUserType: FC<ModalTermsUserTypeProps> = ({ show, onClose, kindOfPerson }) => {
  const [isLoadingSubmit] = useState<boolean>(false);
  const [selectedUserType, setSelectedUserType] = useState<any>();
  const {handleList, typesOptions} = useType();
  const {handleSaveRule, term, handleTermsRules, rules, handleDeleteRules, error} = useTermsDocuments();

  useEffect(() => {
    handleList();   
  }, []);

  const { handleSubmit, register, setValue, reset, formState: { errors }, } = useForm({
    mode: "onChange"
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: any) => {
    data.id = null;
    data.termDocumentId = term.id;
    data.companyId = null;
    data.accessProfileId = null;
    data.userTypeId = selectedUserType?.map((x: any) => x.value);
    data.userId = null
    data.kindOfPerson = kindOfPerson;

    const ret = await handleSaveRule(data as any);

    if (ret) {
      toast.success("Regra salva com sucesso!");
      onClose();
      handleTermsRules(term.id);
      setSelectedUserType("");
    }
  };

  return (
    <ModalDefault
      title={"Gerenciar Tipo de Agente"}
      show={show}
      onClose={handleClose}
      sizeModal={"lg"}
      showFooter={true}
      buttonText={isLoadingSubmit ? "Salvando..." : "Salvar"}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsUserType" data-testid="ModalTermsUserType">
        <Row>
          <Col>
            <FormGroup className="form-group d-flex align-items-center">
              <div style={{width: '100%'}}>
                <label>TIPO DE AGENTE</label>
                <ReactSelect
                  isClearable
                  isMulti
                  isSearchable
                  options={typesOptions}
                  value={selectedUserType ?? ""}
                  defaultValue={selectedUserType}
                  placeholder="Selecione..."
                  className={`form-control p-0`}
                  noOptionsMessage={() => "Não há registros"}
                  styles={customStyles}
                  onChange={(val: any) => {
                    setSelectedUserType(val);
                  }}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsUserType;
