function FormatArrayString(items: string[]): string {
  let aux = "";

  items.forEach((item: string, i: number) => {
    if (i + 1 < items.length && i + 2 === items.length) {
      aux += `${item} e `;
    } else if (i + 1 < items.length) {
      aux += `${item}, `;
    } else {
      aux += item;
    }
  });

  return aux;
}

export default FormatArrayString;
